<template>
  <div class="iconList">
    <div class="iconList__item">
      <span class="icon">
        <v-icon class="link">mdi-checkbox-blank-circle-outline</v-icon>
      </span>
      <span class="txt">：予約可能</span>
    </div>
    <div class="iconList__item">
      <span class="icon">
        <v-icon class="link">mdi-triangle-outline</v-icon>
      </span>
      <span class="txt">：残りわずか</span>
    </div>
    <div class="iconList__item">
      <span class="icon"><v-icon>mdi-close</v-icon></span>
      <span class="txt">：受付終了</span>
    </div>
    <div class="iconList__item">
      <span class="icon"><span class="bgRed">休</span></span>
      <span class="txt">：休業</span>
    </div>
    <div class="iconList__item">
      <span class="icon"><span class="bgBlue">臨</span></span>
      <span class="txt">：臨時休業</span>
    </div>
    <div class="iconList__item">
      <span class="icon"><span class="bgYellow">外</span></span>
      <span class="txt">：申込期間外</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'IconList',
});
</script>
