<template>
  <div>
    <div v-for="unit in tables" :key="unit.id">
      <div>{{ unit.name }}</div>
      <div class="tableMonth my-5">
        <table>
          <tbody>
            <tr>
              <th v-for="(m, i) in monthlyTableHeader" :key="i">
                <span class="week" :class="m.style">
                  {{ m.label }}
                </span>
              </th>
            </tr>
            <tr v-for="(days_, i) in unit.days" :key="i">
              <availability
                v-for="(day, j) in days_"
                :key="j"
                :label="day.label"
                :ratio="day.ratio"
                :remain="
                  unit.type === rentalType.frame.value
                    ? day.max - day.sum
                    : undefined
                "
                :type="day.type"
                @click="
                  $emit('click:cell', {
                    facilityId: item.id,
                    unitId: unit.id,
                    day: day,
                    row: i,
                    col: j,
                  })
                "
              ></availability>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  DailyAvailability,
  WeeklyAvailability,
} from '@api-i/routes/availability/availability';
import { daysOfWeek, rentalType } from '@api/constants';
import Availability from '@web/components/availability/Availability.vue';
import { chunk } from 'lodash';
import Vue, { PropType } from 'vue';

export interface ClickMonthlyCellEventData {
  facilityId: string;
  unitId: string;
  day: DailyAvailability;
  row: number;
  col: number;
}

export default Vue.extend({
  name: 'MonthlyAvailabilityTable',

  components: {
    Availability,
  },

  props: {
    dates: {
      type: Array as PropType<Date[]>,
      required: true,
    },

    item: {
      type: Object as PropType<WeeklyAvailability>,
      required: true,
    },
  },

  computed: {
    monthlyTableHeader() {
      return daysOfWeek.items.map(({ text, value }) => ({
        style: value === 6 ? 'txtBlue' : value === 0 ? 'txtRed' : '',
        label: text.charAt(0),
      }));
    },

    tables() {
      return this.item.units.map((unit) => ({
        ...unit,
        days: chunk(
          unit.days.map((day) => {
            const date = new Date(day.date);
            const m = date.getMonth() + 1;
            const d = date.getDate();

            let text = String(d);
            if (d === 1) {
              text = m + '/' + d;
            }
            return {
              ...day,
              label: text,
            };
          }),
          7,
        ),
      }));
    },

    rentalType() {
      return rentalType;
    },
  },
});
</script>
