import Vue from 'vue';
import { RouteConfig } from 'vue-router';
import { ComponentOptions } from 'vue/types/options';

// viewsの直下のvueか直下のディレクトリの中のindex.vueを読み込み
const context = require.context(
  '../views',
  true,
  /^\.\/(\w+\.vue|\w+\/index\.vue)$/,
);

function notNull<T>(item: T | null): item is T {
  return item !== null;
}

export const routes: RouteConfig[] = context
  .keys()
  .map((filePath) => {
    const ctx = context(filePath)?.default;
    // コンポーネントがtsとjsでexportされるものが違うので判定
    const value = (ctx.options || ctx) as ComponentOptions<Vue>;
    return value;
  })
  .filter((value) => {
    // pageOptionsのrouterConfigにpathが指定されているものだけを取り出し
    return !!value.pageOptions?.routeConfig?.path;
  })
  .map((v) => {
    const { pageOptions } = v;
    // ありえないがtsでエラーが出るのが嫌なので書く
    if (!pageOptions) return null;
    return {
      name: v.name,
      component: v,
      ...pageOptions.routeConfig,
      meta: {
        pageProps: pageOptions.props,
      },
    };
  })
  .filter(notNull);
