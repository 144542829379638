import Vue from 'vue';

export default class HolidaySearcher {
  static async fetchAPI(year: number): Promise<string[]> {
    const response = await fetch(
      `https://holidays-jp.github.io/api/v1/${year}/date.json`,
    );
    if (!response.ok) {
      return [];
    }
    const json = await response.json();
    const result = Object.keys(json);

    return result;
  }

  static async fetchCacheAPI(year: number, instance: Vue): Promise<string[]> {
    return await instance.$cache.get({
      key: 'api_holidays_' + year,
      exec: async () => {
        const holidays = await this.fetchAPI(year);
        return holidays;
      },
    });
  }
}
