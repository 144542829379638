import {
  ConstantBookOperation,
  ConstantBookOperationDict,
} from '@api-t/constants/bookOperation';

const bookOperationDict: ConstantBookOperationDict = {
  create: {
    text: '新規登録',
    value: 'create',
    append: '',
    mail: '予約完了',
  },
  update: {
    text: '変更',
    value: 'update',
    append: 'の変更',
    mail: '予約変更完了',
  },
  cancel: {
    text: '取消',
    value: 'cancel',
    append: 'の取消',
    mail: '予約取消完了',
  },
};

export const bookOperation: ConstantBookOperation = {
  ...bookOperationDict,
  items: [
    bookOperationDict.create,
    bookOperationDict.update,
    bookOperationDict.cancel,
  ],
  get(value: string): string {
    switch (value) {
      case bookOperationDict.create.value:
        return bookOperationDict.create.text;
      case bookOperationDict.update.value:
        return bookOperationDict.update.text;
      case bookOperationDict.cancel.value:
        return bookOperationDict.cancel.text;
      default:
        return '';
    }
  },
};
