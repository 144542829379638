import { StoreRootExternal } from '@web-t/store/root';
import {
  StoreSearchActions,
  StoreSearchGetters,
  StoreSearchMutations,
  StoreSearchState,
} from '@web-t/store/search';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';

const state: StoreSearchState = {
  search: {
    purposeIds: [],
    facilityIds: [],
    date: '',
  },
};

const getters: DefineGetters<StoreSearchGetters, StoreSearchState> = {
  search(state) {
    return state.search;
  },
};

const mutations: DefineMutations<StoreSearchMutations, StoreSearchState> = {
  setSearch(state, payload) {
    state.search = payload;
  },
  clearSearch(state) {
    state.search = {
      purposeIds: [],
      facilityIds: [],
      date: '',
    };
  },
  setSearchPurposeId(state, payload) {
    state.search = {
      ...state.search,
      purposeIds: [...new Set([...state.search.purposeIds, payload])],
    };
  },
  setSearchPurposeIds(state, payload) {
    state.search = {
      ...state.search,
      purposeIds: [...payload],
    };
  },
  setSearchFacilityId(state, payload) {
    state.search = {
      ...state.search,
      facilityIds: [...new Set([...state.search.facilityIds, payload])],
    };
  },
  setSearchFacilityIds(state, payload) {
    state.search = {
      ...state.search,
      facilityIds: [...payload],
    };
  },
  setSearchDate(state, payload) {
    state.search = {
      ...state.search,
      date: payload,
    };
  },
};

const actions: DefineActions<
  StoreSearchActions,
  StoreSearchState,
  StoreSearchMutations,
  StoreSearchGetters
> = {
  setSearch({ commit }, payload) {
    commit('setSearch', payload);
  },
  clearSearch({ commit }) {
    commit('clearSearch');
  },
  setSearchPurposeId({ commit }, payload) {
    commit('setSearchPurposeId', payload);
  },
  setSearchPurposeIds({ commit }, payload) {
    commit('setSearchPurposeIds', payload);
  },
  setSearchFacilityId({ commit }, payload) {
    commit('setSearchFacilityId', payload);
  },
  setSearchFacilityIds({ commit }, payload) {
    commit('setSearchFacilityIds', payload);
  },
  setSearchDate({ commit }, payload) {
    commit('setSearchDate', payload);
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreSearchState,
    StoreSearchGetters,
    StoreSearchMutations,
    StoreSearchActions
  >('search');

const storeModule: Module<StoreSearchState, StoreRootExternal> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<
    StoreSearchState,
    StoreRootExternal
  >,
};

export default storeModule;
