<template>
  <v-simple-table>
    <template #default>
      <tbody>
        <tr v-for="(item, idx) in tableItems" :key="idx">
          <td>{{ item.text }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script lang="ts">
import { ConstantBaseItem } from '@api-t/constants';
import { inputType } from '@api/constants';
import { Unit } from '@api/models';
import { stringUtility } from '@c/util';
import { CartDetail } from '@web-t/store/cart';
import { chain } from 'lodash';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CartDetailTable',

  props: {
    detail: {
      type: Object as PropType<CartDetail>,
      required: true,
    },
    unitDict: {
      type: Object as PropType<Record<string, Unit>>,
      required: true,
    },
  },

  computed: {
    unit(): Unit | undefined {
      return this.unitDict[this.detail.unitId];
    },

    tableItems(): ConstantBaseItem[] {
      const result = chain(this.unit?.fields)
        .filter((field) => field.isPublic)
        .map((field) => {
          const property = field.property as keyof CartDetail;
          let value = this.detail[property];
          if (field.type === inputType.date.value) {
            value = this.$dateFns.fnsFormatDate(value, '');
          } else if (
            (field.type === inputType.enumMultiple.value ||
              field.type === inputType.unitItems.value) &&
            stringUtility.isJson(value)
          ) {
            value = String(JSON.parse(value));
          } else if (
            field.type === inputType.checkbox.value &&
            stringUtility.isJson(value)
          ) {
            value = (Boolean(JSON.parse(value)) && field.label) || '';
          }

          return { text: field.name, value };
        })
        .value();

      return result;
    },
  },
});
</script>
