import {
  StoreLoadingActions,
  StoreLoadingGetters,
  StoreLoadingMutations,
  StoreLoadingState,
} from '@web-t/store/loading';
import { StoreRootExternal, StoreRootInternal } from '@web-t/store/root';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';

const state: StoreLoadingState = {
  // ローディング表示
  show: false,
  // 強制表示
  force: false,
  // タスク配列
  tasks: [],
  // 現在の有効な非表示プロミス
  hidePromise: null,
};

const getters: DefineGetters<StoreLoadingGetters, StoreLoadingState> = {
  /**
   * loadingを表示するかどうか
   * @param state
   * @returns
   */
  showLoading(state) {
    return state.show || state.force;
  },
};

const mutations: DefineMutations<StoreLoadingMutations, StoreLoadingState> = {
  /**
   * プロミスを登録する
   * @param state
   * @param payload
   */
  register(state, payload: Promise<any>) {
    // タスクに登録
    state.tasks = [...state.tasks, payload];
    // loadingを表示
    state.show = true;
    // 全部が完了したらhideするプロミスを用意
    const completedPromise = Promise.allSettled(state.tasks);
    // 有効な非表示プロミスとして設定する
    state.hidePromise = completedPromise;
    // 非表示プロミスが完了した時の処理
    completedPromise.then(() => {
      if (state.hidePromise === completedPromise) {
        // loadingを非表示にする
        state.show = false;
        // 今あるタスクを空にする
        state.tasks.length = 0;
        // 非表示プロミスを削除
        state.hidePromise = null;
      }
    });
  },

  /**
   * 強制表示
   * @param state
   * @param payload
   */
  setForce(state, payload: boolean) {
    state.force = !!payload;
  },
};

const actions: DefineActions<
  StoreLoadingActions,
  StoreLoadingState,
  StoreLoadingMutations,
  StoreLoadingGetters
> = {
  /**
   * プロミスを登録する
   * @param param0
   * @param payload
   * @returns
   */
  async register({ commit }, payload) {
    commit('register', payload);
    return payload;
  },

  /**
   * 強制表示する
   * @param param0
   * @param payload
   */
  async setForce({ commit }, payload) {
    commit('setForce', payload);
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreLoadingState,
    StoreLoadingGetters,
    StoreLoadingMutations,
    StoreLoadingActions
  >('loading');

const storeModule: Module<
  StoreLoadingState,
  StoreRootExternal | StoreRootInternal
> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<
    StoreLoadingState,
    StoreRootExternal | StoreRootInternal
  >,
};

export default storeModule;
