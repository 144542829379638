import Vue, { PluginObject } from 'vue';
// 使用する関数を都度読み込むこと(容量の節約のため)
import { format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { DateFnsInstance } from '@web-t/datefns';

const instance: DateFnsInstance = {
  format,

  /**
   * dateFnsでDateオブジェクトなどを文字列にフォーマットする
   * @param {Date|Number} value 値
   * @param {String} nullValue エラーもしくはnullの時に返す値
   * @param {String} formatPattern フォーマットのパターン
   * @param {Object} option フォーマットのオプション
   *
   * @returns {String} フォーマットされた文字列
   */
  fnsFormat(value, nullValue = '', formatPattern, option = { locale: ja }) {
    if (!value) return nullValue;
    if (typeof value === 'string') {
      try {
        // perseISOしてトライ
        return format(parseISO(value), formatPattern, option);
      } catch (ignored) {
        //
      }
    }
    try {
      // 日付にしてトライ
      return format(new Date(value), formatPattern, option);
    } catch (ignored) {
      //
    }
    if (typeof value !== 'string') {
      try {
        // 文字列でそのままトライ
        return format(value, formatPattern, option);
      } catch (ignored) {
        //
      }
    }
    // 無理なら空で返す
    return nullValue;
  },

  fnsFormatDatetimeSec(value, nullValue = '', option) {
    return instance.fnsFormat(
      value,
      nullValue,
      'yyyy年MM月dd日(E) HH:mm:ss',
      option,
    );
  },

  fnsFormatDatetime(value, nullValue = '', option) {
    return instance.fnsFormat(
      value,
      nullValue,
      'yyyy年MM月dd日(E) HH:mm',
      option,
    );
  },

  fnsFormatDate(value, nullValue = '', option) {
    return instance.fnsFormat(value, nullValue, 'yyyy年MM月dd日(E)', option);
  },

  fnsFormatTime(value, nullValue = '', option) {
    return instance.fnsFormat(value, nullValue, 'HH:mm', option);
  },
};

/**
 * @mixin
 */
const plugin: PluginObject<void> = {
  install: function (Vue) {
    Vue.prototype.$dateFns = instance;
    Vue.dateFns = instance;
  },
};

Vue.use(plugin);
export default plugin;

// filter
Vue.filter('datetimesec', instance.fnsFormatDatetimeSec);
Vue.filter('datetime', instance.fnsFormatDatetime);
Vue.filter('date', instance.fnsFormatDate);
Vue.filter('time', instance.fnsFormatTime);
