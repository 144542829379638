<template>
  <v-progress-circular
    :size="100"
    :width="6"
    color="#1f891f"
    indeterminate
  ></v-progress-circular>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'LoadingInner',
});
</script>
