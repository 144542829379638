import { CachePlugin, CachePluginGetProps } from '@web-t/cache';
import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';

interface CacheValues {
  [kind: string]: CacheValueKind[];
}
interface CacheValueKind {
  key: string;
  data: any;
  expire: Date;
}

class CacheManager implements CachePlugin {
  private static cacheValues: CacheValues = {};

  /**
   * キャッシュを取得する
   * @param props
   * @returns
   */
  async get<T = any>(props: CachePluginGetProps<T>): Promise<T> {
    const { kind = '__undefined__', key, exec, age = 1 * 60 * 60 } = props;
    // 期限切れを削除
    await this.clearCacheAgeExpired();

    const cacheValues = CacheManager.cacheValues;

    // キャッシュにkindがなければセット
    if (!cacheValues[kind]) {
      cacheValues[kind] = [];
    }

    // キャッシュの中から対象のデータを見つける
    const cacheData = cacheValues[kind].find((c) => c.key === key);

    // 見つかればそれを返す
    if (cacheData !== undefined) {
      return cacheData.data as T;
    }

    // 見つからない場合

    // 期限を設定
    const expire = new Date();
    expire.setSeconds(expire.getSeconds() + age);

    // 結果を生成
    const data = await exec();

    // キャッシュにセット
    cacheValues[kind] = [
      ...cacheValues[kind],
      {
        key,
        data,
        expire,
      },
    ];

    return data;
  }

  /**
   * 期限切れのものを削除する
   */
  private async clearCacheAgeExpired() {
    const now = new Date();

    await Promise.all(
      Object.entries(CacheManager.cacheValues).map(
        async ([kind, kindValues]) => {
          CacheManager.cacheValues[kind] =
            kindValues?.filter((v) => v.expire > now) || [];
        },
      ),
    );
  }

  /**
   * 種別ごとのキャッシュをクリアする
   * @param kind
   */
  clearCacheByKind(kind: string) {
    delete CacheManager.cacheValues[kind];
  }

  /**
   * getterをビルドする
   * @param props
   * @returns
   */
  buildGetter<T = any>(props: CachePluginGetProps<T>): () => Promise<T> {
    return () => {
      return this.get(props);
    };
  }
}

const plugin: PluginObject<void> = {
  install: function (Vue) {
    const cacheInstance = new CacheManager();
    Vue.prototype.$cache = cacheInstance;
    Vue.$cache = cacheInstance;
  },
};

Vue.use(plugin);
