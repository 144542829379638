<template>
  <div>
    <v-text-field
      :value="value"
      :outlined="outlined"
      :dense="dense"
      :label="label"
      :clearable="clearable"
      :hide-details="hideDetails"
      readonly
      v-bind="{ rules, ...textFieldProps }"
      role="button"
      :aria-haspopup="true"
      :aria-expanded="`${menu}`"
      @click:clear="clear"
      @keydown.delete="clear"
      @click.stop="menu = true"
      @keydown.enter.space.stop="menu = true"
    ></v-text-field>
    <v-dialog v-model="menu" width="auto">
      <DateSelector v-bind="dateSelectorAttrs" :value="value" @input="input" />
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import DateSelector, { props as dateSelectorProps } from './date-selector.vue';

export default Vue.extend({
  name: 'DatePicker',

  components: {
    DateSelector,
  },

  props: {
    value: {
      type: String,
      default: () => '',
    },

    outlined: {
      type: Boolean,
      default: true,
    },

    dense: {
      type: Boolean,
      default: true,
    },

    label: {
      type: String,
      default: () => '',
    },

    clearable: {
      type: Boolean,
      default: () => false,
    },

    hideDetails: {
      type: [Boolean, String],
      default: true,
    },

    rules: {
      type: Array as PropType<
        (boolean | string | ((v: any) => boolean | string))[]
      >,
      default: () => [],
    },

    textFieldProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },

    ...dateSelectorProps,
  },

  data: () => ({
    menu: false,
  }),

  computed: {
    dateSelectorAttrs(): Record<string, any> {
      return Object.keys(dateSelectorProps).reduce((prev, nextKey) => {
        const nextValue = (this as any)[nextKey];
        if (nextValue !== undefined) {
          return {
            ...prev,
            [nextKey]: nextValue,
          };
        }
        return prev;
      }, {});
    },
  },

  methods: {
    input(value: string) {
      this.menu = false;
      this.$emit('input', value);
    },

    clear() {
      this.$emit('input', '');
      this.$emit('clear');
    },
  },
});
</script>
