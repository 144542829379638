<template>
  <!-- 同じパスならaタグでページ遷移させる -->
  <router-link v-if="currentPath !== resolvedPath" v-bind="$attrs">
    <slot />
  </router-link>
  <a v-else :href="resolvedObject.href">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'CustomLink',

  computed: {
    currentPath() {
      return this.$route.path;
    },
    resolvedObject() {
      return this.$router.resolve(this.$attrs.to, this.$route);
    },
    resolvedPath() {
      return this.resolvedObject?.route?.path;
    },
  },
};
</script>
