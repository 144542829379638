<template>
  <div>
    <template v-for="(tbl, i) in tables">
      <!-- 枠貸 -->
      <div
        v-if="tbl.type === rentalType.frame.value"
        :key="i"
        :class="tbl.kind"
      >
        <table>
          <tbody>
            <tr>
              <th class="spNone"></th>
              <th colspan="5">
                {{ dateLabel }}
              </th>
            </tr>
            <tr v-for="(row, j) in tbl.rows" :key="j">
              <td v-if="!!row.name" :rowspan="row.rowspan" class="text-left">
                {{ row.name }}
              </td>
              <availability
                v-for="detail in row.details"
                :key="detail.label"
                :label="detail.label"
                :ratio="detail.ratio"
                :remain="detail.max - detail.sum"
                :type="row.type"
                @click="
                  $emit('click:cell', {
                    facilityId: item.id,
                    row,
                    detail,
                  })
                "
              ></availability>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 時間貸 -->
      <div v-else-if="tbl.type === rentalType.time.value" :key="i">
        <div class="py-2 text-md-h5 text-h6 grey--text text--darken-1">
          この表は横にスクロールできます。
        </div>
        <div :class="tbl.kind">
          <table>
            <tbody>
              <tr>
                <th class="text-left fixed"></th>
                <th v-for="detail in tbl.rows[0].details" :key="detail.label">
                  <span>
                    {{ `${detail.label}~` }}
                  </span>
                </th>
              </tr>
              <tr v-for="row in tbl.rows" :key="row.id">
                <td class="text-left fixed">
                  {{ row.name }}
                </td>
                <availability
                  v-for="detail in row.details"
                  :key="detail.label"
                  :ratio="detail.ratio"
                  :remain="detail.max - detail.sum"
                  :type="row.type"
                  @click="
                    $emit('click:cell', {
                      facilityId: item.id,
                      row,
                      detail,
                    })
                  "
                ></availability>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {
  DetailAvailability,
  WeeklyAvailability,
} from '@api-i/routes/availability/availability';
import { rentalType } from '@api/constants';
import Availability from './Availability.vue';
import { chunk, groupBy } from 'lodash';
import Vue, { PropType } from 'vue';

export interface ClickDailyCellEventData {
  facilityId: string;
  row: {
    id: string;
    name: string;
    type: string;
  };
  detail: DetailAvailability;
}

export default Vue.extend({
  name: 'DailyAvailabilityTable',

  components: {
    Availability,
  },

  props: {
    dates: {
      type: Array as PropType<Date[]>,
      required: true,
    },

    dispIdx: {
      type: Number,
      required: true,
    },

    item: {
      type: Object as PropType<WeeklyAvailability>,
      required: true,
    },
  },

  computed: {
    tables() {
      const idx = this.dispIdx as number;

      // 区画種類を貸区分ごとにまとめる
      const obj = groupBy(this.item.units, 'type');

      // 貸区分ごとにテーブルを作成する
      const tables = Object.entries(obj)
        .map(([type, units]) => {
          if (type === rentalType.frame.value) {
            // 枠貸
            const obj_ = groupBy(units, (unit) => {
              const { max, details } = unit.days[idx];
              if (!!max && !details.length) {
                return 'tableDayQuantity';
              }
              return 'tableDay';
            });
            const tables_ = Object.entries(obj_).flatMap(([kind, units_]) => {
              if (kind === 'tableDayQuantity') {
                // 定量枠貸
                const rows = units_.flatMap((unit_) => {
                  const { max, ratio, sum, type: dayType } = unit_.days[idx];
                  const details_ = [{ max, ratio, sum }];
                  return {
                    id: unit_.id,
                    name: unit_.name,
                    details: details_,
                    type: dayType,
                  };
                });
                return { kind, type, rows };
              } else if (kind === 'tableDay') {
                // 選択枠貸
                const rows = units_.flatMap((unit_) => {
                  const { details, type: dayType } = unit_.days[idx];
                  const splitSize = 5;
                  const rowspan =
                    Math.floor((details.length - 1) / splitSize) + 1;
                  const result = chunk(details, splitSize).map(
                    (details_, i) => {
                      if (i === 0) {
                        return {
                          id: unit_.id,
                          name: unit_.name,
                          rowspan,
                          details: details_,
                          type: dayType,
                        };
                      }
                      return { id: unit_.id, details: details_, type: dayType };
                    },
                  );
                  return result;
                });
                return { kind, type, rows };
              }
            });
            return tables_;
          } else if (type === rentalType.time.value) {
            // 時間貸
            const obj_ = groupBy(units, (unit) => {
              const { start, end, step } = unit.days[idx];
              return `${start},${end},${step}`;
            });
            // 利用時間帯ごとにテーブルを作成する
            const tables_ = Object.entries(obj_).flatMap(([key, units_]) => {
              const [start, end, step] = key.split(',');
              if (
                start === 'undefined' ||
                end === 'undefined' ||
                !Number(step)
              ) {
                return [];
              }
              const rows = units_.map((unit_) => ({
                id: unit_.id,
                name: unit_.name,
                details: unit_.days[idx].details,
                type: unit_.days[idx].type,
              }));
              return { kind: 'tableDaySports', type, rows };
            });
            return tables_;
          }
        })
        .flat();
      return tables;
    },

    dateLabel() {
      const DATE_FORMAT = 'yyyy年M月d日(E)';
      return this.$dateFns.fnsFormat(this.dates[this.dispIdx], '', DATE_FORMAT);
    },

    rentalType() {
      return rentalType;
    },
  },
});
</script>
