<template>
  <v-form>
    <v-row>
      <v-col cols="12" class="pb-md-0">
        <v-autocomplete
          :value="search.facilityIds"
          :items="facilities"
          item-text="name"
          item-value="id"
          outlined
          dense
          chips
          small-chips
          hide-details="auto"
          label="利用施設"
          prepend-inner-icon="mdi-format-list-bulleted"
          multiple
          clearable
          @input="setSearchFacilityIds"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="9" class="pr-md-0">
        <v-autocomplete
          :value="search.purposeIds"
          :items="purposes"
          item-text="name"
          item-value="id"
          outlined
          dense
          chips
          small-chips
          hide-details="auto"
          label="利用目的"
          prepend-inner-icon="mdi-flag"
          multiple
          clearable
          @input="setSearchPurposeIds"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <DatePicker
          :value="search.date"
          label="利用予定日"
          :text-field-props="{
            chips: true,
            'small-chips': true,
            'prepend-inner-icon': 'mdi-calendar-blank-outline',
          }"
          clearable
          @input="setSearchDate"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Facility, Purpose } from '@api/models';
import DatePicker from '@web/components/inputs/date-picker.vue';
import { mapActions, mapGetters } from 'vuex';

/**
 * 検索のデフォルト値
 */
export default Vue.extend({
  name: 'SearchForm',

  components: {
    DatePicker,
  },

  props: {
    purposes: {
      type: Array as PropType<Purpose[]>,
      required: true,
    },
    facilities: {
      type: Array as PropType<Facility[]>,
      required: true,
    },
  },

  computed: {
    ...mapGetters('search', ['search']),
  },

  methods: {
    ...mapActions('search', [
      'setSearchPurposeIds',
      'setSearchFacilityIds',
      'setSearchDate',
    ]),
  },
});
</script>
