var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.tables),function(unit){return _c('div',{key:unit.id},[_c('div',[_vm._v(_vm._s(unit.name))]),_c('div',{staticClass:"tableMonth my-5"},[_c('table',[_c('tbody',[_c('tr',_vm._l((_vm.monthlyTableHeader),function(m,i){return _c('th',{key:i},[_c('span',{staticClass:"week",class:m.style},[_vm._v(" "+_vm._s(m.label)+" ")])])}),0),_vm._l((unit.days),function(days_,i){return _c('tr',{key:i},_vm._l((days_),function(day,j){return _c('availability',{key:j,attrs:{"label":day.label,"ratio":day.ratio,"remain":unit.type === _vm.rentalType.frame.value
                  ? day.max - day.sum
                  : undefined,"type":day.type},on:{"click":function($event){return _vm.$emit('click:cell', {
                  facilityId: _vm.item.id,
                  unitId: unit.id,
                  day: day,
                  row: i,
                  col: j,
                })}}})}),1)})],2)])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }