<template>
  <td class="itemUnit">
    <v-row>
      <v-col
        v-for="field in filteredFields"
        :key="field.id"
        class="d-flex flex-column pb-0"
        cols="12"
        sm="6"
      >
        <span class="price d-flex align-center">{{ field.name }}</span>
        <VariableInputField
          v-model="item[field.property]"
          :field="field"
          :unit="unit"
          :usage-time="usageTime"
        />
      </v-col>
    </v-row>
  </td>
</template>

<script lang="ts">
import { InsertDetailRequest } from '@api-i/routes/detail/detail';
import { schemaOperation } from '@api/constants';
import { Field, Unit, UsageTime } from '@api/models';
import VariableInputField from '@web-e/components/VariableInputField.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CartOptionDetailCell',

  components: {
    VariableInputField,
  },

  props: {
    value: {
      type: Object as PropType<InsertDetailRequest>,
      default: undefined,
    },

    unit: {
      type: Object as PropType<Unit>,
      default: undefined,
    },

    holidays: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  computed: {
    item: {
      get(): InsertDetailRequest {
        return this.value;
      },
      set(value: InsertDetailRequest): void {
        this.$emit('input', { ...this.value, value });
      },
    },

    usageTime(): UsageTime | undefined {
      if (!this.unit?.usageTimes) {
        return undefined;
      }
      const { startDate } = this.item;
      if (!startDate) {
        return undefined;
      }
      const result = schemaOperation.find(
        this.unit.usageTimes,
        {
          system: { date: startDate },
        },
        { holidays: this.holidays },
      );

      return result;
    },

    filteredFields(): Field[] | undefined {
      return this.unit?.fields?.filter((field) => field.isPublic);
    },
  },
});
</script>
