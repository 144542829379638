<template>
  <div class="reserveDetail__item reserveItem">
    <section class="reserveItem__list">
      <h3 class="secTtl">{{ unitDict[detail.unitId]?.name }}</h3>
      <CartDetailTable :detail="detail" :unit-dict="unitDict" />
      <div class="d-flex flex-row-reverse">{{ amount.toLocaleString() }}円</div>
      <slot name="action" />
    </section>
  </div>
</template>

<script lang="ts">
import { Unit } from '@api/models';
import { CartDetail } from '@web-t/store/cart';
import Vue, { PropType } from 'vue';
import CartDetailTable from './CartDetailTable.vue';

export default Vue.extend({
  name: 'CartDetail',

  components: {
    CartDetailTable,
  },

  props: {
    detail: {
      type: Object as PropType<CartDetail>,
      required: true,
    },
    unitDict: {
      type: Object as PropType<Record<string, Unit>>,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },
});
</script>
