import { SnackbarShortProps } from '@web-t/store/snackbar';
import { WebApiError } from '@web/plugins/api';
import Vue from 'vue';

/**
 * apiのエラーハンドラー
 * @param err
 * @returns WebApiErrorの場合はtrue
 */
export function handleApiError(
  err: any,
  instance: Vue,
  options?: { prefix?: string[] },
): boolean {
  const { prefix = [] } = options || {};
  if (err instanceof WebApiError) {
    const error = err as WebApiError;
    const { detail } = error;
    const { name: errorName } = detail;

    // snackbar登録
    const register = getRegister(instance);

    switch (errorName) {
      case 'SequelizeValidationError': {
        // バリデートエラーの時はその内容を表示する
        register({
          type: 'error',
          message: [
            ...prefix,
            ...(detail.error as any).errors.map((e: any) => `・${e.message}`),
          ].join('\n'),
        });
        break;
      }
      case 'Forbidden': {
        register({
          type: 'error',
          message: [...prefix, '権限エラー', `・${detail.message}`].join('\n'),
        });
        break;
      }
      case 'Bad Request': {
        const messages = detail.message
          .split('\n')
          .map((message) => `・${message}`);

        register({
          type: 'error',
          message: [...prefix, ...messages].join('\n'),
        });
        break;
      }
      // TODO その他の予期できるエラーの処理をここに追記
      default: {
        register({
          type: 'error',
          message: [
            '不明なエラーが発生しました。',
            errorName,
            detail.message,
          ].join('\n'),
        });
        break;
      }
    }

    return true;
  }
  return false;
}

/**
 * なんでもエラー用
 * @param err
 * @param instance
 */
export function handleUnknownError(err: any, instance: Vue) {
  const error = err as Error;
  const register = getRegister(instance);
  register({
    type: 'error',
    message: [
      '予期しないエラーが発生しました。',
      error.name,
      error.message,
    ].join('\n'),
  });
}

/**
 * snackbar登録用functionを返す
 * @param instance
 * @returns
 */
function getRegister(instance: Vue) {
  const result = async function (props: SnackbarShortProps) {
    await instance.$store.dispatch('snackbar/register', props);
  };
  return result;
}
