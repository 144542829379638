var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tableWeek"},[_c('table',[_c('tbody',[_c('tr',[_c('th',{staticClass:"text-left fixed"}),_vm._l((_vm.weeklyTableHeader),function(w,i){return _c('th',{key:i},[_c('span',{staticClass:"week",class:w.style},[_vm._v(" "+_vm._s(w.label)+" ")]),_c('span',{staticClass:"day"},[_vm._v(" "+_vm._s(w.text)+" ")])])})],2),_vm._l((_vm.item.units),function(unit){return _c('tr',{key:unit.id},[_c('td',{staticClass:"text-left fixed"},[_vm._v(" "+_vm._s(unit.name)+" ")]),_vm._l((unit.days),function(day,j){return _c('availability',{key:day.date,attrs:{"ratio":day.ratio,"remain":unit.type === _vm.rentalType.frame.value
              ? day.max - day.sum
              : undefined,"type":day.type},on:{"click":function($event){return _vm.$emit('click:cell', {
              facilityId: _vm.item.id,
              unitId: unit.id,
              day: day,
              col: j,
            })}}})})],2)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }