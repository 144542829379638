import { ConstantSystemFields } from '@api-t/constants/systemFields';
import { inputType } from './inputType';

export const systemFields: ConstantSystemFields = {
  date: {
    name: '利用日',
    property: 'system.date',
    type: inputType.date.value,
    typeName: inputType.date.text,
    items_: [],
  },
  usually: [
    {
      name: '区画種類の選択数',
      property: 'system.sectionLength',
      type: inputType.number.value,
      typeName: inputType.number.text,
      items_: [],
    },
    {
      name: '経過日数',
      property: 'system.elapsedDays',
      type: inputType.number.value,
      typeName: inputType.number.text,
      items_: [],
    },
    {
      name: '経過時間（時間）',
      property: 'system.elapsedHours',
      type: inputType.number.value,
      typeName: inputType.number.text,
      items_: [],
    },
    {
      name: '経過時間（分）',
      property: 'system.elapsedMinutes',
      type: inputType.number.value,
      typeName: inputType.number.text,
      items_: [],
    },
  ],
  book: [
    {
      name: '受付番号',
      property: 'receiptNo',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '氏名',
      property: 'name',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: 'かな',
      property: 'kana',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '郵便番号',
      property: 'zipCode',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '住所',
      property: 'address',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '電話番号',
      property: 'tel',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: 'E-mail',
      property: 'email',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '最終更新日時',
      property: 'updatedAtJP',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
  ],
  facility: [
    {
      name: '施設名',
      property: 'facility.name',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）地区名',
      property: 'facility.area',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）概要',
      property: 'facility.overview',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）住所',
      property: 'facility.address',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）TEL',
      property: 'facility.tel',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）営業時間',
      property: 'facility.businessHours',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）定休日',
      property: 'facility.regularHoliday',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）利用料金',
      property: 'facility.usageFee',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）リンク先',
      property: 'facility.url',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '施設内マップ名',
      property: 'facility.imageName',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
    {
      name: '（施設の）地図URL',
      property: 'facility.mapUrl',
      type: inputType.text.value,
      typeName: inputType.text.text,
      items_: [],
    },
  ],
};
