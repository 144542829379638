<template>
  <v-app>
    <LoadingOverlay />
    <MultiSnackbar
      :objects.sync="$store.state.snackbar.messages"
      transition="scroll-y-transition"
    />
    <v-app-bar
      app
      clipped
      class="header"
      :class="{ 'is-logged-in': isLoggedIn }"
    >
      <template v-if="!isLoggedIn">
        <custom-link class="logo" to="/">
          <div class="logoImg">
            <v-img
              src="../../assets/img/common/head_logo.jpg"
              alt="オクシズばった"
            ></v-img>
          </div>
        </custom-link>
      </template>

      <template v-else>
        <div
          class="d-flex flex-column flex-sm-row align-start align-sm-center justify-center"
        >
          <custom-link class="logo" to="/">
            <div class="logoImg">
              <v-img
                src="../../assets/img/common/head_logo.jpg"
                alt="オクシズ"
              ></v-img>
            </div>
          </custom-link>
          <custom-link
            class="pl-3 pl-sm-4 headResNum d-flex flex-column justify-center align-start"
            to="/reserveEdit"
          >
            <span class="mb-0">受付番号：{{ book?.receiptNo }}</span>
            <span class="mb-0 txt">予約の変更 / 取消中</span>
          </custom-link>
        </div>
      </template>

      <div class="linkList">
        <div class="btnList">
          <v-btn depressed to="/search" class="pcShow">
            <span class="orgIcon icon-search"></span>
            <span class="txt">検索</span>
          </v-btn>

          <v-btn
            depressed
            to="/reserveCart"
            class="cart"
            :class="{ pcShow: isLoggedIn }"
          >
            <span class="orgIcon icon-cart">
              <span v-if="cartSize > 0" class="num">
                {{ cartSize }}
              </span>
            </span>
            <span class="txt">予約カート</span>
          </v-btn>

          <template v-if="!isLoggedIn">
            <v-btn depressed to="/reserveLogin" class="pcShow">
              <span class="orgIcon icon-calendar"></span>
              <span class="txt">予約変更 / 取消</span>
            </v-btn>
          </template>

          <template v-else>
            <v-btn depressed to="/reserveLogin">
              <span class="orgIcon icon-calendar-cancel"></span>
              <span class="txt">変更 / 取消を中止</span>
            </v-btn>
          </template>
        </div>
        <v-app-bar-nav-icon class="hamburger" @click.stop="drawer = !drawer">
          <div class="navToggle">
            <span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <p>メニュー</p>
        </v-app-bar-nav-icon>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" right temporary app>
      <v-list nav>
        <div class="closeButton d-flex justify-end">
          <v-btn @click="drawer = false"
            ><span class="mdi mdi-window-close"></span>
          </v-btn>
        </div>

        <v-list-item-group class="menuMain">
          <v-list-item
            v-for="item in menuMain"
            :key="item.name"
            :to="item.link"
          >
            <span class="orgIcon">
              <v-icon class="txtC-orange">{{ item.icon }}</v-icon>
            </span>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group class="menuSub">
          <v-list-item to="/news">
            <v-icon>mdi-chevron-right</v-icon>
            <v-list-item-title>お知らせ</v-list-item-title>
          </v-list-item>

          <v-list-item to="/link">
            <v-icon>mdi-chevron-right</v-icon>
            <v-list-item-title>オクシズ関連リンク一覧</v-list-item-title>
          </v-list-item>

          <v-list-item
            href="https://shizuokashi-road.appspot.com/index_pub.html"
            target="_blank"
          >
            <v-icon>mdi-chevron-right</v-icon>
            <v-list-item-title>しずみちインフォ</v-list-item-title>
          </v-list-item>

          <v-list-item to="/terms">
            <v-icon>mdi-chevron-right</v-icon>
            <v-list-item-title>利用規約</v-list-item-title>
          </v-list-item>

          <v-list-item to="/privacy">
            <v-icon>mdi-chevron-right</v-icon>
            <v-list-item-title>プライバシーポリシー</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <slot />
    </v-main>

    <footer class="footer">
      <div class="copyCont inner">
        <p>&copy; 2022 オクシズばった</p>
        <p>powered by CompRe:</p>
      </div>
    </footer>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { chain } from 'lodash';
import LoadingOverlay from '@web/components/LoadingOverlay.vue';
import MultiSnackbar from '@web/components/MultiSnackbar.vue';
import { mapState as mapStateCart } from '@web-e/store/cart';
import { mapState as mapStateLoginStatus } from '@web-e/store/loginStatus';

export default Vue.extend({
  name: 'ExternalLayout',

  components: {
    LoadingOverlay,
    MultiSnackbar,
  },

  data: () => ({
    drawer: false,
    group: null,
  }),

  computed: {
    cartSize() {
      const result = chain(this.details)
        .values()
        .map((details) => details.length)
        .sum()
        .value();

      return result;
    },

    menuMain() {
      return [
        {
          title: '検索',
          icon: 'icon-search',
          link: '/search',
        },
        {
          title: '予約カート',
          icon: 'icon-cart',
          link: '/reserveCart',
        },
        {
          title: '予約変更 / 取消',
          icon: 'icon-calendar',
          link: this.isLoggedIn ? '/reserveEdit' : '/reserveLogin',
        },
      ];
    },

    ...mapStateCart(['book', 'details']),
    ...mapStateLoginStatus(['isLoggedIn']),
  },
});
</script>
