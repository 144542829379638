import { ConstantValidationRule } from '@api-t/constants/validationRule';
import { chain, isString } from 'lodash';

export const validationRule: ConstantValidationRule = {
  items: {
    name: [(v) => !!v || '氏名は必須項目です。'],
    kana: [
      (v) => !!v || 'かなは必須項目です。',
      (v) =>
        /^[\u0020|\u3000|\u3040-\u309F]+$/.test(v) ||
        'かなはひらがなのみ入力できます。',
    ],
    zipCode: [
      (v) => !!v || '郵便番号は必須項目です。',
      (v) => /^[-\d]+$/.test(v) || '郵便番号はハイフンと数字のみ入力できます。',
    ],
    address: [(v) => !!v || '住所は必須項目です。'],
    tel: [
      (v) => !!v || '電話番号は必須項目です。',
      (v) => /^[-\d]+$/.test(v) || '電話番号はハイフンと数字のみ入力できます。',
    ],
    email: [
      (v) => !!v || 'E-mailは必須項目です。',
      (v) =>
        /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+$/.test(v) ||
        'E-mailはメールアドレスの形式で入力して下さい。',
    ],
  },

  eval: (data) => {
    const result = chain(validationRule.items)
      .mapValues((v, k) => {
        const result_ = chain(v)
          .map((f) => f(data?.[k]))
          .head()
          .value();

        return result_;
      })
      .values()
      .filter(isString)
      .value();

    return result;
  },
};
