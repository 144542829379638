import {
  ConstantRoundingType,
  ConstantRoundingTypeDict,
} from '@api-t/constants/roundingType';

const roundingTypeDict: ConstantRoundingTypeDict = {
  ceil: {
    text: '切り上げ',
    value: 'ceil',
  },
  floor: {
    text: '切り捨て',
    value: 'floor',
  },
  round: {
    text: '四捨五入',
    value: 'round',
  },
};

export const roundingType: ConstantRoundingType = {
  ...roundingTypeDict,
  items: [
    roundingTypeDict.ceil,
    roundingTypeDict.floor,
    roundingTypeDict.round,
  ],
  get(value: string): string {
    switch (value) {
      case roundingTypeDict.ceil.value:
        return roundingTypeDict.ceil.text;
      case roundingTypeDict.floor.value:
        return roundingTypeDict.floor.text;
      case roundingTypeDict.round.value:
        return roundingTypeDict.round.text;
      default:
        return '';
    }
  },
};
