<template>
  <div role="radiogroup">
    <label
      v-for="(item, index) in items"
      :key="item.value"
      ref="items"
      role="radio"
      :aria-checked="item.value === _value"
      :tabindex="(index === focusedIndex) - 1"
      :aria-label="item.text"
      @keydown.up.left.prevent="prev"
      @keydown.down.right.prevent="next"
    >
      <input v-model="_value" type="radio" :value="item.value" />
      <span>{{ item.text }}</span>
    </label>
  </div>
</template>

<script lang="ts">
import { ConstantBaseItem } from '@api-t/constants';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'RadioGroup',

  props: {
    value: {
      type: Number,
      default: 0,
    },

    items: {
      type: Array as PropType<ConstantBaseItem<number>[]>,
      default: () => [],
    },
  },

  computed: {
    _value: {
      get(): number {
        return this.value;
      },
      set(value: number): void {
        this.$emit('input', value);
      },
    },

    focusedIndex(): number {
      return Math.max(
        0,
        this.items.findIndex((item) => item.value === this._value),
      );
    },

    prev() {
      return () => {
        const newIdx =
          (this.focusedIndex + this.items.length - 1) % this.items.length;
        this._value = this.items[newIdx].value;
      };
    },

    next() {
      return () => {
        const newIdx =
          (this.focusedIndex + this.items.length + 1) % this.items.length;
        this._value = this.items[newIdx].value;
      };
    },
  },

  watch: {
    _value() {
      (this.$refs.items as HTMLElement[])[this.focusedIndex].focus();
    },
  },
});
</script>
