<template>
  <Cart>
    <template #top>
      <slot name="top" />
    </template>

    <template #action.detail="{ facilityId, index }">
      <!-- 操作 -->
      <v-card-actions class="btnArea -list">
        <v-btn
          depressed
          x-small
          outlined
          class="btnBasic -icoNone"
          @click="openDetail(facilityId, index)"
          >変更</v-btn
        >
        <v-btn
          depressed
          x-small
          outlined
          class="btnBasic -back -icoNone"
          @click="removeDetail(facilityId, index)"
          >削除</v-btn
        >
      </v-card-actions>
    </template>

    <template #titleSide.option="{ facilityId }">
      <!-- 追加ボタン -->
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            x-small
            class="btnBasic -icoNone"
            :disabled="!selectableOptions[facilityId].length"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>追加
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="option in selectableOptions[facilityId]"
            :key="option.date"
            @click="openOptionDetail(facilityId, option.value)"
          >
            <v-list-item-title>
              {{ option.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template #action.option="{ facilityId, date }">
      <!-- 操作 -->
      <v-card-actions class="btnArea -list">
        <v-btn
          depressed
          x-small
          outlined
          class="btnBasic -icoNone"
          @click="openOptionDetail(facilityId, date)"
          >変更</v-btn
        >
        <v-btn
          depressed
          x-small
          outlined
          class="btnBasic -back -icoNone"
          @click="removeOption(facilityId, date)"
          >削除</v-btn
        >
      </v-card-actions>
    </template>

    <template #bottom>
      <slot name="bottom" />

      <!-- ディテール編集ダイアログ -->
      <CartDetailDialog
        v-model="dialog"
        :units="units"
        :holidays="holidays"
        @save="saveDetail"
      ></CartDetailDialog>

      <!-- オプション編集ダイアログ -->
      <CartOptionDetail
        v-model="optionDialog"
        :units="units"
        :holidays="holidays"
        @save="saveOption"
      />
    </template>
  </Cart>
</template>

<script lang="ts">
import Vue from 'vue';
import { chain } from 'lodash';
import Cart from './Cart.vue';
import { dateUtility } from '@c/util';
import CartDetailDialog, {
  CartDetailDialogItem,
} from '@web-e/components/cart/detail/CartDetailDialog.vue';
import CartOptionDetail from '@web-e/components/cart/option/CartOptionDetail.vue';
import { Unit } from '@api/models';
import { loadCacheUnits } from '@web/modules/master-loader';
import { mapActions, mapState } from '@web-e/store/cart';
import { CartDetail } from '@web-t/store/cart';
import HolidaySearcher from '@web/modules/holiday-searcher';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';

export default Vue.extend({
  name: 'CartEditable',

  components: {
    Cart,
    CartDetailDialog,
    CartOptionDetail,
  },

  data: () => ({
    units: [] as Unit[],

    holidays: [] as string[],

    editedFacilityId: '',

    editedIndex: -1,

    dialog: {
      show: false,
      item: {} as CartDetailDialogItem,
    },

    optionDialog: {
      show: false,
      item: {},
    },
  }),

  computed: {
    /**
     * 選択可能なオプション
     */
    selectableOptions(): any {
      const result = chain(this.details)
        .mapValues((details, facilityId) => {
          return chain(details)
            .map(dateUtility.getISODatesFromObj)
            .flatten()
            .uniq()
            .filter((v) => {
              return !chain(this.options[facilityId])
                .keys()
                .value()
                .some((date) => {
                  return date === v;
                });
            })
            .map((v) => ({
              value: v,
              text: this.$dateFns.fnsFormat(v, '', 'yyyy年M月d日(E) 利用分'),
            }))
            .value();
        })
        .value();

      return result;
    },

    ...mapState(['details', 'options']),
  },

  async mounted() {
    const year = new Date().getFullYear();
    await Promise.all([
      this.loadUnits(),
      this.loadHolidays(year),
      this.loadHolidays(year + 1),
    ]);
  },

  methods: {
    /**
     * 区画種類マスタを読み込む
     */
    async loadUnits() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },

    /**
     * 祝日をAPIから取得する
     */
    async loadHolidays(year: number) {
      const holidays = await HolidaySearcher.fetchCacheAPI(year, this);
      this.$set(this, 'holidays', [...this.holidays, ...holidays]);
    },

    /**
     * ディテールの編集ダイアログを開く
     */
    openDetail(facilityId: string, index: number) {
      this.editedFacilityId = facilityId;
      this.editedIndex = index;
      const item = structuredClone(this.details[facilityId][index]);
      this.$set(this, 'dialog', {
        show: true,
        item: item,
      });
    },

    /**
     * ディテールと利用日が異なるオプションを削除する
     */
    removeOptionWithDifferentDays() {
      const result = chain(this.options)
        .mapValues((o, facilityId) => {
          const result_ = chain(o)
            .pickBy((_, q) => {
              const dates = chain(this.details[facilityId])
                .map(dateUtility.getISODatesFromObj)
                .flatten()
                .uniq()
                .value();
              return dates.includes(q);
            })
            .value();

          return result_;
        })
        .value();

      this.setOptions(result);
    },

    /**
     * ディテールの編集内容を保存する
     */
    async saveDetail(item: CartDetail) {
      const facilityId = this.editedFacilityId;
      try {
        // 事前にバリデーション
        const result = await this.$api({
          path: '/detail/validate',
          method: 'post',
          params: {
            facilityId,
            detail: item,
          },
        });
        if (result) {
          const index = this.editedIndex;
          const details = structuredClone(this.details);
          const items = details[facilityId].slice();
          items[index] = item;
          this.setDetails_(facilityId, items);
          this.removeOptionWithDifferentDays();
          this.dialog.show = false;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['入力内容に不備があります。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
        }
      }
    },

    /**
     * ディテールを予約カートから削除する
     */
    removeDetail(facilityId: string, index: number) {
      if (!confirm('予約カートから削除します。よろしいですか？')) {
        return;
      }
      const details = structuredClone(this.details);
      const items = [
        ...details[facilityId].slice(0, index),
        ...details[facilityId].slice(index + 1),
      ];
      this.setDetails_(facilityId, items);
      this.removeOptionWithDifferentDays();
    },

    /**
     * ストアにディテールを保存する
     */
    setDetails_(facilityId: string, items: CartDetail[]) {
      const details = structuredClone(this.details);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [facilityId]: _, ...rest } = details;
      const result = {
        ...rest,
        ...(items.length > 0 ? { [facilityId]: [...items] } : {}),
      };

      this.setDetails(result);
    },

    /**
     * 選択済のオプションを取得する
     */
    getOptionDetailDict(
      facilityId: string,
      date: string,
    ): Record<string, CartDetail> {
      const result =
        this.options[facilityId]?.[date]?.reduce(
          (acc, crr) => ({
            ...acc,
            [crr.unitId]: crr,
          }),
          {},
        ) || {};

      return result;
    },

    /**
     * オプション編集ダイアログを開く時の初期値を生成する
     */
    generateOptionDialogItem(facilityId: string, date: string): any {
      const exists = this.getOptionDetailDict(facilityId, date);
      const result = chain(this.units)
        .filter((u) => u.facilityId === facilityId)
        .filter((u) => u.isOption)
        .groupBy('group')
        .mapValues((units) => {
          const value_ = chain(units)
            .map((unit) => ({
              ...unit.fields?.reduce((acc, crr) => {
                return {
                  ...acc,
                  [crr.property]: '',
                };
              }, {}),
              ...(exists[unit.id!] ? { ...exists[unit.id!] } : {}),
              startDate: date,
              unitId: unit.id!,
            }))
            .value();
          return value_;
        })
        .value();

      return result;
    },

    /**
     * オプション編集ダイアログを開く
     */
    openOptionDetail(facilityId: string, date: string) {
      this.editedFacilityId = facilityId;
      const item = this.generateOptionDialogItem(facilityId, date);
      this.$set(this, 'optionDialog', {
        show: true,
        item: {
          date,
          text: this.$dateFns.fnsFormat(date, '', 'M月d日'),
          value: item,
        },
      });
    },

    /**
     * オプションの編集内容を保存する
     */
    saveOption(date: string, items: CartDetail[]) {
      const facilityId = this.editedFacilityId;
      this.setOptions_(facilityId, date, items);
      this.optionDialog.show = false;
    },

    /**
     * オプションを削除する
     */
    removeOption(facilityId: string, date: string) {
      if (!confirm('オプションを削除します。よろしいですか？')) {
        return;
      }
      this.setOptions_(facilityId, date);
    },

    /**
     * ストアにオプションを保存する
     */
    setOptions_(facilityId: string, date: string, items: CartDetail[] = []) {
      const options = structuredClone(this.options);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [date]: _, ...rest } = options[facilityId] || {};
      const result = {
        ...options,
        [facilityId]: {
          ...rest,
          ...(items.length > 0 ? { [date]: [...items] } : {}),
        },
      };

      this.setOptions(result);
    },

    ...mapActions(['setDetails', 'setOptions']),
  },
});
</script>
