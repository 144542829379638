<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>施設情報</v-expansion-panel-header>
      <v-expansion-panel-content>
        <p class="readTxt long-text-area">
          {{ facility.overview }}
        </p>

        <ul class="facilityInfo">
          <li class="facilityInfo__item">
            <div class="ttl">住所</div>
            <div class="txt">{{ facility.address }}</div>
          </li>
          <li class="facilityInfo__item">
            <div class="ttl">TEL</div>
            <div class="txt">{{ facility.tel }}</div>
          </li>
          <li class="facilityInfo__item">
            <div class="ttl">営業時間</div>
            <div class="txt long-text-area">
              {{ facility.businessHours }}
            </div>
          </li>
          <li class="facilityInfo__item">
            <div class="ttl">定休日</div>
            <div class="txt long-text-area">
              {{ facility.regularHoliday }}
            </div>
          </li>
          <li class="facilityInfo__item">
            <div class="ttl">利用料金</div>
            <div class="txt long-text-area">
              {{ facility.usageFee }}
            </div>
          </li>
          <li class="facilityInfo__item">
            <div class="ttl">HP</div>
            <div class="txt url">
              <a :href="facility.url" target="_blank">
                {{ facility.url }}
              </a>
            </div>
          </li>
        </ul>

        <div class="map">
          <v-img :src="imageSrc" alt="周辺体験"></v-img>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import { Facility } from '@api/models';
import Vue, { PropType } from 'vue';

const images: Record<string, any> = {
  dummy: require('../../assets/img/search/dummy.jpg'),
  map_IA: require('../../assets/img/search/map_IA.jpg'),
  map_KC: require('../../assets/img/search/map_KC.jpg'),
  map_SH: require('../../assets/img/search/map_SH.jpg'),
  map_UR: require('../../assets/img/search/map_UR.jpg'),
  // ...
  map_UC: require('../../assets/img/search/map_UC.jpg'),
  map_TC: require('../../assets/img/search/map_TC.jpg'),
  map_IF: require('../../assets/img/search/map_IF.jpg'),
  map_MA: require('../../assets/img/search/map_MA.jpg'),
};

export default Vue.extend({
  name: 'SearchFacilityInfo',

  props: {
    facility: {
      type: Object as PropType<Facility>,
      required: true,
    },
  },

  data: () => ({
    panel: 0,
  }),

  computed: {
    imageSrc(): any | null {
      return (
        (this.facility.imageName && images[this.facility.imageName]) || null
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.long-text-area {
  white-space: pre-wrap;
}
.url {
  word-break: break-all;
}
</style>
