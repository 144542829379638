<template>
  <div class="mContainer -detailCont">
    <div class="inner">
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="facility in availability" :key="facility.id">
          {{ facility.name }}
        </v-tab>
      </v-tabs>
      <!-- 横スクロール可能なコンテンツがあるためタッチサポートを無効にする -->
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-for="facility in availability" :key="facility.id">
          <template v-if="!!facilityDict[facility.id]">
            <FacilityInfo :facility="facilityDict[facility.id]" />
            <News :facility="facilityDict[facility.id]" />
            <Free :item="facility" @load="$listeners['load']" @add="addCart" />
          </template>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div class="btnArea">
      <v-btn depressed to="/reserveCart" class="btnBasic"> カートを見る </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import FacilityInfo from './FacilityInfo.vue';
import Free from './Free.vue';
import News from './News.vue';
import { Facility } from '@api/models';
import { InferCreationAttributesDetail } from '@api/models/Detail';
import * as cartStore from '@web-e/store/cart';
import * as freeStore from '@web-e/store/free';

export default Vue.extend({
  name: 'SearchResult',

  components: {
    FacilityInfo,
    News,
    Free,
  },

  props: {
    facilities: {
      type: Array as PropType<Facility[]>,
      required: true,
    },
  },

  data: () => ({
    tab: null as null | number,
  }),

  computed: {
    facilityDict(): object {
      return this.facilities.reduce(
        (acc, crr) => ({
          ...acc,
          [crr.id!]: crr,
        }),
        {},
      );
    },

    ...freeStore.mapState(['availability']),
  },

  methods: {
    addCart(facilityId: string, item: InferCreationAttributesDetail) {
      this.addDetail({ facilityId, item });
    },

    ...cartStore.mapActions(['addDetail']),
  },
});
</script>
