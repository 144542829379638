<template>
  <section class="reserveDetail__item reservePrice">
    <h3 class="borderTtl">ご利用料金小計</h3>
    <div class="v-data-table">
      <div class="v-data-table__wrapper">
        <table>
          <tbody>
            <tr>
              <td>施設料金</td>
              <td class="text-right">
                {{ amounts[facilityId].det.toLocaleString() }}円
              </td>
            </tr>
            <tr>
              <td>オプション料金</td>
              <td class="text-right">
                {{ amounts[facilityId].opt.toLocaleString() }}円
              </td>
            </tr>
            <tr>
              <td>合計</td>
              <td class="text-right">
                {{ amounts[facilityId].sum.toLocaleString() }}円
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ApproxAmountFacilities } from '@web-t/books';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CartPrice',

  props: {
    facilityId: {
      type: String,
      required: true,
    },
    amounts: {
      type: Object as PropType<ApproxAmountFacilities>,
      required: true,
    },
  },
});
</script>
