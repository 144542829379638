<template>
  <v-select
    v-if="field.type === inputType.enumSingle.value"
    :value="value"
    :prepend-inner-icon="field.icon"
    :items="field.items_"
    :hint="field.hint"
    :persistent-hint="true"
    outlined
    dense
    hide-details="auto"
    clearable
    @input="input"
  ></v-select>
  <v-select
    v-else-if="field.type === inputType.enumMultiple.value"
    :value="parsedValue"
    :prepend-inner-icon="field.icon"
    :items="field.items_"
    :hint="field.hint"
    :persistent-hint="true"
    outlined
    dense
    hide-details="auto"
    clearable
    multiple
    @input="input"
  ></v-select>
  <DatePicker
    v-else-if="field.type === inputType.date.value"
    :value="value"
    clearable
    hide-details="auto"
    :text-field-props="{
      prependInnerIcon: field.icon,
      hint: field.hint,
      persistentHint: true,
    }"
    @input="input"
  />
  <TimePicker
    v-else-if="field.type === inputType.time.value"
    :value="value"
    clearable
    :text-field-props="{
      prependInnerIcon: field.icon,
      hint: field.hint,
      persistentHint: true,
    }"
    :hour-min="hourMin"
    :hour-max="hourMax"
    :minute-step="unit?.step"
    @input="input"
  />
  <v-select
    v-else-if="field.type === inputType.unitItems.value"
    :value="parsedValue"
    :prepend-inner-icon="field.icon"
    :items="unit.items_"
    :hint="field.hint"
    :persistent-hint="true"
    outlined
    dense
    hide-details="auto"
    clearable
    multiple
    @input="input"
  ></v-select>
  <v-select
    v-else-if="field.type === inputType.number.value"
    :value="value"
    :prepend-inner-icon="field.icon"
    :items="numberItems"
    :hint="field.hint"
    :persistent-hint="true"
    outlined
    dense
    hide-details="auto"
    clearable
    @input="input"
  ></v-select>
  <v-checkbox
    v-else-if="field.type === inputType.checkbox.value"
    :input-value="parseBoolean(value)"
    :prepend-inner-icon="field.icon"
    :label="field.label"
    :hint="field.hint"
    :persistent-hint="true"
    hide-details="auto"
    @change="(v) => input(!!v)"
  ></v-checkbox>
  <v-text-field
    v-else
    :type="field.type"
    :value="value"
    :prepend-inner-icon="field.icon"
    :hint="field.hint"
    :persistent-hint="true"
    outlined
    dense
    hide-details="auto"
    clearable
    @input="input"
  ></v-text-field>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { isArray, isBoolean, range } from 'lodash';
import DatePicker from '@web/components/inputs/date-picker.vue';
import TimePicker from '@web/components/inputs/time-picker.vue';
import { inputType } from '@api/constants';
import { Field, Unit, UsageTime } from '@api/models';
import { ConstantInputType } from '@api-t/constants/inputType';

export default Vue.extend({
  name: 'VariableInputField',

  components: {
    DatePicker,
    TimePicker,
  },

  props: {
    value: {
      type: String,
      default: () => '',
    },

    field: {
      type: Object as PropType<Field>,
      default: undefined,
    },

    unit: {
      type: Object as PropType<Unit>,
      default: undefined,
    },

    usageTime: {
      type: Object as PropType<UsageTime>,
      default: undefined,
    },
  },

  computed: {
    inputType(): ConstantInputType {
      return inputType;
    },

    parsedValue(): any {
      return JSON.parse(this.value || '[]');
    },

    hourMin(): number | undefined {
      if (!this.usageTime) {
        return undefined;
      }
      const result = Number(this.usageTime.startTime.slice(0, 2));

      return result;
    },

    hourMax(): number | undefined {
      if (!this.usageTime) {
        return undefined;
      }
      const result = Number(this.usageTime.endTime.slice(0, 2));

      return result;
    },

    numberItems(): string[] {
      const start = this.field.max || 10;

      return range(start + 1).map((v) => String(v));
    },
  },

  methods: {
    input(value: string): void {
      this.$emit(
        'input',
        isArray(value) || isBoolean(value) ? JSON.stringify(value) : value,
      );
    },

    parseBoolean(value: string): boolean {
      try {
        return JSON.parse(value);
      } catch (e) {
        return false;
      }
    },
  },
});
</script>
