import {
  ConstantRentalType,
  ConstantRentalTypeDict,
} from '@api-t/constants/rentalType';

const rentalTypeDict: ConstantRentalTypeDict = {
  frame: {
    text: '枠貸',
    value: 'frame',
  },
  time: {
    text: '時間貸',
    value: 'time',
  },
};

export const rentalType: ConstantRentalType = {
  ...rentalTypeDict,

  items: [rentalTypeDict.frame, rentalTypeDict.time],

  get(value: string): string {
    switch (value) {
      case rentalTypeDict.frame.value:
        return rentalTypeDict.frame.text;
      case rentalTypeDict.time.value:
        return rentalTypeDict.time.text;
      default:
        return '';
    }
  },
};
