<template>
  <v-dialog v-model="show" scrollable max-width="700px">
    <v-card>
      <v-card-title class="pa-5">
        <h3>オプションの詳細</h3>
      </v-card-title>
      <v-card-text>
        <div v-for="(details, group) in computedValue.value" :key="group">
          <div class="text-h5 pa-1">
            {{ group || 'その他' }}
          </div>
          <v-simple-table class="optionTable">
            <tbody>
              <tr v-for="({ detail, amount }, i) in details" :key="i">
                <td class="itemName">{{ unitDict[detail.unitId].name }}</td>
                <td class="itemPrice d-flex flex-row-reverse align-center">
                  {{ amount.toLocaleString() }}円
                </td>
                <CartOptionDetailCell
                  v-model="details[i].detail"
                  :unit="unitDict[detail.unitId]"
                  :holidays="holidays"
                />
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>

      <div class="actionCont">
        <v-card-actions class="btnArea -list mt-0">
          <v-btn
            depressed
            small
            outlined
            class="btnBasic -back"
            @click="show = false"
            >キャンセル</v-btn
          >
          <v-btn
            depressed
            small
            class="btnBasic"
            :disabled="inputedValue.length === 0"
            @click="save"
          >
            保存
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { InsertDetailRequest } from '@api-i/routes/detail/detail';
import { feeCalculation } from '@api/constants/feeCalculation';
import { Unit } from '@api/models';
import { chain, mapValues } from 'lodash';
import Vue, { PropType } from 'vue';
import CartOptionDetailCell from './CartOptionDetailCell.vue';

export interface OptionAddItem {
  date: string;
  text: string;
  value: {
    [key: string]: InsertDetailRequest[];
  };
}

export type OptionAddDataType = {
  show: boolean;
  item: OptionAddItem;
};

export default Vue.extend({
  name: 'CartOptionDetail',

  components: {
    CartOptionDetailCell,
  },

  props: {
    value: {
      type: Object as PropType<OptionAddDataType>,
      default: undefined,
    },

    units: {
      type: Array as PropType<Unit[]>,
      default: () => [],
    },

    holidays: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): OptionAddItem {
        return this.value.item;
      },
      set(item: OptionAddItem): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    unitDict(): Record<string, Unit> {
      return chain(this.units).keyBy('id').value();
    },

    /**
     * 入力内容から料金を計算する
     */
    computedValue(): {
      value: {
        [x: string]: {
          detail: InsertDetailRequest;
          amount: number;
        }[];
      };
      date: string;
      text: string;
    } {
      const result = {
        ...this.item,
        value: mapValues(this.item.value, (details) => {
          return details.map((detail) => {
            const amount = feeCalculation.calcAmount(
              detail,
              this.unitDict,
              this.holidays,
            );
            return { detail, amount };
          });
        }),
      };

      return result;
    },

    /**
     * 入力値
     */
    inputedValue(): InsertDetailRequest[] {
      const result = chain(this.item.value)
        .values()
        .flatten()
        .filter((detail) => {
          const fields = this.unitDict[detail.unitId].fields;
          const result_ = chain(fields)
            .some((field) => {
              return !!(detail as Record<string, any>)[field.property];
            })
            .value();

          return result_;
        })
        .value();

      return result;
    },
  },

  methods: {
    /**
     * 保存
     */
    save() {
      const date = this.item.date;
      const options = this.inputedValue;
      this.$emit('save', date, options);
    },
  },
});
</script>
