import { Book, Unit } from '@api/models';
import { chain } from 'lodash';
import Vue from 'vue';

/**
 * 予約をカートで扱うデータの形に変える
 */
export function cartFormat(book: Book, unitDict: Record<string, Unit>) {
  const data = chain(book).omit('details').value();

  const details = chain([book])
    .keyBy('facilityId')
    .mapValues((book) => {
      const result_ = chain(book.details)
        .filter((detail) => !unitDict[detail.unitId]?.isOption)
        .value();

      return result_;
    })
    .value();

  const options = chain([book])
    .keyBy('facilityId')
    .mapValues((book) => {
      const result_ = chain(book.details)
        .filter((detail) => !!unitDict[detail.unitId]?.isOption)
        .groupBy('startDate')
        .value();

      return result_;
    })
    .value();

  return { book: data, details, options };
}

/**
 * 現在の予約確認ページに遷移する
 */
export function toConfirmCurrentReserve(instance: Vue) {
  instance.$router.push({
    path: '/reserveConfirm',
    query: { afterLogin: String(true) },
  });
}
