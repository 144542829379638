import { ConstantDateUtility } from '@c-t/util/dateUtility';

export const dateUtility: ConstantDateUtility = {
  getDatesInRange(start, end) {
    const result = [];

    const start_ = new Date(start);
    const end_ = new Date(end);
    while (start_ < end_) {
      const date = new Date(start_.getTime());
      result.push(date);
      start_.setDate(start_.getDate() + 1);
    }
    result.push(end_);

    return result;
  },

  getISODatesFromObj(object) {
    const { startDate, endDate, stayDays } = object;
    if (!startDate) {
      return [];
    }
    const endDate_ = dateUtility.calcEndDate(startDate, endDate, stayDays);
    const result = dateUtility
      .getDatesInRange(startDate, endDate_)
      .map((date) => date.toISOString().slice(0, 10));

    return result;
  },

  /**
   * 日付のフォーマットを判定する
   * @param str 判定する時刻
   * @returns 時刻のフォーマット(HH:mm)であるか
   */
  isDateFormat(str) {
    return (
      str.match(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) !== null
    );
  },

  /**
   * 時刻のフォーマットを判定する
   * @param str 判定する時刻
   * @returns 時刻のフォーマット(HH:mm)であるか
   */
  isTimeFormat(str) {
    return str.match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/) !== null;
  },

  timeString2Minutes(str) {
    const [h, m] = str.split(':');
    const hour = Number(h);
    const minute = Number(m);
    return hour * 60 + minute;
  },

  isTimeFitStep(str, step) {
    const minutes = dateUtility.timeString2Minutes(str);
    return minutes % step === 0;
  },

  calcEndDate(startDate, endDate, stayDays) {
    const stayDays_ = Number(stayDays);
    let result = startDate;
    if (!!endDate && dateUtility.isDateFormat(endDate)) {
      // 最終日を含む
      result = endDate;
    }
    if (!!stayDays_) {
      // 最終日を含まない
      const endDate_ = new Date(startDate);
      endDate_.setDate(endDate_.getDate() + stayDays_ - 1);
      result = endDate_.toISOString().slice(0, 10);
    }

    return result;
  },
};
