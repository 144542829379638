import {
  StoreLoginStatusActions,
  StoreLoginStatusGetters,
  StoreLoginStatusMutations,
  StoreLoginStatusState,
} from '@web-t/store/loginStatus';
import { StoreRootExternal } from '@web-t/store/root';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';

const state: StoreLoginStatusState = {
  isLoggedIn: false,
};

const getters: DefineGetters<StoreLoginStatusGetters, StoreLoginStatusState> =
  {};

const mutations: DefineMutations<
  StoreLoginStatusMutations,
  StoreLoginStatusState
> = {
  setIsLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
};

const actions: DefineActions<
  StoreLoginStatusActions,
  StoreLoginStatusState,
  StoreLoginStatusMutations,
  StoreLoginStatusGetters
> = {
  setIsLoggedIn({ commit }, payload) {
    commit('setIsLoggedIn', payload);
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreLoginStatusState,
    StoreLoginStatusGetters,
    StoreLoginStatusMutations,
    StoreLoginStatusActions
  >('loginStatus');

const storeModule: Module<StoreLoginStatusState, StoreRootExternal> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<
    StoreLoginStatusState,
    StoreRootExternal
  >,
};

export default storeModule;
