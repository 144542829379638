export default class AddressSearcher {
  static async fetchAPI(zipCode: string) {
    const result = await (
      await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}`)
    ).json();
    if (!result || result.status !== 200 || !result.results) {
      return;
    }
    return result.results[0];
  }

  static isZipCodeFormat(value: string) {
    if (!value) {
      return false;
    }
    const zipCode = value.match(/\d+/g)?.join('');
    if (!zipCode || zipCode.length !== 7) {
      return false;
    }
    return true;
  }
}
