import {
  ConstantPeriodOperation,
  ConstantPeriodOperationDict,
  ConstantPeriodOperationKey,
} from '@api-t/constants/periodOperation';
import { Schema } from '@api-t/utils';
import { ReceiptPeriod } from '@api/models';
import { dateUtility, stringUtility } from '@c/util';

const periodOperationDict: ConstantPeriodOperationDict = {
  any: {
    text: '{$1}',
    value: 'any',
    numOfArgs: 1,
  },
  day: {
    text: '{$1}日前',
    value: 'day',
    numOfArgs: 1,
  },
  week: {
    text: '{$1}週間前',
    value: 'week',
    numOfArgs: 1,
  },
  month: {
    text: '{$1}ヶ月前',
    value: 'month',
    numOfArgs: 1,
  },
  startOfMonth: {
    text: '{$1}ヶ月前の月初',
    value: 'startOfMonth',
    numOfArgs: 1,
  },
  anyOfMonth: {
    text: '{$1}ヶ月前の{$2}日',
    value: 'anyOfMonth',
    numOfArgs: 2,
  },
  endOfMonth: {
    text: '{$1}ヶ月前の月末',
    value: 'endOfMonth',
    numOfArgs: 1,
  },
  monthOfPrevFY: {
    text: '前年度の{$1}月',
    value: 'monthOfPrevFY',
    numOfArgs: 1,
  },
};

export const periodOperation: ConstantPeriodOperation = {
  ...periodOperationDict,
  items: [
    periodOperationDict.any,
    periodOperationDict.day,
    periodOperationDict.week,
    periodOperationDict.month,
    periodOperationDict.startOfMonth,
    periodOperationDict.anyOfMonth,
    periodOperationDict.endOfMonth,
    periodOperationDict.monthOfPrevFY,
  ],

  eval(date, periods) {
    const result = periods.some((period) => {
      const result_ = eval_(date, period);

      return result_;
    });

    return result;
  },

  valid(schema) {
    if (!schema.operation) {
      return false;
    }
    if (schema.operation === periodOperationDict.any.value) {
      return dateUtility.isDateFormat(schema.value_1);
    }
    if (schema.operation === periodOperationDict.anyOfMonth.value) {
      return !!Number(schema.value_1) && !!Number(schema.value_2);
    }
    return !!Number(schema.value_1);
  },

  get(schema) {
    return stringUtility.formatString(
      periodOperationDict[schema.operation].text,
      [schema.value_1, schema.value_2],
    );
  },
};

const eval_ = (date: string, period: ReceiptPeriod): boolean => {
  const { from_, to_ } = period;
  const start = calcDate(date, from_);
  const end = calcDate(date, to_);
  const today = new Date(
    Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000,
  );

  if (!start) {
    return false;
  }
  start.setHours(0, 0, 0);

  if (!end) {
    return start <= today;
  }
  end.setHours(23, 59, 59);

  return start <= today && today <= end;
};

const calcDate = (
  date: string,
  schema: Schema<ConstantPeriodOperationKey>,
): Date | null => {
  const date_ = new Date(date);
  const { operation, value_1, value_2 } = schema;
  switch (operation) {
    case 'any': {
      return new Date(value_1);
    }
    case 'day': {
      date_.setDate(date_.getDate() - Number(value_1));
      return date_;
    }
    case 'week': {
      date_.setDate(date_.getDate() - 7 * Number(value_1));
      return date_;
    }
    case 'month': {
      date_.setMonth(date_.getMonth() - Number(value_1));
      return date_;
    }
    case 'startOfMonth': {
      date_.setDate(1);
      date_.setMonth(date_.getMonth() - Number(value_1));
      return date_;
    }
    case 'anyOfMonth': {
      date_.setDate(1);
      date_.setMonth(date_.getMonth() - Number(value_1));
      date_.setDate(Number(value_2));
      return date_;
    }
    case 'endOfMonth': {
      date_.setDate(1);
      date_.setMonth(date_.getMonth() - Number(value_1) + 1);
      date_.setDate(0);
      return date_;
    }
    case 'monthOfPrevFY': {
      const year = date_.getFullYear();
      const month = date_.getMonth() + 1;
      const month_ = Number(value_1);
      const year_ = year - Number(month < 4) + Number(month_ < 4);
      date_.setDate(1);
      date_.setMonth(month_ - 1);
      date_.setFullYear(year_ - 1);
      return date_;
    }
    default:
      return null;
  }
};
