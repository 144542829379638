import Vue from 'vue';
import App from './App.vue';

// 先に読み込むやつ
import vuetify from '@web/plugins/vuetify';
import '@web/plugins/config';
import '@web/plugins/constants';
import '@web/components/global/index';

// 次に読み込むやつ
import router from './router';
import store from './store';
import '@web/plugins/api';

// いつ読み込んでもいいやつ
import '@web/plugins/datefns';
import '@web/plugins/mathjs';
import '@web/plugins/mavon-editor';
import '@web/plugins/cache';
import '@web/assets/styles/global.scss';
import './assets/styles/style.scss';

Vue.config.productionTip = false;

async function run() {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
}

run();
