import { StoreRootExternal } from '@web-t/store/root';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import loading from '@web/store/loading';
import snackbar from '@web/store/snackbar';
import cart from './cart';
import free from './free';
import search from './search';
import loginStatus from './loginStatus';

Vue.use(Vuex);

const root: StoreOptions<StoreRootExternal> = {
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  modules: {
    loading,
    snackbar,
    cart,
    free,
    search,
    loginStatus,
  },
};

export default new Vuex.Store<StoreRootExternal>(root);
