import { ConstantSample } from '@api-t/constants/sample';

export const sample: ConstantSample = {
  values: [
    {
      name: 'this is name',
      label: 'this is value',
    },
  ],
};
