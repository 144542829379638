import { ConstantScopeDistribution } from '@api-t/constants/scopeDistribution';
import { dateUtility } from '@c/util';
import { isArray } from 'lodash';

const { isDateFormat, isTimeFormat, timeString2Minutes } = dateUtility;

export const scopeDistribution: ConstantScopeDistribution = {
  exec(data) {
    const { section, startDate, endDate, startTime, endTime } = data;
    const scope = {
      ...data,
      system: {
        sectionLength: 0,
        elapsedDays: 0,
        elapsedHours: 0,
        elapsedMinutes: 0,
      },
    };
    try {
      const section_ = JSON.parse(section || '[]');
      if (isArray(section_)) {
        scope.system.sectionLength = section_.length;
      }
    } catch (ignored) {}
    if (
      !!startDate &&
      isDateFormat(startDate) &&
      !!endDate &&
      isDateFormat(endDate)
    ) {
      const numerator =
        new Date(endDate).getTime() - new Date(startDate).getTime();
      const denominator = 24 * 60 * 60 * 1000;
      const quotient = Math.floor(numerator / denominator);
      scope.system.elapsedDays = quotient;
    }
    if (
      !!startTime &&
      isTimeFormat(startTime) &&
      !!endTime &&
      isTimeFormat(endTime)
    ) {
      const startMinutes = timeString2Minutes(startTime);
      const endMinutes = timeString2Minutes(endTime);
      const minutes = endMinutes - startMinutes;
      const hours = Math.floor(minutes / 60);
      scope.system.elapsedHours = hours;
      scope.system.elapsedMinutes = minutes;
    }

    return scope;
  },
};
