<template>
  <div class="calendarCont">
    <v-row class="pb-0">
      <v-col cols="12">
        <div class="calendarBox">
          <div class="calendar">
            <!-- 前へ -->
            <v-tooltip bottom>
              <template #activator="{ on }">
                <button :aria-label="prevLabel" v-on="on" @click="prev">
                  <v-icon>mdi-chevron-left</v-icon>
                </button>
              </template>
              <span>{{ prevLabel }}</span>
            </v-tooltip>
            <!-- 日次の日付 -->
            <div v-if="mode === 0">
              {{ dateLabel }}
            </div>
            <!-- 週次の日付 -->
            <div v-else-if="mode === 1">
              {{ `${startLabel} - ${endLabel}` }}
            </div>
            <!-- 月次の日付 -->
            <div v-else-if="mode === 2">
              {{ monthLabel }}
            </div>
            <!-- 次へ -->
            <v-tooltip bottom>
              <template #activator="{ on }">
                <button :aria-label="nextLabel" v-on="on" @click="next">
                  <v-icon>mdi-chevron-right</v-icon>
                </button>
              </template>
              <span>{{ nextLabel }}</span>
            </v-tooltip>
          </div>
          <form>
            <RadioGroup
              v-model="mode"
              :items="[
                { text: '日', value: 0 },
                { text: '週', value: 1 },
                { text: '月', value: 2 },
              ]"
              class="cal-switcher__switch"
            />
          </form>
        </div>
      </v-col>
      <v-col cols="12">
        <!-- 日次 -->
        <DailyAvailabilityTable
          v-if="mode === 0"
          :dates="datesOfMonth[weekIdx]"
          :disp-idx="dateIdx"
          :item="weekItem"
          @click:cell="handleClickDailyCell"
        />
        <!-- 週次 -->
        <WeeklyAvailabilityTable
          v-else-if="mode === 1"
          :dates="datesOfMonth[weekIdx]"
          :item="weekItem"
          @click:cell="handleClickWeeklyCell"
        />
        <!-- 月次 -->
        <MonthlyAvailabilityTable
          v-else-if="mode === 2"
          :dates="datesOfMonth"
          :item="item"
          @click:cell="handleClickMonthlyCell"
        />
        <!-- 凡例 -->
        <IconList />
      </v-col>
    </v-row>
    <!-- 予約カートに追加する用 -->
    <CartDetailDialog
      v-model="dialog"
      :units="units"
      :holidays="holidays"
      @add="addCart"
    />
  </div>
</template>

<script lang="ts">
import { WeeklyAvailability } from '@api-i/routes/availability/availability';
import { Unit } from '@api/models';
import { dateUtility } from '@c/util';
import DailyAvailabilityTable, {
  ClickDailyCellEventData,
} from '@web/components/availability/DailyAvailabilityTable.vue';
import IconList from '@web/components/availability/IconList.vue';
import MonthlyAvailabilityTable, {
  ClickMonthlyCellEventData,
} from '@web/components/availability/MonthlyAvailabilityTable.vue';
import WeeklyAvailabilityTable, {
  ClickWeeklyCellEventData,
} from '@web/components/availability/WeeklyAvailabilityTable.vue';
import RadioGroup from '@web/components/inputs/radio-group.vue';
import { loadCacheUnits } from '@web/modules/master-loader';
import CartDetailDialog, {
  CartDetailDialogItem,
} from '../../components/cart/detail/CartDetailDialog.vue';
import { chunk, flatten } from 'lodash';
import Vue, { PropType } from 'vue';
import * as cartStore from '@web-e/store/cart';
import * as freeStore from '@web-e/store/free';
import * as loginStatus from '@web-e/store/loginStatus';
import { mapActions } from '@web/store/snackbar';
import HolidaySearcher from '@web/modules/holiday-searcher';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';

export default Vue.extend({
  name: 'SearchFree',

  components: {
    MonthlyAvailabilityTable,
    IconList,
    DailyAvailabilityTable,
    WeeklyAvailabilityTable,
    RadioGroup,
    CartDetailDialog,
  },

  props: {
    item: {
      type: Object as PropType<WeeklyAvailability>,
      required: true,
    },
  },

  data: () => ({
    units: [] as Unit[],

    holidays: [] as string[],

    mode: 2,

    dialog: {
      show: false,
      item: {} as CartDetailDialogItem,
    },
  }),

  computed: {
    currentMonth(): Date {
      const found = flatten(this.datesOfMonth).find(
        (date) => (date as Date).getDate() === 1,
      );
      return found as Date;
    },

    prevLabel(): string {
      return this.mode === 0 ? '前日' : this.mode === 1 ? '前週' : '前月';
    },

    nextLabel(): string {
      return this.mode === 0 ? '翌日' : this.mode === 1 ? '翌週' : '翌月';
    },

    monthLabel(): string {
      return this.$dateFns.fnsFormat(
        this.currentMonth as Date,
        '',
        'yyyy年M月',
      );
    },

    startLabel(): string {
      return this.$dateFns.fnsFormat(
        this.datesOfMonth[this.weekIdx][0],
        '',
        'yyyy年M月d日',
      );
    },

    endLabel(): string {
      return this.$dateFns.fnsFormat(
        this.datesOfMonth[this.weekIdx][6],
        '',
        'yyyy年M月d日',
      );
    },

    dateLabel(): string {
      return this.$dateFns.fnsFormat(
        this.datesOfMonth[this.weekIdx][this.dateIdx],
        '',
        'yyyy年M月d日(E)',
      );
    },

    weekItem(): any {
      return {
        ...this.item,
        units: this.item.units.map((unit) => ({
          ...unit,
          days: chunk(unit.days, 7)[this.weekIdx],
        })),
      };
    },

    ...cartStore.mapState(['book']),
    ...freeStore.mapState(['datesOfMonth', 'weekIdx', 'dateIdx']),
    ...loginStatus.mapState(['isLoggedIn']),
  },

  async mounted() {
    const year = new Date().getFullYear();
    await Promise.all([
      this.loadUnits(),
      this.loadHolidays(year),
      this.loadHolidays(year + 1),
    ]);
  },

  methods: {
    /**
     * 区画種類マスタを読み込む
     */
    async loadUnits() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },

    /**
     * 祝日をAPIから取得する
     */
    async loadHolidays(year: number) {
      const holidays = await HolidaySearcher.fetchCacheAPI(year, this);
      this.$set(this, 'holidays', [...this.holidays, ...holidays]);
    },

    /**
     * 前月または前週または前日へ
     */
    async prev() {
      if (this.mode === 2) {
        // 前月
        const lastMonth = structuredClone(this.currentMonth) as Date;
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        this.$emit('load', lastMonth);
      } else if (this.mode === 1) {
        // 前週
        if (0 < this.weekIdx) {
          await this.setWeekIdx(this.weekIdx - 1);
        } else {
          const lastWeek = structuredClone(
            this.datesOfMonth[this.weekIdx][this.dateIdx],
          );
          lastWeek.setDate(lastWeek.getDate() - 7);
          this.$emit('load', lastWeek);
        }
      } else if (this.mode === 0) {
        // 前日
        if (0 < this.dateIdx) {
          await this.setDateIdx(this.dateIdx - 1);
        } else if (0 < this.weekIdx) {
          await this.setDateIdx(6);
          await this.setWeekIdx(this.weekIdx - 1);
        } else {
          const lastDay = structuredClone(this.datesOfMonth[0][0]);
          lastDay.setDate(lastDay.getDate() - 1);
          this.$emit('load', lastDay);
        }
      }
    },

    /**
     * 翌月または翌週または翌日へ
     */
    async next() {
      if (this.mode === 2) {
        // 翌月
        const nextMonth = structuredClone(this.currentMonth) as Date;
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        this.$emit('load', nextMonth);
      } else if (this.mode === 1) {
        // 翌週
        if (this.weekIdx < this.datesOfMonth.length - 1) {
          await this.setWeekIdx(this.weekIdx + 1);
        } else {
          const nextWeek = structuredClone(
            this.datesOfMonth[this.weekIdx][this.dateIdx],
          );
          nextWeek.setDate(nextWeek.getDate() + 7);
          this.$emit('load', nextWeek);
        }
      } else if (this.mode === 0) {
        // 翌日
        if (this.dateIdx < 6) {
          await this.setDateIdx(this.dateIdx + 1);
        } else if (this.weekIdx < this.datesOfMonth.length - 1) {
          await this.setDateIdx(0);
          await this.setWeekIdx(this.weekIdx + 1);
        } else {
          const nextDay = structuredClone(
            this.datesOfMonth[this.weekIdx][this.dateIdx],
          );
          nextDay.setDate(nextDay.getDate() + 1);
          this.$emit('load', nextDay);
        }
      }
    },

    /**
     * 月次テーブルのセルをクリックした時
     */
    async handleClickMonthlyCell({ row, col }: ClickMonthlyCellEventData) {
      await this.setDateIdx(col);
      await this.setWeekIdx(row);
      this.mode = 0;
    },

    /**
     * 週次テーブルのセルをクリックした時
     */
    async handleClickWeeklyCell({ col }: ClickWeeklyCellEventData) {
      await this.setDateIdx(col);
      this.mode = 0;
    },

    /**
     * 日次テーブルのセルをクリックした時
     */
    handleClickDailyCell({ row, detail }: ClickDailyCellEventData) {
      if (this.isLoggedIn && this.book?.facilityId !== this.item.id) {
        this.snackbarRegister({
          type: 'error',
          message: '変更中の予約と異なる施設の予約はできません。',
        });
        return;
      }
      if (detail.ratio >= 100) return;
      if (row.type) return;
      const unit = this.units.find((u) => u.id === row.id);
      if (!unit) return;
      const item = this.createItem(unit, detail.label);
      this.$set(this.dialog, 'show', true);
      this.$set(this.dialog, 'item', item);
    },

    /**
     * カートに追加するデータの初期値を生成
     */
    createItem(unit: Unit, label?: string): CartDetailDialogItem {
      let result = this.createBaseItem(unit);

      if (label) {
        // 時間貸の場合は選択した列が時間となっている
        if (dateUtility.isTimeFormat(label)) {
          result = { ...result, startTime: label };
        } else {
          result = { ...result, section: JSON.stringify([label]) };
        }
      }

      // 利用日（開始）
      const startDate = this.$dateFns.fnsFormat(
        this.datesOfMonth[this.weekIdx][this.dateIdx],
        '',
        'yyyy-MM-dd',
      );
      result = { ...result, startDate };

      // 利用日（終了）
      if (unit.fields?.find((field) => field.property === 'endDate')) {
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1);
        const endDate_ = this.$dateFns.fnsFormat(endDate, '', 'yyyy-MM-dd');
        result = { ...result, endDate: endDate_ };
      }

      return result;
    },

    createBaseItem(unit: Unit): CartDetailDialogItem {
      let result = unit.fields?.reduce((acc, crr) => {
        return {
          ...acc,
          [crr.property]: '',
        };
      }, {}) as CartDetailDialogItem;

      result = { ...result, unitId: unit.id! };

      return result;
    },

    /**
     * 予約カートに追加ボタンを押した時
     */
    async addCart(item: Partial<CartDetailDialogItem>) {
      try {
        // 事前にバリデーション
        const result = await this.$api({
          path: '/detail/validate',
          method: 'post',
          params: {
            facilityId: this.item.id,
            detail: item,
          },
        });
        if (result) {
          this.$emit('add', this.item.id, item);
          this.dialog.show = false;
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['入力内容に不備があります。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
        }
      }
    },

    ...freeStore.mapActions(['setWeekIdx', 'setDateIdx']),
    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
