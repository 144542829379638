<template>
  <div class="lContainer -sub -reserve">
    <CartEditable>
      <template #top>
        <section class="inner">
          <h1 class="contTtl">ご予約内容</h1>
        </section>
      </template>

      <template #bottom>
        <section class="inner mt-15">
          <h1 class="contTtl">お客様情報</h1>
        </section>

        <section class="formCont -confirm inner">
          <!-- 本体 -->
          <v-form ref="form" v-model="valid" lazy-validation>
            <CartBookEdit />
          </v-form>
        </section>

        <section class="inner">
          <!-- 操作 -->
          <v-card-actions class="btnArea -list">
            <v-btn
              depressed
              small
              class="btnBasic -back -order2"
              @click="cancel"
            >
              変更をキャンセルする
            </v-btn>
            <v-btn depressed small class="btnBasic -order1" @click="confirm">
              変更内容の確認へ進む
            </v-btn>
          </v-card-actions>
        </section>
      </template>
    </CartEditable>
  </div>
</template>

<script lang="ts">
import { validationRule } from '@api/constants';
import CartBookEdit from '@web-e/components/cart/book/CartBookEdit.vue';
import CartEditable from '@web-e/components/cart/CartEditable.vue';
import {
  mapActions as mapActionsCart,
  mapState as mapStateCart,
} from '@web-e/store/cart';
import { mapState as mapStateLoginStatus } from '@web-e/store/loginStatus';
import { toConfirmCurrentReserve } from '@web/modules/cart-handler';
import { mapActions } from '@web/store/snackbar';
import { isEmpty } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'ReserveEdit',

  pageOptions: {
    routeConfig: {
      path: '/reserveEdit',
    },
    props: {
      title: '予約変更 / 取消',
    },
  },

  components: {
    CartEditable,
    CartBookEdit,
  },

  data: () => ({
    valid: false,
  }),

  computed: {
    /**
     * 予約カートが空か否か
     */
    cartIsEmpty(): boolean {
      return isEmpty(this.details);
    },

    ...mapStateCart(['book', 'details']),
    ...mapStateLoginStatus(['isLoggedIn']),
  },

  async mounted() {
    this.init();
  },

  methods: {
    init() {
      if (!this.isLoggedIn) {
        this.$router.push('/reserveLogin');
        return;
      }
    },

    /**
     * 入力内容をチェックして確認へ
     */
    confirm() {
      if (this.cartIsEmpty) {
        this.snackbarRegister({
          type: 'error',
          message: '予約する施設を少なくとも1つ選択して下さい。',
        });
        return;
      }
      const valid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (!valid) {
        const data = structuredClone(this.book);
        const messages = validationRule.eval(data);
        this.snackbarRegister({
          type: 'error',
          message: messages.join('\n'),
        });
        return;
      }
      this.$router.push('/reserveConfirm');
    },

    /**
     * キャンセルしたら現在の予約確認へ
     */
    cancel() {
      toConfirmCurrentReserve(this);
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
    ...mapActionsCart(['setBook', 'setDetails', 'setOptions']),
  },
});
</script>
