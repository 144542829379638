import { IConfig } from '@web-t/config';
import Vue, { PluginObject } from 'vue';

// encoded data
const configContext = window._appConfig;
// parsed data
const configData = JSON.parse(
  new TextDecoder().decode(
    Uint8Array.from(atob(configContext), (c) => c.charCodeAt(0)),
  ),
) as IConfig;

window.appConfig = configData;

const plugin: PluginObject<void> = {
  install(Vue) {
    Vue.prototype.$config = configData;
    Vue.$config = configData;
  },
};

Vue.use(plugin);
export default plugin;
