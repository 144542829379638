<template>
  <div class="lContainer -sub -reserve">
    <div class="mContainer -flowCont">
      <div class="inner">
        <ReserveFlow :value="1" />
      </div>
    </div>
    <div class="mContainer -reserveCont">
      <section class="inner">
        <!-- タイトル -->
        <h1 class="contTtl">お客様情報の入力</h1>
      </section>
      <section class="formCont inner">
        <!-- フォーム -->
        <v-form ref="form" v-model="valid" lazy-validation>
          <CartBookEdit />
        </v-form>
      </section>
      <section class="formCont checkboxCont inner">
        <!-- チェックボックス -->
        <div class="pa-5">
          <p class="text-center mb-0">
            <a target="_blank" href="/terms">利用規約</a>・<a
              target="_blank"
              href="/privacy"
              >各施設のプライバシーポリシー</a
            >への同意
          </p>
          <div class="d-flex align-center justify-center">
            <v-checkbox
              v-model="agree"
              label="同意する"
              hide-details="auto"
            ></v-checkbox>
          </div>
        </div>
      </section>
      <section class="inner">
        <!-- 操作 -->
        <v-card-actions class="btnArea -list mt-0">
          <v-btn
            depressed
            small
            outlined
            to="/reserveCart"
            class="btnBasic -back -order2"
          >
            予約内容を変更する
          </v-btn>
          <v-btn
            depressed
            small
            class="btnBasic -order1"
            :disabled="!agree"
            @click="confirm"
          >
            入力内容の確認
          </v-btn>
        </v-card-actions>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { validationRule } from '@api/constants';
import CartBookEdit from '@web-e/components/cart/book/CartBookEdit.vue';
import ReserveFlow from '@web-e/components/ReserveFlow.vue';
import * as cartStore from '@web-e/store/cart';
import { mapActions } from '@web/store/snackbar';
import Vue from 'vue';

export default Vue.extend({
  name: 'ReserveInput',

  components: {
    ReserveFlow,
    CartBookEdit,
  },

  pageOptions: {
    routeConfig: {
      path: '/reserveInput',
    },
    props: {
      title: 'お客様情報入力',
    },
  },

  data: () => ({
    agree: false,
    valid: false,
  }),

  computed: {
    ...cartStore.mapState(['book']),
  },

  mounted() {
    if (!this.book) {
      return;
    }
  },

  methods: {
    confirm() {
      const valid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (!valid) {
        const data = structuredClone(this.book);
        const messages = validationRule.eval(data);
        this.snackbarRegister({
          type: 'error',
          message: messages.join('\n'),
        });
        return;
      }
      this.$router.push('/reserveConfirm');
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
