<template>
  <div class="lContainer -sub -reserve">
    <div class="mContainer -reserveCont">
      <section class="CompleteCont inner">
        <h1>ご予約内容の<br class="spShow" />確認・変更・取消</h1>
        <p class="readTxt mb-7">
          受付番号およびメールアドレスを以下に入力の上、<br
            class="pcShow"
          />「予約内容詳細画面」へお進みください。
        </p>
      </section>
      <section class="formCont -edit inner">
        <v-form ref="loginForm" v-model="form.isValid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" class="mb-4 mb-sm-1">
                <v-row>
                  <v-col cols="12" md="4">受付番号</v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="form.value.receiptNo"
                      :rules="[form.rules.required]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="mb-4">
                <v-row>
                  <v-col cols="12" md="4">メールアドレス</v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="form.value.email"
                      :rules="[form.rules.required]"
                      required
                      outlined
                      dense
                      placeholder="example@mail.com"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </section>
      <section class="inner">
        <v-card-actions class="btnArea -list mt-0">
          <v-btn depressed class="btnBasic -order1" @click="startLogin">
            予約内容詳細画面
          </v-btn>
        </v-card-actions>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { LoginRequest, LoginResponse } from '@api-e/routes/login/login';
import { mapActions as mapActionsCart } from '@web-e/store/cart';
import { mapActions as mapActionsLoginState } from '@web-e/store/loginStatus';
import { mapActions as mapActionsLoading } from '@web/store/loading';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { toConfirmCurrentReserve } from '@web/modules/cart-handler';

export default Vue.extend({
  name: 'ReserveLogin',

  pageOptions: {
    routeConfig: {
      path: '/reserveLogin',
    },
    props: {
      title: '予約変更 / 取消',
    },
  },

  data: () => ({
    form: {
      isValid: false,
      value: {
        receiptNo: '',
        email: '',
      },
      rules: {
        required: (v: any) => !!v || '必須項目です',
      },
    },
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      // ログアウト
      try {
        await this.$api({
          path: '/login/logout',
          method: 'post',
          params: {},
        });
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['ログアウトに失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
        }
        throw error;
      }

      // storeも更新
      this.clearEdits();
      this.setIsLoggedIn(false);
    },

    /**
     * ログイン開始
     */
    async startLogin() {
      await this.loadingRegister(this.login());
    },

    /**
     * ログイン
     */
    async login() {
      try {
        const formInstance = this.$refs.loginForm as any;
        if (!formInstance.validate()) {
          return;
        }

        const { receiptNo, email } = this.form.value;
        if (!receiptNo) {
          throw new Error('受付番号は必須項目です。');
        }
        if (!email) {
          throw new Error('メールアドレスは必須項目です。');
        }

        // ログインの実施
        const result = await this.$api<LoginResponse, LoginRequest>({
          path: '/login',
          method: 'post',
          params: {
            receiptNo,
            email,
          },
        });
        if (!result) {
          throw new Error('ログインに失敗しました。');
        }

        // ログインに成功した場合
        // ログイン状況を更新
        await this.setIsLoggedIn(true);
        // 現在の予約確認ページに遷移
        toConfirmCurrentReserve(this);
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['ログインに失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
        }
      }
    },

    ...mapActionsCart(['clearEdits']),
    ...mapActionsLoginState(['setIsLoggedIn']),
    ...mapActionsLoading({ loadingRegister: 'register' }),
  },
});
</script>
