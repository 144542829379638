import Vue from 'vue';
import VueRouter from 'vue-router';
import { setupRouter } from './util';
import { routes } from './pages';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  async scrollBehavior(to, from, savedPosition) {
    // transitionのアニメーションの分だけ待つ
    await new Promise((resolve) => setTimeout(resolve, 400));
    // ブラウザの戻る進むの場合は保存されているものを使用する
    if (savedPosition) return savedPosition;
    // そうでなければ最上部までスクロールする
    return {
      x: 0,
      y: 0,
    };
  },
});

setupRouter({ router });

export default router;
