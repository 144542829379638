import { schemaOperation, scopeDistribution } from '@api/constants';
import { feeCalculation } from '@api/constants/feeCalculation';
import { Detail, Fee, InvoiceDetail, Unit } from '@api/models';
import { ApproxAmounts } from '@web-t/books';
import { CartDetailDict, CartOptionDict } from '@web-t/store/cart';
import { chain } from 'lodash';

/**
 * カート内にある全ての概算金額を計算する
 */
export function calcApproxAmounts(
  details: CartDetailDict,
  options: CartOptionDict,
  unitDict: Record<string, Unit>,
  holidays: string[],
): ApproxAmounts {
  const result = { all: 0, facilities: {} } as ApproxAmounts;
  const details__ = chain(details)
    .mapValues((details_, facilityId) => {
      result.facilities[facilityId] ??
        (result.facilities[facilityId] = { det: 0, opt: 0, sum: 0 });
      return chain(details_)
        .map((detail) => {
          const amount = feeCalculation.calcAmount(detail, unitDict, holidays);
          result.facilities[facilityId].det += amount;
          result.facilities[facilityId].sum += amount;
          result.all += amount;
          return amount;
        })
        .value();
    })
    .value();
  const options___ = chain(options)
    .mapValues((options_, facilityId) => {
      return chain(options_)
        .mapValues((options__) => {
          return chain(options__)
            .map((option) => {
              const amount = feeCalculation.calcAmount(
                option,
                unitDict,
                holidays,
              );
              result.facilities[facilityId].opt += amount;
              result.facilities[facilityId].sum += amount;
              result.all += amount;
              return amount;
            })
            .value();
        })
        .value();
    })
    .value();

  return {
    ...result,
    details: details__,
    options: options___,
  };
}

/**
 * ディテール単体の全ての料金を割り出す
 */
export function calcInvoiceDetails(
  instance: Vue,
  detail: Partial<Detail>,
  fees: Fee[],
  holidays: string[],
): Partial<InvoiceDetail>[] {
  const { startDate, endDate } = detail;

  if (!startDate) {
    return [];
  }

  let date = instance.$dateFns.fnsFormat(startDate, '', 'M月d日');
  if (!!endDate && startDate !== endDate) {
    date += `~${instance.$dateFns.fnsFormat(endDate!, '', 'M月d日')}`;
  }

  const result = fees.flatMap((fee) => {
    // 料金を計算する際はシステム専用のプロパティを注入する
    const scope = scopeDistribution.exec(detail);
    const { formula, schema_ } = fee;
    if (!schemaOperation.eval(schema_, scope, { holidays: holidays })) {
      return [];
    }
    const { name, unitPrice, numOfPieces, taxRate, taxType, roundingType } =
      fee;
    const amount = instance.$mathjs.evaluate(formula, scope);
    const amount_ = feeCalculation.calcTaxAmount({
      amount,
      taxRate,
      taxType,
      roundingType,
    });
    const numOfPieces_ = instance.$mathjs.evaluate(numOfPieces ?? '', scope);

    const result_ = {
      date,
      name,
      unitPrice: unitPrice ?? 0,
      numOfPieces: Number(numOfPieces_) || 0,
      amount: amount_,
      taxRate,
      taxType,
      roundingType,
    };

    return result_;
  });

  return result;
}
