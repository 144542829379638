<template>
  <div class="lContainer -sub -news">
    <h1 class="pageTtl text-center">お知らせ</h1>
    <div class="mContainer -newsCont">
      <div ref="news_inner" class="inner">
        <template v-if="displayItems.length > 0">
          <v-expansion-panels
            v-model="panels"
            accordion
            class="accordion-newsDetail"
          >
            <v-expansion-panel
              v-for="item in displayItems"
              :key="item.id"
              :ref="`notice-${item.id}`"
            >
              <v-expansion-panel-header>
                <div class="txt">
                  <time class="time">{{ item.from | date }}</time>
                  <v-chip
                    v-if="!!item.isEmergency"
                    color="red"
                    dark
                    class="mr-2"
                  >
                    重要
                  </v-chip>
                  <p class="ttl mb-0">{{ item.subject }}</p>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="newsDetail">
                  <mavon-editor
                    v-model="item.body"
                    language="ja"
                    default-open="preview"
                    :toolbars-flag="false"
                    :subfield="false"
                    class="markdown-editor"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-container>
            <v-row justify="center">
              <v-col cols="8">
                <v-container class="max-width">
                  <v-pagination
                    v-model="page"
                    class="my-4"
                    :length="pageLength"
                    @input="onPageChanged"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-else>
          <span>お知らせはありません。</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import chunk from 'lodash/chunk';
import {
  GetNoticeRequest,
  GetNoticeResponse,
} from '@api-e/routes/notice/notice';
import { NoticeForUser } from '@api/models';

export default Vue.extend({
  name: 'News',

  pageOptions: {
    routeConfig: {
      path: '/news',
    },
    props: {
      title: 'お知らせ',
    },
  },

  data: () => ({
    page: 1 as number,
    itemsPerPage: 10 as number,
    items: [] as NoticeForUser[],
    panels: null as null | number,
  }),

  computed: {
    // チャンク化したアイテム
    chunkedItems(): NoticeForUser[][] {
      return chunk(this.items, this.itemsPerPage);
    },
    // チャンクの長さ
    pageLength(): number {
      return this.chunkedItems.length;
    },
    // 表示するアイテム
    displayItems(): NoticeForUser[] {
      return this.chunkedItems[this.page - 1] || [];
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await Promise.all([this.loadNotices()]);
      await this.$nextTick();
      this.queryCheck();
    },

    async loadNotices() {
      const result = await this.$api<GetNoticeResponse, GetNoticeRequest>({
        path: '/notice',
        method: 'get',
        params: {},
      });

      this.page = 1;
      this.items = result.result;
    },

    // urlパラメータにnoticeのidがあった場合はそのページを開いてアコーディオンを開く
    queryCheck() {
      const { id } = this.$route.query;
      if (!id || typeof id !== 'string') return;
      // 対象のアイテム
      const targetItem = this.items.find((i) => i.id === id);
      if (!targetItem) return;
      // 対象のアイテムがあるチャンクを探す
      const chunk = this.chunkedItems;
      chunk.forEach((c, i) => {
        c.forEach((d, j) => {
          if (d.id === id) {
            this.$set(this, 'panels', j);
            this.$set(this, 'page', i + 1);
          }
        });
      });
      // 描画してからスクロールしたいので待つ
      setTimeout(() => {
        this.$vuetify.goTo((this.$refs[`notice-${id}`] as any)?.[0]);
      }, 500);
    },

    onPageChanged() {
      // 開いているものを閉じる
      this.panels = null;
      // topに移動
      this.$vuetify.goTo(this.$refs.news_inner as any);
    },
  },
});
</script>
