import { ApiResponse, ApiResponseContent } from '@api-t/api/response';
import { ApiError } from '@api-t/api/error';
import { ApiRequestProps } from '@web-t/api';
import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';
import { stringify } from 'qs';
import { getPageTabId } from '@web/modules/pageTabId';

interface FetchParams {
  url: string;
  options?: RequestInit;
}

export class WebApiError extends Error {
  public readonly detail: ApiError;
  constructor(e: ApiError) {
    super(e.message);
    this.detail = e;
  }
}

const plugin: PluginObject<void> = {
  install: function (Vue) {
    Vue.prototype.$api = this.api;
  },

  /**
   * APIリクエストを投げる
   * @param props
   * @returns
   */
  async api<T1 extends ApiResponseContent, T2 = unknown>(
    props: ApiRequestProps<T2>,
  ): Promise<T1> {
    // vueのインスタンスを取得(thisがそれになる)
    const instance = this as unknown as Vue;

    let result: T1 | WebApiError;

    try {
      const promise = callApi<T1, T2>(props);
      result = await (instance?.$store?.dispatch('loading/register', promise) ||
        promise);
    } catch (e) {
      // 予期しないエラー
      console.error('予期しないエラーが発生しました。', e);
      throw e;
    }

    // 予期したエラーの場合
    if (result instanceof WebApiError) {
      throw result;
    }
    return result;
  },
};

/**
 * apiを実際に呼び出す
 * @param props
 * @returns
 */
async function callApi<T1 extends ApiResponseContent, T2 = unknown>(
  props: ApiRequestProps<T2>,
): Promise<T1 | WebApiError> {
  const { url, options } = await generateFetchParams(props);

  const fetchRes = await fetch(url, options);
  // 成功の場合
  if (fetchRes.ok) {
    const result = (await fetchRes.json()) as ApiResponse & { content: T1 };
    return result.content;
  }
  // 失敗の場合
  else {
    const errorObj = (await fetchRes.json()) as ApiError;
    console.error(
      `API Error: ${fetchRes.status} ${fetchRes.statusText}`,
      errorObj,
    );
    return new WebApiError(errorObj);
  }
}

/**
 * fetchに使うurlとoptionを計算する
 * @param param0
 * @returns
 */
async function generateFetchParams({
  path,
  method = 'get',
  params = {},
}: ApiRequestProps): Promise<FetchParams> {
  const query = method === 'get' ? stringify(params) : undefined;
  const body = method !== 'get' && params ? JSON.stringify(params) : undefined;

  // // ログイン済みユーザ情報の取得
  // // TODO 毎回やるとそれなりに遅いので考える
  // // api側で都度ユーザが有効かどうか確認するのでここではスキップする?
  // const loggedInUser = await Vue.$cognito?.getLoggedInUserDetail();
  // // ログインしていなければリダイレクト
  // if (!loggedInUser) {
  //   window.location.href = '/';
  //   throw {};
  // }

  let token: string;
  try {
    // 内部サイトのとき
    if (Vue.$cognito) {
      // アクセストークンの取得に失敗したらトップにリダイレクトする
      token = (await Vue.$cognito.getToken()) || '';
      if (!token) {
        throw new Error('token is invalid');
      }
    }
    // 公開サイトのとき
    else {
      token = '';
    }
  } catch (error) {
    if (window.location.href === '/') {
      window.location.reload();
    } else {
      window.location.href = '/';
    }
    throw {};
  }

  // ページタブIdを取得
  const pageTabId = getPageTabId();

  const result: FetchParams = {
    url: `${Vue.$config.api}/api${path}${query ? `?${query}` : ''}`,
    options: {
      method: method?.toUpperCase(),
      headers: {
        // 認証情報を入れる
        // 公開サイトはtokenは不要なのでキーも持たないようにする
        ...(token ? { Authorization: token } : {}),
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-PageTabId': pageTabId,
      },
      credentials: 'include',
      body,
    },
  };

  return result;
}

Vue.use(plugin);
