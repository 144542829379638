<template>
  <div class="reserveDetail__item reserveOption">
    <section class="ttlCont">
      <h3 class="borderTtl">オプション</h3>
      <slot name="title-side" />
    </section>
    <div>
      <!-- 追加済オプション -->
      <v-tabs v-model="tabIdx" color="basil" class="mt-3">
        <!-- タブ -->
        <v-tab v-for="(_, key) in options[facilityId]" :key="key">
          <div class="my-n1 py-1">
            <span class="font-weight-bold ma-2">
              {{ key }}
            </span>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabIdx">
        <!-- コンテンツ -->
        <v-tab-item v-for="(options_, date) in options[facilityId]" :key="date">
          <v-simple-table class="optionTable">
            <tbody>
              <tr v-for="(option, j) in options_" :key="j">
                <td class="itemName">
                  {{ unitDict[option.unitId]?.name }}
                </td>
                <td
                  class="itemPrice d-flex flex-row-reverse align-center mb-md-0"
                >
                  {{ amounts[facilityId][date][j].toLocaleString() }}円
                </td>
                <td
                  class="itemUnit d-flex align-start justify-center flex-column"
                >
                  <div
                    class="d-flex"
                    v-for="field in unitDict[option.unitId]?.fields"
                    :key="field.id"
                  >
                    <span class="price d-flex align-center">{{
                      field.name
                    }}</span>
                    <span class="times">&emsp;</span>
                    <span class="unit">
                      {{ option[field.property] }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <slot name="action" :date="date" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script lang="ts">
import { Unit } from '@api/models';
import { ApproxAmountOptions } from '@web-t/books';
import { CartOptionDict } from '@web-t/store/cart';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CartOption',

  props: {
    facilityId: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<CartOptionDict>,
      required: true,
    },
    unitDict: {
      type: Object as PropType<Record<string, Unit>>,
      required: true,
    },
    amounts: {
      type: Object as PropType<ApproxAmountOptions>,
      required: true,
    },
  },

  data: () => ({
    tabIdx: 0,
  }),
});
</script>
