import {
  ConstantPurposeSize,
  ConstantPurposeSizeDict,
} from '../types/constants/purposeSize';

const purposeSizeDict: ConstantPurposeSizeDict = {
  lg: {
    text: '大',
    value: 'lg',
  },
  md: {
    text: '中',
    value: 'md',
  },
  sm: {
    text: '小',
    value: 'sm',
  },
};

export const purposeSize: ConstantPurposeSize = {
  ...purposeSizeDict,

  items: [purposeSizeDict.lg, purposeSizeDict.md, purposeSizeDict.sm],

  get(value: string): string {
    switch (value) {
      case purposeSizeDict.lg.value:
        return purposeSizeDict.lg.text;
      case purposeSizeDict.md.value:
        return purposeSizeDict.md.text;
      case purposeSizeDict.sm.value:
        return purposeSizeDict.sm.text;
      default:
        return '';
    }
  },
};
