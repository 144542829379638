<template>
  <div class="mContainer -news pt-0">
    <section class="inner">
      <div class="newsArea">
        <section class="titleArea">
          <h2 class="topSubttl d-flex align-center">
            <span class="orgIcon icon-news txtC-green"></span>
            <div class="txt">
              <span class="ja">お知らせ</span>
              <span class="en" aria-hidden="true">NEWS</span>
            </div>
          </h2>
          <div class="btnArea">
            <v-btn depressed small to="/news" class="btnBasic">
              一覧を見る
            </v-btn>
          </div>
        </section>
        <div class="contArea">
          <template v-if="displayItems.length > 0">
            <router-link
              v-for="item in displayItems"
              :key="item.id"
              :to="{ path: '/news', query: { id: item.id } }"
              class="contArea__item"
            >
              <div class="newsFlex">
                <div class="flexItem">
                  <time class="date">
                    {{ item.from | date }}
                  </time>
                </div>
                <p class="newsTxt mb-0">
                  {{ item.subject }}
                </p>
              </div>
            </router-link>
          </template>
          <template v-else>
            <span>お知らせはありません。</span>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { NoticeForUser } from '@api/models';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'HomeNotice',

  props: {
    items: {
      type: Array as PropType<NoticeForUser[]>,
      default: () => [],
    },
  },

  computed: {
    displayItems(): NoticeForUser[] {
      return this.items.slice(0, 3);
    },
  },
});
</script>
