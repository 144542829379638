<template>
  <div class="mContainer -purpose">
    <section class="inner">
      <h2
        class="topSubttl -center d-flex flex-column justify-space-between align-center"
      >
        <span class="orgIcon icon-flag txtC-green"></span>
        <span class="ja">利用目的から予約する</span>
        <span class="en" aria-hidden="true">PURPOSE</span>
      </h2>
      <v-row class="purposeList d-flex justify-center">
        <v-col
          v-for="item in items"
          :key="item.id"
          :cols="sizeMapping[item.size].cols"
          :md="sizeMapping[item.size].md"
          :sm="sizeMapping[item.size].sm"
          class="purposeList__item"
        >
          <router-link :to="{ path: '/search', query: { purposeId: item.id } }">
            <p>
              <span>{{ item.name }}</span>
            </p>
            <v-img :src="images[item.imageName]"></v-img>
          </router-link>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Purpose } from '@api/models';
import { ConstantPurposeSizeKey } from '@api-t/constants/purposeSize';

const images = {
  purpose01: require('../../assets/img/top/purpose01.jpg'),
  purpose02: require('../../assets/img/top/purpose02.jpg'),
  purpose03: require('../../assets/img/top/purpose03.jpg'),
  purpose04: require('../../assets/img/top/purpose04.jpg'),
  purpose05: require('../../assets/img/top/purpose05.jpg'),
  purpose06: require('../../assets/img/top/purpose06.jpg'),
  purpose07: require('../../assets/img/top/purpose07.jpg'),
  purpose08: require('../../assets/img/top/purpose08.jpg'),
  purpose09: require('../../assets/img/top/purpose09.jpg'),
  purpose10: require('../../assets/img/top/purpose10.jpg'),
  purpose11: require('../../assets/img/top/purpose11.jpg'),
  purpose12: require('../../assets/img/top/purpose12.jpg'),
  purpose13: require('../../assets/img/top/purpose13.jpg'),
  purpose14: require('../../assets/img/top/purpose14.jpg'),
  purpose15: require('../../assets/img/top/purpose15.jpg'),
};

const sizeMapping: Record<
  ConstantPurposeSizeKey,
  {
    cols: string;
    md?: string;
    sm?: string;
  }
> = {
  lg: {
    cols: '12',
    sm: '6',
  },
  md: {
    cols: '6',
    md: '4',
  },
  sm: {
    cols: '6',
    md: '3',
  },
};

export default Vue.extend({
  name: 'HomePurpose',

  props: {
    items: {
      type: Array as PropType<Purpose[]>,
      default: () => [],
    },
  },

  data: () => ({
    images,
    sizeMapping,
  }),
});
</script>
