<template>
  <ExternalLayout>
    <transition name="page">
      <router-view />
    </transition>
  </ExternalLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import { chain } from 'lodash';
import ExternalLayout from '@web-e/components/layout/index.vue';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { mapActions as mapActionsCart } from './store/cart';
import { mapActions as mapActionsLoginState } from './store/loginStatus';
import { GetLoggedInResponse } from './routes/login/login';
import { cartFormat } from '@web/modules/cart-handler';
import { loadCacheUnits } from '@web/modules/master-loader';

export default Vue.extend({
  name: 'App',

  components: {
    ExternalLayout,
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loagLoggedIn()]),
      );
    },

    /**
     * ログイン済みデータを取得など
     */
    async loagLoggedIn() {
      try {
        // ログイン済みかどうか取得する
        const result = await this.$api<GetLoggedInResponse>({
          path: '/login/getLoggedIn',
          method: 'post',
          params: {},
        });

        // ログイン済みの場合
        if (result.book) {
          const units = await loadCacheUnits(this, { scope: 'fees' });
          const unitDict = chain(units).keyBy('id').value();

          const { book, details, options } = cartFormat(result.book, unitDict);
          await this.setBook(book);
          await this.setDetails(details);
          await this.setOptions(options);

          // ログイン済みであればストアにセット
          await this.setIsLoggedIn(true);
        }

        // ログイン済みでない場合
        else {
          // ストアから排除
          await this.setIsLoggedIn(false);
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['ログインに失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
        }
        throw error;
      }
    },

    ...mapActionsCart(['setBook', 'setDetails', 'setOptions']),
    ...mapActionsLoginState(['setIsLoggedIn']),
  },
});
</script>

<style lang="scss" scoped>
.page-enter-active,
.page-leave-active {
  transition: opacity 0.4s ease;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}
</style>
