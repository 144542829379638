import Vue, { PluginObject } from 'vue';
import { evaluate } from 'mathjs';
import { MathjsInstance } from '@web-t/mathjs';

const instance: MathjsInstance = {
  evaluate: (expr, scope?) => {
    try {
      return evaluate(expr, scope);
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
};

const plugin: PluginObject<void> = {
  install: function (Vue) {
    Vue.prototype.$mathjs = instance;
    Vue.$mathjs = instance;
  },
};

Vue.use(plugin);
