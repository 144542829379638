<template>
  <div class="mContainer -guide">
    <section class="inner">
      <h2
        class="topSubttl -center d-flex flex-column justify-space-between align-center"
      >
        <span class="orgIcon icon-guide txtC-green"></span>
        <span class="ja">ご利用方法</span>
        <span class="en" aria-hidden="true">GUIDE</span>
      </h2>
      <div class="guideList">
        <div class="guideList__item">
          <section class="txt">
            <span class="num en num1">01</span>
            <h4 class="ttl num1">施設を検索する</h4>
            <p class="readTxt mb-0">
              場所や日時など、<br />
              希望条件から検索しましょう
            </p>
          </section>
          <v-img
            src="../../assets/img/top/guide01.jpg"
            alt="施設を検索する"
          ></v-img>
        </div>
        <div class="guideList__item">
          <section class="txt">
            <span class="num en num2">02</span>
            <h4 class="ttl num2">施設を予約する</h4>
            <p class="readTxt mb-0">Webから<br />簡単手続きで完了</p>
          </section>
          <v-img
            src="../../assets/img/top/guide02.jpg"
            alt="施設を予約する"
          ></v-img>
        </div>
        <div class="guideList__item">
          <section class="txt">
            <span class="num en num3">03</span>
            <h4 class="ttl num3">施設を利用する</h4>
            <p class="readTxt mb-0">
              利用方法をチェックしたら、<br />あとは楽しむだけ！
            </p>
          </section>
          <v-img
            src="../../assets/img/top/guide03.jpg"
            alt="施設を利用する"
          ></v-img>
        </div>
      </div>
      <div class="btnArea -list">
        <v-btn depressed x-large href="files/usage.pdf" class="btnBasic guide">
          詳しいご利用方法はこちら
        </v-btn>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'HomeGuide',
});
</script>
