import {
  ConstantInputType,
  ConstantInputTypeDict,
} from '@api-t/constants/inputType';

const inputTypeDict: ConstantInputTypeDict = {
  enumSingle: {
    text: '単一選択',
    value: 'enum_single',
  },
  enumMultiple: {
    text: '複数選択',
    value: 'enum_multiple',
  },
  date: {
    text: '日付',
    value: 'date',
  },
  time: {
    text: '時間',
    value: 'time',
  },
  unitItems: {
    text: '区画種類',
    value: 'unit_items',
  },
  text: {
    text: 'テキスト',
    value: 'text',
  },
  number: {
    text: '数値',
    value: 'number',
  },
  checkbox: {
    text: 'チェックボックス',
    value: 'checkbox',
  },
};

export const inputType: ConstantInputType = {
  ...inputTypeDict,

  items: [
    inputTypeDict.enumSingle,
    inputTypeDict.enumMultiple,
    inputTypeDict.date,
    inputTypeDict.time,
    inputTypeDict.unitItems,
    inputTypeDict.text,
    inputTypeDict.number,
    inputTypeDict.checkbox,
  ],

  selectable: [
    inputTypeDict.enumSingle.value,
    inputTypeDict.enumMultiple.value,
    inputTypeDict.unitItems.value,
  ],

  get(value: string): string {
    switch (value) {
      case inputTypeDict.enumSingle.value:
        return inputTypeDict.enumSingle.text;
      case inputTypeDict.enumMultiple.value:
        return inputTypeDict.enumMultiple.text;
      case inputTypeDict.date.value:
        return inputTypeDict.date.text;
      case inputTypeDict.time.value:
        return inputTypeDict.time.text;
      case inputTypeDict.unitItems.value:
        return inputTypeDict.unitItems.text;
      case inputTypeDict.text.value:
        return inputTypeDict.text.text;
      case inputTypeDict.number.value:
        return inputTypeDict.number.text;
      case inputTypeDict.checkbox.value:
        return inputTypeDict.checkbox.text;
      default:
        return '';
    }
  },
};
