<template>
  <div class="lContainer -sub -search">
    <h1 class="pageTtl text-center">キャンプ場・施設を探す</h1>

    <div class="mContainer -searchCont">
      <section class="inner searchFoamCont">
        <div class="searchFoam">
          <div class="inputCont">
            <SearchForm :purposes="purposes" :facilities="facilities" />
          </div>
          <div class="actionCont">
            <v-card-actions class="btnArea">
              <v-btn depressed small class="btnBasic" @click="searchValidate()">
                <v-icon>mdi-magnify</v-icon>検索する
              </v-btn>
              <v-btn depressed small class="btnBasic -back" @click="clearForm">
                クリア
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </section>
    </div>

    <Result :facilities="facilities" @load="searchValidate" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { range } from 'lodash';
import Result from './Result.vue';
import {
  loadCacheFacilities,
  loadCachePurposes,
} from '@web/modules/master-loader';
import { Facility, Purpose } from '@api/models';
import SearchForm from '@web-e/components/SearchForm.vue';
import {
  GetSearchResultRequest,
  GetSearchResultResponse,
} from '@api-e/routes/search/search';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Search',

  pageOptions: {
    routeConfig: {
      path: '/search',
    },
    props: {
      title: 'キャンプ場・施設を探す',
    },
  },

  components: {
    SearchForm,
    Result,
  },

  data: () => ({
    // 目的マスタ
    purposes: [] as Purpose[],
    // 施設マスタ
    facilities: [] as Facility[],
  }),

  computed: {
    ...mapGetters('search', ['search']),
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      const facilityId = this.$route.query.facilityId;
      if (facilityId) {
        await this.setSearchFacilityId(facilityId);
      }
      const purposeId = this.$route.query.purposeId;
      if (purposeId) {
        await this.setSearchPurposeId(purposeId);
      }
      await Promise.all([this.loadPurpose(), this.loadFacility()]);
      await this.searchValidate();
    },

    // 目的を読み込み
    async loadPurpose() {
      const purposes = await loadCachePurposes(this);
      this.$set(this, 'purposes', purposes);
    },

    // 施設を読み込み
    async loadFacility() {
      const facilities = await loadCacheFacilities(this);
      this.$set(this, 'facilities', facilities);
    },

    /**
     * バリデート
     */
    async searchValidate(date?: Date) {
      const { date: searchDate } = this.search;
      const date_ =
        date ?? (!searchDate ? new Date() : new Date(searchDate as string));
      const date__ = structuredClone(date_);
      date__.setDate(1);
      const datesOfMonth = [] as Date[][];
      let i_ = 0;
      let j_ = 0;
      for (let i = 0; date_.getMonth() === date__.getMonth(); i++) {
        const datesOfWeek = range(7).map((j) => {
          date__.setDate(date__.getDate() - ((date__.getDay() + 6) % 7) + j);
          if (date__ <= date_ && date_ <= date__) {
            i_ = i;
            j_ = j;
          }
          return structuredClone(date__);
        });
        datesOfMonth.push(datesOfWeek);
        date__.setDate(date__.getDate() + 1);
      }
      // バリデート
      // TODO
      // 問題なければ検索実行
      await this.getSearchResult(datesOfMonth, i_, j_);
    },

    /**
     * 検索本体
     */
    async getSearchResult(
      datesOfMonth: Date[][],
      weekIdx: number,
      dateIdx: number,
    ) {
      const datesOfMonth_ = datesOfMonth.flatMap((datesOfWeek) => {
        return datesOfWeek.map((date) => {
          return this.$dateFns.fnsFormat(date, '', 'yyyy-MM-dd');
        });
      });
      try {
        const result = await this.$api<
          GetSearchResultResponse,
          GetSearchResultRequest
        >({
          path: '/search',
          method: 'post',
          params: {
            facilityId: this.search.facilityIds,
            purposeId: this.search.purposeIds,
            startDate: datesOfMonth_,
          },
        });
        this.setAvailability(result.availability);
        this.setDatesOfMonth(datesOfMonth);
        this.setWeekIdx(weekIdx);
        this.setDateIdx(dateIdx);
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              '検索結果の取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
        }
        throw error;
      }
    },

    /**
     * 検索フォームをクリアする
     */
    clearForm() {
      // 変数をリセット
      this.clearSearch();
    },

    ...mapActions('search', [
      'setSearchFacilityId',
      'setSearchPurposeId',
      'clearSearch',
    ]),
    ...mapActions('free', [
      'setAvailability',
      'setDatesOfMonth',
      'setWeekIdx',
      'setDateIdx',
    ]),
  },
});
</script>
