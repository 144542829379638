import {
  ConstantSchemaOperation,
  ConstantSchemaOperationDict,
  ConstantSchemaOperationKey,
} from '@api-t/constants/schemaOperation';
import { Schema } from '@api-t/utils';
import { get, isEmpty } from 'lodash';
import { evaluate } from 'mathjs';

const schemaOperationDict: ConstantSchemaOperationDict = {
  empty: {
    text: '未入力である',
    value: 'empty',
    numOfArgs: 0,
  },
  notEmpty: {
    text: '未入力でない',
    value: 'notEmpty',
    numOfArgs: 0,
  },
  holiday: {
    text: '祝日である',
    value: 'holiday',
    numOfArgs: 0,
  },
  notHoliday: {
    text: '祝日でない',
    value: 'notHoliday',
    numOfArgs: 0,
  },
  eq: {
    text: '{$1}と等しい',
    value: 'eq',
    numOfArgs: 1,
  },
  ne: {
    text: '{$1}と等しくない',
    value: 'ne',
    numOfArgs: 1,
  },
  isTrue: {
    text: 'チェックされている',
    value: 'isTrue',
    numOfArgs: 0,
  },
  isNotTrue: {
    text: 'チェックされていない',
    value: 'isNotTrue',
    numOfArgs: 0,
  },
  gt: {
    text: '{$1}より大きい',
    value: 'gt',
    numOfArgs: 1,
  },
  gte: {
    text: '{$1}以上',
    value: 'gte',
    numOfArgs: 1,
  },
  lt: {
    text: '{$1}より小さい',
    value: 'lt',
    numOfArgs: 1,
  },
  lte: {
    text: '{$1}以下',
    value: 'lte',
    numOfArgs: 1,
  },
  between: {
    text: '{$1}と{$2}の間にある',
    value: 'between',
    numOfArgs: 2,
  },
  notBetween: {
    text: '{$1}と{$2}の間にない',
    value: 'notBetween',
    numOfArgs: 2,
  },
  betweenMonth: {
    text: '{$1}月と{$2}月の間にある',
    value: 'betweenMonth',
    numOfArgs: 2,
  },
  notBetweenMonth: {
    text: '{$1}月と{$2}月の間にない',
    value: 'notBetweenMonth',
    numOfArgs: 2,
  },
  betweenDayOfWeek: {
    text: '{$1}曜日と{$2}曜日の間にある',
    value: 'betweenDayOfWeek',
    numOfArgs: 2,
  },
  notBetweenDayOfWeek: {
    text: '{$1}曜日と{$2}曜日の間にない',
    value: 'notBetweenDayOfWeek',
    numOfArgs: 2,
  },
  in: {
    text: '{$1}を含む',
    value: 'in',
    numOfArgs: 1,
  },
  notIn: {
    text: '{$1}を含まない',
    value: 'notIn',
    numOfArgs: 1,
  },
  length: {
    text: '{$1}つ選ばれている',
    value: 'length',
    numOfArgs: 1,
  },
  like: {
    text: '{$1}を含む',
    value: 'like',
    numOfArgs: 1,
  },
  notLike: {
    text: '{$1}を含まない',
    value: 'notLike',
    numOfArgs: 1,
  },
  startsWith: {
    text: '{$1}から始まる',
    value: 'startsWith',
    numOfArgs: 1,
  },
  endsWith: {
    text: '{$1}で終わる',
    value: 'endsWith',
    numOfArgs: 1,
  },
};

export const schemaOperation: ConstantSchemaOperation = {
  ...schemaOperationDict,

  get(type) {
    switch (type) {
      case 'enum_single':
        return [
          schemaOperationDict.empty,
          schemaOperationDict.notEmpty,
          schemaOperationDict.eq,
          schemaOperationDict.ne,
        ];
      case 'enum_multiple':
        return [
          schemaOperationDict.empty,
          schemaOperationDict.notEmpty,
          schemaOperationDict.in,
          schemaOperationDict.notIn,
          schemaOperationDict.length,
        ];
      case 'date':
        return [
          schemaOperationDict.empty,
          schemaOperationDict.notEmpty,
          schemaOperationDict.holiday,
          schemaOperationDict.notHoliday,
          schemaOperationDict.betweenMonth,
          schemaOperationDict.notBetweenMonth,
          schemaOperationDict.betweenDayOfWeek,
          schemaOperationDict.notBetweenDayOfWeek,
        ];
      case 'time':
        return [schemaOperationDict.empty, schemaOperationDict.notEmpty];
      case 'unit_items':
        return [
          schemaOperationDict.empty,
          schemaOperationDict.notEmpty,
          schemaOperationDict.in,
          schemaOperationDict.notIn,
          schemaOperationDict.length,
        ];
      case 'text':
        return [
          schemaOperationDict.empty,
          schemaOperationDict.notEmpty,
          schemaOperationDict.eq,
          schemaOperationDict.ne,
          schemaOperationDict.like,
          schemaOperationDict.notLike,
          schemaOperationDict.startsWith,
          schemaOperationDict.endsWith,
        ];
      case 'number':
        return [
          schemaOperationDict.empty,
          schemaOperationDict.notEmpty,
          schemaOperationDict.eq,
          schemaOperationDict.ne,
          schemaOperationDict.gt,
          schemaOperationDict.gte,
          schemaOperationDict.lt,
          schemaOperationDict.lte,
          schemaOperationDict.between,
          schemaOperationDict.notBetween,
        ];
      case 'checkbox':
        return [schemaOperationDict.isTrue, schemaOperationDict.isNotTrue];
      default:
        return [];
    }
  },

  find(target, data, append) {
    const result = target.find((t) => {
      if (!t.schema_ || !t.schema_.length) {
        return target.length === 1;
      }
      return t.schema_.every((schema) => {
        return evaluateSchema(schema, data, append);
      });
    });

    return result;
  },

  filter(target, data, append) {
    const result = target.filter((t) => {
      if (!t.schema_ || !t.schema_.length) {
        return target.length === 1;
      }
      return t.schema_.every((schema) => {
        return evaluateSchema(schema, data, append);
      });
    });

    return result;
  },

  eval(schema, data, append) {
    if (!schema || !schema.length) {
      return true;
    }
    return schema.every((s) => {
      return evaluateSchema(s, data, append);
    });
  },
};

const evaluateSchema = (
  schema: Schema<ConstantSchemaOperationKey>,
  data: any,
  append?: any,
): boolean => {
  const { operation, property, value_1: v1, value_2: v2 } = schema;
  const target = get(data, property);
  const value_1 = evaluateIfIsFormula(v1, data);
  const value_2 = evaluateIfIsFormula(v2, data);
  switch (operation) {
    case 'empty': {
      try {
        const v = JSON.parse(target);
        if (typeof v === 'object') return isEmpty(v);
        return false;
      } catch (e) {
        return !target;
      }
    }
    case 'notEmpty': {
      try {
        const v = JSON.parse(target);
        if (typeof v === 'object') return !isEmpty(v);
        return true;
      } catch (e) {
        return !!target;
      }
    }
    case 'holiday': {
      return append?.holidays?.includes(target);
    }
    case 'notHoliday': {
      return !append?.holidays?.includes(target);
    }
    case 'eq': {
      return target === value_1;
    }
    case 'ne': {
      return target !== value_1;
    }
    case 'isTrue': {
      return parseBooleanJSON(target);
    }
    case 'isNotTrue': {
      return !parseBooleanJSON(target);
    }
    case 'gt': {
      return Number(target) > Number(value_1);
    }
    case 'gte': {
      return Number(target) >= Number(value_1);
    }
    case 'lt': {
      return Number(target) < Number(value_1);
    }
    case 'lte': {
      return Number(target) <= Number(value_1);
    }
    case 'between': {
      return value_1 <= target && target <= value_2;
    }
    case 'notBetween': {
      return target < value_1 || value_2 < target;
    }
    case 'betweenMonth': {
      const d = new Date(target);
      const month = d.getMonth() + 1;
      return isInRange(month, value_1, value_2);
    }
    case 'notBetweenMonth': {
      const d = new Date(target);
      const month = d.getMonth() + 1;
      return !isInRange(month, value_1, value_2);
    }
    case 'betweenDayOfWeek': {
      const d = new Date(target);
      const dayOfWeek = d.getDay();
      return isInRange(dayOfWeek, value_1, value_2);
    }
    case 'notBetweenDayOfWeek': {
      const d = new Date(target);
      const dayOfWeek = d.getDay();
      return !isInRange(dayOfWeek, value_1, value_2);
    }
    case 'in': {
      return parseEnumJSON(target).includes(value_1);
    }
    case 'notIn': {
      return !parseEnumJSON(target).includes(value_1);
    }
    case 'length': {
      return parseEnumJSON(target).length === Number(value_1);
    }
    case 'like': {
      return (target as string).includes(value_1);
    }
    case 'notLike': {
      return !(target as string).includes(value_1);
    }
    case 'startsWith': {
      return (target as string).startsWith(value_1);
    }
    case 'endsWith': {
      return (target as string).endsWith(value_1);
    }
    default: {
      return false;
    }
  }
};

const evaluateIfIsFormula = (value: string, scope: any): string => {
  if (!value || !value.startsWith('=')) {
    return value;
  }
  const expr = value.slice(1).trim();
  try {
    return String(evaluate(expr, scope) ?? '');
  } catch (error) {
    console.error(error);
    return '';
  }
};

const isInRange = (target: any, value_1: string, value_2: string) => {
  const v1 = Number(value_1);
  const v2 = Number(value_2);
  const result =
    v1 < v2 ===
    (Math.min(v1, v2 + 1) <= target && target <= Math.max(v1 - 1, v2));

  return result;
};

const parseBooleanJSON = (value: string): boolean => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return false;
  }
};

const parseEnumJSON = (value: string): string[] => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return [];
  }
};
