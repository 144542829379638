<template>
  <v-overlay :value="showLoading" color="white" :opacity="0.5" :z-index="999">
    <LoadingInner />
  </v-overlay>
</template>

<script lang="ts">
import Vue from 'vue';
import LoadingInner from './LoadingInner.vue';

export default Vue.extend({
  name: 'LoadingOverlay',

  components: {
    LoadingInner,
  },

  computed: {
    showLoading(): boolean {
      return this.$store.getters['loading/showLoading'];
    },
  },
});
</script>
