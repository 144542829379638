<template>
  <td :class="{ clickable, bgRed, bgBlue, bgYellow }" @click="$emit('click')">
    <div v-if="!!label" class="label">
      {{ label }}
    </div>
    <div class="txt">
      <span v-if="!!type">
        {{ closedDayType.get(type).charAt(0) }}
      </span>
      <v-icon v-else-if="0 <= ratio && ratio < 75" class="link">
        mdi-checkbox-blank-circle-outline
      </v-icon>
      <v-icon v-else-if="75 <= ratio && ratio < 100" class="link">
        mdi-triangle-outline
      </v-icon>
      <v-icon v-else-if="ratio >= 100">mdi-close</v-icon>
    </div>
    <div v-if="!type && remain > 0" class="remain">残：{{ remain }}</div>
  </td>
</template>

<script lang="ts">
import { ConstantClosedDayType } from '@api-t/constants/closedDayType';
import { closedDayType } from '@api/constants';
import Vue from 'vue';

export default Vue.extend({
  name: 'Availability',

  props: {
    label: {
      type: String,
      default: undefined,
    },

    ratio: {
      type: Number,
      default: 0,
    },

    remain: {
      type: Number,
      default: undefined,
    },

    type: {
      type: String,
      default: '',
    },
  },

  computed: {
    closedDayType(): ConstantClosedDayType {
      return closedDayType;
    },

    clickable(): boolean {
      return !this.type && this.ratio < 100;
    },

    /**
     * 休業、臨時、受付期間外の色
     */
    bgRed(): boolean {
      return this.type === closedDayType.closed.value;
    },

    bgBlue(): boolean {
      return this.type === closedDayType.maintenance.value;
    },

    bgYellow(): boolean {
      return this.type === closedDayType.out.value;
    },
  },

  mounted() {
    /**
     * クラス名：labelのついたdiv要素の高さを揃える
     */
    const labels = document.querySelectorAll<HTMLElement>('.label');
    const heights = Array.from(labels).map((label) => label.clientHeight);
    const maxHeight = Math.max(...heights);
    labels.forEach((label) => (label.style.height = `${maxHeight}px`));
  },
});
</script>
