<template>
  <div>
    <v-text-field
      :value="value"
      outlined
      :clearable="clearable"
      dense
      readonly
      hide-details="auto"
      :label="label"
      v-bind="{ rules, ...textFieldProps }"
      role="button"
      :aria-haspopup="true"
      :aria-expanded="`${menu}`"
      @click.stop="menu = true"
      @keydown.enter.space.stop="menu = true"
      @click:clear="clear"
      @keydown.delete="clear"
    ></v-text-field>
    <v-dialog v-model="menu" :width="300">
      <v-card class="calendar timePicker">
        <v-card-text class="px-0 py-0 text--primary">
          <TimeSelector v-model="value_" v-bind="timeSelectorAttrs" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4 py-4">
          <v-spacer></v-spacer>
          <v-btn x-small class="gray" @click="menu = false"> キャンセル </v-btn>
          <v-btn x-small @click="select"> 指定する </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import TimeSelector, { props as timeSelectorProps } from './time-selector.vue';

export default Vue.extend({
  name: 'TimePicker',

  components: {
    TimeSelector,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Array as PropType<
        (boolean | string | ((v: any) => boolean | string))[]
      >,
      default: () => [],
    },

    textFieldProps: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },

    ...timeSelectorProps,
  },

  data: () => ({
    menu: false,
    value_: '',
  }),

  computed: {
    timeSelectorAttrs(): Record<string, any> {
      return Object.keys(timeSelectorProps).reduce((prev, nextKey) => {
        const nextValue = (this as any)[nextKey];
        if (nextValue !== undefined) {
          return {
            ...prev,
            [nextKey]: nextValue,
          };
        }
        return prev;
      }, {});
    },
  },

  watch: {
    menu: {
      handler(open) {
        if (open) {
          this.value_ = this.value;
        }
      },
    },
  },

  mounted() {
    this.value_ = this.value;
  },

  methods: {
    select() {
      this.menu = false;
      this.$emit('input', this.value_);
    },

    clear() {
      this.$emit('input', '');
      this.$emit('clear');
    },
  },
});
</script>
