<template>
  <div class="timePicker timePickerWrapper d-flex justify-center" width="100%">
    <div class="custom-time-picker">
      <div class="preview">
        <input
          :value="value_"
          type="text"
          placeholder="HH:mm"
          readonly
          autocomplete="off"
          class="text-center"
          :tabindex="-1"
        />
      </div>
      <div class="time-selector d-flex justify-center">
        <div class="selector-hour time-number-selector">
          <div class="select-label text-center py-1 pr-4">時間</div>
          <div
            class="overflow-y-auto select-number-wrap"
            style="height: 200px"
            role="list"
          >
            <div
              v-for="h in hours"
              :key="h"
              class="select-number text-center py-1"
              :class="{
                selected: h === value_.slice(0, 2),
              }"
              role="listitem"
              :aria-label="`${Number(h)}時`"
              :aria-selected="`${h === value_.slice(0, 2)}`"
              :tabindex="(h === focusedValue.slice(0, 2)) - 1"
              @click="selectHour(h)"
              @keydown.up.prevent="prevHour"
              @keydown.down.prevent="nextHour"
              @keydown.enter.prevent="setHour"
            >
              {{ h }}
            </div>
          </div>
        </div>
        <div class="selector-minute time-number-selector">
          <div class="select-label text-center py-1 pr-4">分</div>
          <div
            class="overflow-y-auto select-number-wrap"
            style="height: 200px"
            role="list"
          >
            <div
              v-for="m in minutes"
              :key="m"
              class="select-number text-center py-1"
              :class="{
                selected: m === value_.slice(-2),
              }"
              role="listitem"
              :aria-label="`${Number(m)}分`"
              :aria-selected="`${m === value_.slice(-2)}`"
              :tabindex="(m === focusedValue.slice(-2)) - 1"
              @click="selectMinute(m)"
              @keydown.up.prevent="prevMinute"
              @keydown.down.prevent="nextMinute"
              @keydown.enter.prevent="setMinute"
            >
              {{ m }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export const props = {
  hourMin: {
    type: Number,
    default: 0,
  },
  hourMax: {
    type: Number,
    default: 23,
  },
  hourStep: {
    type: Number,
    default: 1,
  },
  minuteMin: {
    type: Number,
    default: 0,
  },
  minuteMax: {
    type: Number,
    default: 59,
  },
  minuteStep: {
    type: Number,
    default: 1,
  },
};

export default Vue.extend({
  name: 'TimeSelector',

  props: {
    value: {
      type: String,
      default: '',
    },
    ...props,
  },

  data: () => ({
    focusedValue: '',
  }),

  computed: {
    value_: {
      get(): string {
        return this.value;
      },
      set(newValue: string) {
        this.$emit('input', newValue);
      },
    },
    hours() {
      let numbers: number[] = [];
      for (let i = this.hourMin; i <= this.hourMax; i = i + this.hourStep) {
        numbers.push(i);
      }
      return numbers.map((n) => String(n).padStart(2, '0'));
    },
    minutes() {
      let numbers: number[] = [];
      for (
        let i = this.minuteMin;
        i <= this.minuteMax;
        i = i + (this.minuteStep || 1)
      ) {
        numbers.push(i);
      }
      return numbers.map((n) => String(n).padStart(2, '0'));
    },
  },

  mounted() {
    this.focusedValue = this.value_;
  },

  methods: {
    selectHour(hour: string) {
      this.value_ = hour + (this.value_.slice(-3) || ':00');
    },

    selectMinute(minute: string) {
      this.value_ = (this.value_.slice(0, 3) || '00:') + minute;
    },

    /**
     * 前の時間へフォーカスする
     */
    async prevHour() {
      const hour = this.focusedValue.slice(0, 2);
      const newIdx = this.hours.indexOf(hour) - 1;
      if (newIdx < 0) {
        return;
      }
      const newHour = this.hours[newIdx];
      this.focusedValue = newHour + (this.focusedValue.slice(-3) || ':00');
      await this.$nextTick();
      (
        document.querySelector(
          '.selector-hour .select-number[tabindex="0"]',
        ) as HTMLElement
      ).focus();
    },

    /**
     * 次の時間へフォーカスする
     */
    async nextHour() {
      const hour = this.focusedValue.slice(0, 2);
      const newIdx = this.hours.indexOf(hour) + 1;
      if (this.hours.length <= newIdx) {
        return;
      }
      const newHour = this.hours[newIdx];
      this.focusedValue = newHour + (this.focusedValue.slice(-3) || ':00');
      await this.$nextTick();
      (
        document.querySelector(
          '.selector-hour .select-number[tabindex="0"]',
        ) as HTMLElement
      ).focus();
    },

    /**
     * フォーカスしている時間をセットする
     */
    setHour() {
      const hour = this.focusedValue.slice(0, 2);
      this.selectHour(hour);
    },

    /**
     * 前の分へフォーカスする
     */
    async prevMinute() {
      const minute = this.focusedValue.slice(-2);
      const newIdx = this.minutes.indexOf(minute) - 1;
      if (newIdx < 0) {
        return;
      }
      const newMinute = this.minutes[newIdx];
      this.focusedValue = (this.focusedValue.slice(0, 3) || '00:') + newMinute;
      await this.$nextTick();
      (
        document.querySelector(
          '.selector-minute .select-number[tabindex="0"]',
        ) as HTMLElement
      ).focus();
    },

    /**
     * 次の分へフォーカスする
     */
    async nextMinute() {
      const minute = this.focusedValue.slice(-2);
      const newIdx = this.minutes.indexOf(minute) + 1;
      if (this.minutes.length <= newIdx) {
        return;
      }
      const newMinute = this.minutes[newIdx];
      this.focusedValue = (this.focusedValue.slice(0, 3) || '00:') + newMinute;
      await this.$nextTick();
      (
        document.querySelector(
          '.selector-minute .select-number[tabindex="0"]',
        ) as HTMLElement
      ).focus();
    },

    /**
     * フォーカスしている分をセットする
     */
    setMinute() {
      const minute = this.focusedValue.slice(-2);
      this.selectMinute(minute);
    },
  },
});
</script>

<style lang="scss" scoped>
.timePickerWrapper {
  width: 300px;
}
</style>
