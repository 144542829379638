<template>
  <div class="lContainer -sub -privacyTerms">
    <h1 class="pageTtl text-center">利用規約</h1>
    <div class="mContainer -termsCont">
      <div class="inner">
        <section class="list">
          <h2 class="ttl">1.目的</h2>
          <p>
            この規約は、オクシズ観光関連施設予約システム「オクシズばった」（以下、「本システム」という。）を利用して、静岡市（以下、「本市」という。）が管理する別表施設の予約等を行うために必要な事項について定めるものです。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">2.運営</h2>
          <p>
            本システムの運営は、本市及び施設管理者（指定管理者及び管理受託者。以下、「施設管理者等」という。）が行います。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">3.利用上の注意</h2>
          <p>
            本市及び施設管理者等は、この利用規約に同意し、かつ、本システムの利用方法の手順に従い利用する方（以下、「利用者」という。）のみに対して、本システムを利用していただくこととしております。<br />本システムは、利用者がこの規約に同意していただいていることを前提に提供しているため、利用者は、利用の前に必ずこの規約をご確認いただくとともに、この規約に同意いただけない場合いは、本システムの利用をお断りします。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">4.利用時間</h2>
          <p>
            本システムの利用時間は、原則として24時間です。ただし、緊急の保守・点検を行う場合、本システムの一部又は全部を停止することがあります。本システムの運用停止を行う場合は、本システムのトップページで事前にお知らせしますが、本市が特に必要と認める場合には、予告なしに停止することがあります。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">5.個人情報の保護</h2>
          <p>
            利用者が、本システムを利用する際に入力した個人情報については、「個人情報の保護に関する法律」に基づき、個人情報の漏洩、滅失及び損傷の防止、その他の安全確保の措置を行うこととします。詳細は「プライバシーポリシー」をご参照ください。また、利用者が本システムにおいて他人のプライバシーの侵害をする行為をしてはなりません。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">6.予約番号の管理</h2>
          <p>
            本システムの利用に際し取得した予約番号は、利用者のデータ保護に不可欠なものです。利用者自らが責任を持って管理してください。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">7.禁止事項</h2>
          <p>
            本システムの利用においては、次の行為を禁止します。なお、本市は、利用者が次の行為のいずれかに該当すると認められる行為を行ったとき、又は行おうとしたことが認めれられるときは、その予約等を予告なしに抹消する等、必要な措置を講じることができることとします。
          </p>
          <div class="box">
            <ol>
              <li>本システムを施設の予約等手続き以外の目的で利用すること。</li>
              <li>本システムに対し、不正にアクセスすること。</li>
              <li>
                本市システムの管理及び運営を故意に妨害し、又は本システムを破壊すること。
              </li>
              <li>
                本システムに対し、ウイルスに感染したファイルを送信すること。
              </li>
              <li>
                他の利用者の予約番号を不正に入手すること。また、これらを使用すること。
              </li>
              <li>本人以外の人物又は団体を名乗ること。</li>
              <li>その他法令等に反すると認めれられる行為をすること。</li>
              <li>
                その他、本システムの円滑な運用を阻害するような行為をすること。
              </li>
            </ol>
          </div>
        </section>
        <section class="list">
          <h2 class="ttl">8.免責事項</h2>
          <p>
            本市及び施設管理者等は、次の事項については、その責を負わないものとします。
          </p>
          <div class="box">
            <ol>
              <li>
                利用者が本サービスを利用したことにより、利用者が受けた損害及び利用者が第三者に与えた損害。
              </li>
              <li>
                本システムの停止、休止、中断その他本システムの利用ができなかったことにより、利用者が受けた損害及び利用者が第三者に与えた損害。
              </li>
            </ol>
          </div>
        </section>
        <section class="list">
          <h2 class="ttl">9.コンテンツの保護</h2>
          <p>
            本システムに含まれるプログラム及びその他コンテンツは著作権及びその他の知的財産権に関する法律及び条約によって保護されています。本システムに含まれているプログラム及びコンテンツを無断で修正、複製、改ざん、頒布、販売すること等は禁じられています。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">10.リンクについて</h2>
          <div class="box">
            <ol>
              <li>
                <span class="mb-2 d-block"
                  >本システムから他ホームページへのリンク</span
                >
                <p>
                  リンク先の内容及び団体等については、本市の管理下にはないものです。従ってそれらについては、本市はいかなる責任を負わないものとします。またサイトの内容はそのサイトの管理者などに帰属し、本市とは関係ないものとします。
                </p>
              </li>
              <li>
                <span class="mb-2 d-block"
                  >他ホームページから本システムへのリンク</span
                >
                <p>
                  原則リンクはフリーです。<br />
                  ただし、リンク元の内容及び団体等について本市はいかなる責任を負わないものとします。またサイトの内容はそのサイトの管理者などに帰属し、本市とは関係ないものとします。
                </p>
                <ul class="noteList">
                  <li>
                    掲載期限の経過などにより、該当ページが予告なく非公開となったり、URLが変更となる場合がありますので、ご了承ください。
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </section>
        <section class="list">
          <h2 class="ttl">11.利用規約の変更</h2>
          <p>
            本市は、必要に応じて利用者に対し事前に通知することなく、この利用規約を変更する場合があります。利用規約変更後に本システムを利用した場合は、変更後の利用規約に同意したものとみなされます。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">12.その他</h2>
          <p>
            この規約に定めるもののほか、必要な事項は、本市が別に定めるものとします。
          </p>
        </section>
        <section class="list">
          <h2 class="ttl">別表</h2>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>施設名称</th>
                <th>所管課</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">1</td>
                <td>井川オートキャンプ場</td>
                <td rowspan="5">経済局農林水産部<br />中山間地振興課</td>
              </tr>
              <tr>
                <td class="text-center">2</td>
                <td>黒川キャンプ場</td>
              </tr>
              <tr>
                <td class="text-center">3</td>
                <td>白樺荘</td>
              </tr>
              <tr>
                <td class="text-center">4</td>
                <td>梅ケ島コンヤの里レクリエーション施設<br />（テニス場）</td>
              </tr>
              <tr>
                <td class="text-center">5</td>
                <td>やませみの湯</td>
              </tr>
              <tr>
                <td class="text-center">6</td>
                <td>梅ケ島キャンプ場</td>
                <td rowspan="2">観光交流文化局<br />スポーツ振興課</td>
              </tr>
              <tr>
                <td class="text-center">7</td>
                <td>玉川キャンプセンター</td>
              </tr>
              <tr>
                <td class="text-center">8</td>
                <td>井川湖渡船</td>
                <td>市民局井川支所</td>
              </tr>
              <tr>
                <td class="text-center">9</td>
                <td>南アルプスユネスコエコパーク 井川自然の家</td>
                <td>井川自然の家<br />（教育局教育総務課）</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'Terms',

  pageOptions: {
    routeConfig: {
      path: '/terms',
    },
    props: {
      title: '利用規約',
    },
  },
});
</script>
