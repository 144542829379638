import { v4 as uuidv4 } from 'uuid';

const pageTabKey = 'pageTabId';
const pageTabIdMinLength = 10;

/**
 * ページタブIdを取得する
 * ない場合は新規に生成しセッションストレージにセットしてから返す
 * @returns
 */
export function getPageTabId(): string {
  const pageTabId = window.sessionStorage.getItem(pageTabKey);
  if (pageTabId && pageTabId.length >= pageTabIdMinLength) return pageTabId;
  // ない場合または短い場合は再生成する
  const generatedId = generatePageTabId();
  return generatedId;
}

/**
 * ページタブIdを生成する
 * (自動でセッションストレージにセットされる)
 * @returns
 */
export function generatePageTabId(): string {
  const newId = uuidv4();
  setPageTabId(newId);
  return newId;
}

/**
 * ページタブIdをセットする
 * @param id
 * @returns
 */
export function setPageTabId(id: string): string {
  if (!id || id.length < pageTabIdMinLength) {
    throw new Error('設定するページIdがありません。');
  }
  window.sessionStorage.setItem(pageTabKey, id);
  return id;
}
