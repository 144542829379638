import Vue from 'vue';
import { PluginObject } from 'vue/types/umd';

import * as constants from '@api/constants';

const plugin: PluginObject<void> = {
  install: function (Vue) {
    Vue.prototype.$constants = constants;
  },
};

Vue.use(plugin);
