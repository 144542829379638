<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">氏名</v-col>
          <v-col cols="12" md="9">{{ value?.name }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">ふりがな</v-col>
          <v-col cols="12" md="9">{{ value?.kana }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">郵便番号</v-col>
          <v-col cols="12" md="9">{{ value?.zipCode }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">住所</v-col>
          <v-col cols="12" md="9">{{ value?.address }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">TEL</v-col>
          <v-col cols="12" md="9">{{ value?.tel }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">E-mail</v-col>
          <v-col cols="12" md="9">{{ value?.email }}</v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { CartBook } from '@web-t/store/cart';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CartBook',

  props: {
    value: {
      type: Object as PropType<CartBook>,
      default: undefined,
    },
  },
});
</script>
