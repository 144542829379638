import {
  GetAllFacilitiesRequest,
  GetAllFacilitiesResponse,
  GetScopedFacilityRequest,
  GetScopedFacilityResponse,
} from '@api-i/routes/facility/facility';
import {
  GetAllMailConfigsRequest,
  GetAllMailConfigsResponse,
  GetScopedMailConfigRequest,
  GetScopedMailConfigResponse,
} from '@api-i/routes/mailConfig/mailConfig';
import {
  GetAllPurposesRequest,
  GetAllPurposesResponse,
} from '@api-i/routes/purpose/purpose';
import {
  GetAllReportsRequest,
  GetAllReportsResponse,
  GetScopedReportRequest,
  GetScopedReportResponse,
} from '@api-i/routes/report/report';
import {
  GetAllUnitsRequest,
  GetAllUnitsResponse,
  GetScopedUnitRequest,
  GetScopedUnitResponse,
} from '@api-i/routes/unit/unit';
import { Facility, MailConfig, Purpose, Report, Unit } from '@api/models';
import Vue from 'vue';
import { handleApiError, handleUnknownError } from './error-handler';

/**
 * 施設マスタをDBから読み込む
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns 施設データの配列
 */
export async function loadFacilities(
  instance: Vue,
  options?: { scope?: string },
): Promise<Facility[]> {
  const { scope = undefined } = options || {};

  try {
    const result = await instance.$api<
      GetAllFacilitiesResponse,
      GetAllFacilitiesRequest
    >({
      path: '/facility',
      method: 'get',
      params: {
        scope: scope,
      },
    });

    return result.facilities;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}

/**
 * 施設マスタをキャッシュから読み込む
 */
export async function loadCacheFacilities(
  instance: Vue,
  options?: { scope?: string },
): Promise<Facility[]> {
  return await instance.$cache.get({
    key: 'master_facility',
    exec: async () => {
      const facilities = await loadFacilities(instance, options);
      return facilities;
    },
  });
}

/**
 * 施設マスタをDBから読み込む
 * @param facilityId 施設ID
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns 施設データまたはNULL
 */
export async function loadFacility(
  facilityId: string,
  instance: Vue,
  options?: { scope?: string },
): Promise<Facility | null> {
  const { scope = undefined } = options || {};

  try {
    const result = await instance.$api<
      GetScopedFacilityResponse,
      GetScopedFacilityRequest
    >({
      path: '/facility/' + facilityId,
      method: 'get',
      params: {
        scope: scope,
      },
    });

    return result.facility;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}

/**
 * 区画種類マスタをDBから読み込む
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns 区画種類データの配列
 */
export async function loadUnits(
  instance: Vue,
  options?: {
    scope?: string;
    facilityId?: string;
  },
): Promise<Unit[]> {
  const { scope = undefined, facilityId } = options || {};

  try {
    const result = await instance.$api<GetAllUnitsResponse, GetAllUnitsRequest>(
      {
        path: '/unit',
        method: 'get',
        params: {
          scope: scope,
          facilityId: facilityId,
        },
      },
    );

    return result.units;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}

/**
 * 区画種類マスタをキャッシュから読み込む
 */
export async function loadCacheUnits(
  instance: Vue,
  options?: { scope?: string },
): Promise<Unit[]> {
  return await instance.$cache.get({
    key: 'master_unit',
    exec: async () => {
      const units = await loadUnits(instance, options);
      return units;
    },
  });
}

/**
 * 区画種類マスタをDBから読み込む
 * @param unitId 区画種類ID
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns 区画種類データ
 */
export async function loadUnit(
  unitId: string,
  instance: Vue,
  options?: { scope?: string },
): Promise<Unit | null> {
  const { scope = undefined } = options || {};

  try {
    const result = await instance.$api<
      GetScopedUnitResponse,
      GetScopedUnitRequest
    >({
      path: '/unit/' + unitId,
      method: 'get',
      params: {
        scope: scope,
      },
    });

    return result.unit;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}

/**
 * 利用目的マスタをDBから読み込む
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns 利用目的データの配列
 */
export async function loadPurposes(
  instance: Vue,
  options?: { scope?: string },
): Promise<Purpose[]> {
  const { scope = undefined } = options || {};

  try {
    const result = await instance.$api<
      GetAllPurposesResponse,
      GetAllPurposesRequest
    >({
      path: '/purpose',
      method: 'get',
      params: {
        scope: scope,
      },
    });

    return result.purposes;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}

/**
 * 利用目的マスタをキャッシュから読み込む
 */
export async function loadCachePurposes(
  instance: Vue,
  options?: { scope?: string },
): Promise<Purpose[]> {
  return await instance.$cache.get({
    key: 'master_purpose',
    exec: async () => {
      const units = await loadPurposes(instance, options);
      return units;
    },
  });
}

/**
 * 帳票マスタをDBから読み込む
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns 帳票データの配列
 */
export async function loadReports(
  instance: Vue,
  options?: { scope?: string; facilityId?: string },
): Promise<Report[]> {
  const { scope = undefined, facilityId } = options || {};

  try {
    const result = await instance.$api<
      GetAllReportsResponse,
      GetAllReportsRequest
    >({
      path: '/report',
      method: 'get',
      params: {
        scope: scope,
        facilityId: facilityId,
      },
    });

    return result.reports;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}

/**
 * 帳票マスタをキャッシュから読み込む
 */
export async function loadCacheReports(
  instance: Vue,
  options?: { scope?: string },
): Promise<Report[]> {
  return await instance.$cache.get({
    key: 'master_report',
    exec: async () => {
      const reports = await loadReports(instance, options);
      return reports;
    },
  });
}

/**
 * 帳票マスタをDBから読み込む
 * @param reportId 帳票ID
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns 帳票データ
 */
export async function loadReport(
  reportId: string,
  instance: Vue,
  options?: { scope?: string },
): Promise<Report | null> {
  const { scope = undefined } = options || {};

  try {
    const result = await instance.$api<
      GetScopedReportResponse,
      GetScopedReportRequest
    >({
      path: '/report/' + reportId,
      method: 'get',
      params: {
        scope: scope,
      },
    });

    return result.report;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}

/**
 * メール設定マスタをDBから読み込む
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns メール設定データの配列
 */
export async function loadMailConfigs(
  instance: Vue,
  options?: { scope?: string; facilityId?: string },
): Promise<MailConfig[]> {
  const { scope = undefined, facilityId } = options || {};

  try {
    const result = await instance.$api<
      GetAllMailConfigsResponse,
      GetAllMailConfigsRequest
    >({
      path: '/mailConfig',
      method: 'get',
      params: {
        scope: scope,
        facilityId: facilityId,
      },
    });

    return result.mailConfigs;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}

/**
 * メール設定マスタをキャッシュから読み込む
 */
export async function loadCacheMailConfigs(
  instance: Vue,
  options?: { scope?: string },
): Promise<MailConfig[]> {
  return await instance.$cache.get({
    key: 'master_mailConfig',
    exec: async () => {
      const mailConfigs = await loadMailConfigs(instance, options);
      return mailConfigs;
    },
  });
}

/**
 * メール設定マスタをDBから読み込む
 * @param mailConfigId メール設定ID
 * @param instance Vueインスタンス
 * @param options scopeなどを設定
 * @returns メール設定データ
 */
export async function loadMailConfig(
  mailConfigId: string,
  instance: Vue,
  options?: { scope?: string },
): Promise<MailConfig | null> {
  const { scope = undefined } = options || {};

  try {
    const result = await instance.$api<
      GetScopedMailConfigResponse,
      GetScopedMailConfigRequest
    >({
      path: '/mailConfig/' + mailConfigId,
      method: 'get',
      params: {
        scope: scope,
      },
    });

    return result.mailConfig;
  } catch (error) {
    if (
      !handleApiError(error, instance, {
        prefix: ['データの取得に失敗しました。下記内容を確認してください。'],
      })
    ) {
      handleUnknownError(error, instance);
    }
    throw error;
  }
}
