import { ConstantStringUtility } from '@c-t/util/stringUtility';

export const stringUtility: ConstantStringUtility = {
  convertMessage(expr, scope) {
    if (!expr) {
      return '';
    }

    const properties = Object.keys(scope);

    const convertedMessage = expr.replace(/\b(\w+(?:\.\w+)*)\b/g, (match) => {
      if (properties.includes(match)) {
        return scope[match];
      } else {
        return match;
      }
    });

    return convertedMessage;
  },
  formatString(text, convertList) {
    return text.replace(/{\$(\d+)}/g, function (match, number) {
      return convertList[number - 1] || match;
    });
  },
  fullToHalf(text) {
    return text.replace(/[！-～]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  },
  isJson(text) {
    try {
      JSON.parse(text);
    } catch (e) {
      return false;
    }
    return true;
  },
  toString(text) {
    let result: any = text;
    if (stringUtility.isJson(text)) {
      result = JSON.parse(text);
    }
    if (
      result === undefined ||
      result === null ||
      typeof result === 'boolean'
    ) {
      return '';
    }
    result = result.toString();
    return result;
  },
};
