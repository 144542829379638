import { ConstantDaysOfWeek } from '@api-t/constants/daysOfWeek';

export const daysOfWeek: ConstantDaysOfWeek = {
  items: [
    { text: '月曜日', value: 1 },
    { text: '火曜日', value: 2 },
    { text: '水曜日', value: 3 },
    { text: '木曜日', value: 4 },
    { text: '金曜日', value: 5 },
    { text: '土曜日', value: 6 },
    { text: '日曜日', value: 0 },
  ],
};
