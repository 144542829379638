<template>
  <div class="lContainer -sub -reserve">
    <div class="mContainer -flowCont">
      <div class="inner">
        <ReserveFlow :value="0" />
      </div>
    </div>
    <CartEditable>
      <template #top>
        <section class="inner">
          <h1 class="contTtl">予約カート</h1>
          <div
            v-if="cartIsEmpty"
            class="py-4 text-center grey--text text--darken-4 cartEmptyTxt"
          >
            予約カートは空です。
          </div>
        </section>
      </template>
      <template #bottom>
        <section class="inner">
          <v-card-actions class="btnArea -list">
            <v-btn
              depressed
              small
              outlined
              to="/search"
              class="btnBasic -back -order2"
            >
              戻る
            </v-btn>
            <v-btn
              depressed
              small
              class="btnBasic -order1"
              :disabled="cartIsEmpty"
              @click="validate"
            >
              予約へ進む
            </v-btn>
          </v-card-actions>
        </section>
      </template>
    </CartEditable>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { isEmpty } from 'lodash';
import { mapState, mapGetters } from '@web-e/store/cart';
import ReserveFlow from '@web-e/components/ReserveFlow.vue';
import CartEditable from '@web-e/components/cart/CartEditable.vue';
import {
  ValidateBookRequest,
  ValidateBookResponse,
} from '@api-e/routes/book/book';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';

export default Vue.extend({
  name: 'ReserveCart',

  pageOptions: {
    routeConfig: {
      path: '/reserveCart',
    },
    props: {
      title: '予約カート',
    },
  },

  components: {
    ReserveFlow,
    CartEditable,
  },

  computed: {
    /**
     * 予約カートが空か否か
     */
    cartIsEmpty(): boolean {
      return isEmpty(this.details);
    },

    ...mapState(['details']),
    ...mapGetters(['booksToSave']),
  },

  methods: {
    async validate() {
      try {
        // 事前にバリデーション
        const result = await this.$api<
          ValidateBookResponse,
          ValidateBookRequest
        >({
          path: '/book/validate',
          method: 'post',
          params: {
            books: this.booksToSave,
          },
        });
        if (result) {
          this.$router.push('/reserveInput');
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['入力内容に不備があります。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
        }
      }
    },
  },
});
</script>
