<template>
  <div class="priceAll">
    <div class="priceAll__txt">お支払い総計 (税込)</div>
    <div class="priceAll__price">{{ amount.toLocaleString() }}円</div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'CartPriceAll',

  props: {
    amount: {
      type: Number,
      required: true,
    },
  },
});
</script>
