import { ConstantFeeCalculation } from '@api-t/constants/feeCalculation';
import { evaluate } from 'mathjs';
import { schemaOperation } from './schemaOperation';
import { scopeDistribution } from './scopeDistribution';
import { taxType } from './taxType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const evaluate_ = (expr: any, scope: object | undefined): any => {
  try {
    return evaluate(expr, scope);
  } catch (error) {
    return 0;
  }
};

export const feeCalculation: ConstantFeeCalculation = {
  calcTaxAmount(detail) {
    let result = 0;
    if (detail.taxType === taxType.included.value) {
      // 内税
      result = evaluate_('amount', detail);
    } else if (detail.taxType === taxType.excluded.value) {
      // 外税
      const expr = `${detail.roundingType}(amount * (100 + taxRate) / 100)`;
      result = evaluate_(expr, detail);
    }

    return Number(result) || 0;
  },
  calcAmount(detail, unitDict, holidays) {
    const { startDate, unitId } = detail;
    if (!startDate) {
      return 0;
    }
    const unit = unitDict[unitId];
    if (!unit) {
      return 0;
    }
    const { fees } = unit;
    if (!fees) {
      return 0;
    }
    const result = fees.reduce((acc, fee) => {
      // 料金を計算する際はシステム専用のプロパティを注入する
      const scope = scopeDistribution.exec(detail);
      const { formula, taxRate, taxType, roundingType, schema_ } = fee;
      if (!schemaOperation.eval(schema_, scope, { holidays })) {
        return acc;
      }
      const amount = evaluate_(formula, scope);
      const amount_ = feeCalculation.calcTaxAmount({
        amount,
        taxRate,
        taxType,
        roundingType,
      });
      return acc + amount_;
    }, 0);
    return result;
  },
};
