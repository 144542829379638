import { RequestBook } from '@api-e/routes/book/book';
import {
  StoreCartActions,
  StoreCartGetters,
  StoreCartMutations,
  StoreCartState,
} from '@web-t/store/cart';
import { StoreRootExternal } from '@web-t/store/root';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';
import { chain } from 'lodash';

const state: StoreCartState = {
  books: null,

  book: null,
  details: {},
  options: {},

  readonly: true,
};

const getters: DefineGetters<StoreCartGetters, StoreCartState> = {
  /**
   * 登録する予約データ
   */
  booksToSave(state) {
    const result = chain(state.details)
      .mapValues((details, facilityId) => {
        const options = chain(state.options[facilityId])
          .values()
          .flatten()
          .value();
        const result_ = {
          ...state.book,
          facilityId,
          details: [...details, ...options],
        } as RequestBook;

        return result_;
      })
      .values()
      .value();

    return result;
  },
};

const mutations: DefineMutations<StoreCartMutations, StoreCartState> = {
  setBooks(state, payload) {
    state.books = payload;
  },

  setBook(state, payload) {
    state.book = payload;
  },
  addDetail(state, { facilityId, item }) {
    state.details = {
      ...state.details,
      [facilityId]: [...(state.details[facilityId] || []), item],
    };
  },
  setDetails(state, payload) {
    state.details = payload;
  },
  setOptions(state, payload) {
    state.options = payload;
  },
  clearEdits(state) {
    state.book = null;
    state.details = {};
    state.options = {};
  },

  setReadonly(state, payload) {
    state.readonly = payload;
  },
};

const actions: DefineActions<
  StoreCartActions,
  StoreCartState,
  StoreCartMutations,
  StoreCartGetters
> = {
  setBooks({ commit }, payload) {
    commit('setBooks', payload);
  },

  setBook({ commit }, payload) {
    commit('setBook', payload);
  },
  addDetail({ commit }, payload) {
    commit('addDetail', payload);
  },
  setDetails({ commit }, payload) {
    commit('setDetails', payload);
  },
  setOptions({ commit }, payload) {
    commit('setOptions', payload);
  },
  clearEdits({ commit }) {
    commit('clearEdits');
  },

  setReadonly({ commit }, payload) {
    commit('setReadonly', payload);
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreCartState,
    StoreCartGetters,
    StoreCartMutations,
    StoreCartActions
  >('cart');

const storeModule: Module<StoreCartState, StoreRootExternal> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<StoreCartState, StoreRootExternal>,
};

export default storeModule;
