<template>
  <ul class="flow">
    <li
      v-for="(item, i) in items"
      :key="i"
      class="pcShow"
      :class="i === value ? 'current' : ''"
    >
      <span class="pcShow">{{ String(i + 1).padStart(2, '0') }}&ensp;</span>
      <div
        class="spShow c100 small"
        :class="`p${Math.round(((i + 1) * 100) / items.length)}`"
      >
        <span class="number">
          <strong>{{ i + 1 }}</strong
          >/{{ items.length }}
        </span>
        <div class="slice">
          <div class="bar"></div>
          <div class="fill"></div>
        </div>
      </div>
      <p class="txt mb-0">{{ item }}</p>
    </li>
  </ul>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'ReserveFlow',

  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    items: ['予約カート', 'お客様情報の入力', 'ご予約内容の確認', 'ご予約完了'],
  }),
});
</script>
