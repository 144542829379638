import { StoreRootExternal, StoreRootInternal } from '@web-t/store/root';
import {
  SnackbarProps,
  SnackbarShortProps,
  StoreSnackbarActions,
  StoreSnackbarGetters,
  StoreSnackbarMutations,
  StoreSnackbarState,
} from '@web-t/store/snackbar';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';

const state: StoreSnackbarState = {
  // メッセージ
  messages: [],
};

const getters: DefineGetters<StoreSnackbarGetters, StoreSnackbarState> = {};

const mutations: DefineMutations<StoreSnackbarMutations, StoreSnackbarState> = {
  /**
   * メッセージを登録する
   * @param state
   * @param payload
   */
  register(state, payload: SnackbarProps) {
    state.messages.push(payload);
  },
};

const actions: DefineActions<
  StoreSnackbarActions,
  StoreSnackbarState,
  StoreSnackbarMutations,
  StoreSnackbarGetters
> = {
  /**
   * メッセージを登録する
   * @param param0
   * @param payload
   * @returns
   */
  async register({ commit }, payload: SnackbarShortProps) {
    let result: SnackbarProps = {};
    if (payload.type) {
      switch (payload.type) {
        case 'success': {
          result = {
            color: 'success',
            timeout: 2 * 1000,
          };
          break;
        }
        case 'error': {
          result = {
            color: 'error',
            timeout: 10 * 1000,
          };
        }
      }
    }
    result = {
      ...result,
      ...payload,
    };

    commit('register', result);
    return payload;
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreSnackbarState,
    StoreSnackbarGetters,
    StoreSnackbarMutations,
    StoreSnackbarActions
  >('snackbar');

const storeModule: Module<
  StoreSnackbarState,
  StoreRootExternal | StoreRootInternal
> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<
    StoreSnackbarState,
    StoreRootExternal | StoreRootInternal
  >,
};

export default storeModule;
