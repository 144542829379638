<template>
  <div class="lContainer -sub -reserve">
    <div class="mContainer -flowCont">
      <div class="inner">
        <ReserveFlow :value="3" />
      </div>
    </div>
    <div class="mContainer -reserveCont">
      <section class="CompleteCont inner">
        <h1>ご予約{{ operation.append }}完了</h1>
        <p class="readTxt mb-0">
          予約{{ operation.append }}が完了いたしました。
          <br />
          メールアドレスに{{
            operation.mail
          }}メールを送信いたしました。ご確認ください。
        </p>
        <div class="reserveNumberCont">
          <p class="numTtl">受付番号</p>
          <div class="reserveNumber">
            <div
              v-for="book in books"
              :key="book.id"
              class="reserveNumber__item"
            >
              <h3>
                {{ facilityDict[book.facilityId]?.name }}
              </h3>
              <div
                v-for="detail in book.details"
                :key="detail.id"
                class="detail"
              >
                <template v-if="!unitDict[detail.unitId]?.isOption">
                  <p class="facility mb-0">
                    {{ unitDict[detail.unitId]?.name }}
                  </p>
                  <time class="time">
                    {{ detail.startDate }}
                  </time>
                </template>
              </div>
              <p class="number mb-0">
                {{ book.receiptNo }}
              </p>
            </div>
          </div>
        </div>
        <v-card-actions class="btnArea -list">
          <v-btn depressed small to="/" class="btnBasic -back">
            TOPページへ戻る
          </v-btn>
        </v-card-actions>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ConstantBookOperationItem,
  ConstantBookOperationKey,
} from '@api-t/constants/bookOperation';
import { bookOperation } from '@api/constants/bookOperation';
import { Facility, Unit } from '@api/models';
import ReserveFlow from '@web-e/components/ReserveFlow.vue';
import { mapState } from '@web-e/store/cart';
import {
  loadCacheFacilities,
  loadCacheUnits,
} from '@web/modules/master-loader';
import { chain } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'ReserveComplete',

  pageOptions: {
    routeConfig: {
      path: '/reserveComplete',
    },
    props: {
      title: '予約完了',
    },
  },

  components: {
    ReserveFlow,
  },

  data: () => ({
    // 施設マスタ
    facilities: [] as Facility[],

    //区画種類マスタ
    units: [] as Unit[],
  }),

  computed: {
    facilityDict(): Record<string, Facility> {
      return chain(this.facilities).keyBy('id').value();
    },

    unitDict(): Record<string, Unit> {
      return chain(this.units).keyBy('id').value();
    },

    operation(): ConstantBookOperationItem {
      const value = this.$route.query.operation || bookOperation.create.value;
      const result = bookOperation[value as ConstantBookOperationKey];

      return result;
    },

    ...mapState(['books']),
  },

  async mounted() {
    await Promise.all([this.loadFacility(), this.loadUnit()]);
  },

  methods: {
    // 施設マスタを読み込む
    async loadFacility() {
      const facilities = await loadCacheFacilities(this);
      this.$set(this, 'facilities', facilities);
    },

    // 区画種類マスタを読み込む
    async loadUnit() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },
  },
});
</script>
