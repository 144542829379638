<template>
  <div class="lContainer -top">
    <!-- メインビジュアル・検索フォーム -->
    <div class="mContainer -mailVisual">
      <h1 class="mainImg">
        <div class="pcShow">
          <v-img
            src="../../assets/img/top/mainvisual_pc.jpg"
            alt="オクシズを楽しもう"
          ></v-img>
        </div>
        <div class="spShow">
          <v-img
            src="../../assets/img/top/mainvisual_sp.jpg"
            alt="オクシズを楽しもう"
          ></v-img>
        </div>
      </h1>
      <section class="inner searchFoamCont">
        <div class="searchFoam">
          <div class="inputCont">
            <SearchForm :purposes="purposes" :facilities="facilities" />
          </div>
          <div class="actionCont">
            <v-card-actions>
              <v-btn to="/search" depressed small class="btnBasic">
                <v-icon>mdi-magnify</v-icon>
                検索する
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </section>
    </div>

    <!-- 重要なお知らせ -->
    <div class="mContainer -newsAlert">
      <section class="inner">
        <router-link
          v-for="em in emergencyNotices"
          :key="em.id"
          :to="{ path: '/news', query: { id: em.id } }"
          class="my-1"
        >
          {{ em.subject }}
        </router-link>
      </section>
    </div>

    <!-- オクシズとは -->
    <HomeAbout />

    <!-- お知らせ -->
    <HomeNotice :items="notices" />

    <!-- 利用目的から予約する -->
    <HomePurpose :items="purposes" />

    <!-- 施設から予約する -->
    <HomeFacility :items="facilities" />

    <!-- ご利用方法 -->
    <HomeGuide />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import HomeAbout from './about.vue';
import HomePurpose from './purpose.vue';
import HomeFacility from './facility.vue';
import HomeGuide from './guide.vue';
import HomeNotice from './notice.vue';
import {
  GetNoticeRequest,
  GetNoticeResponse,
} from '@api-e/routes/notice/notice';
import { Facility, NoticeForUser, Purpose } from '@api/models';
import {
  loadCacheFacilities,
  loadCachePurposes,
} from '@web/modules/master-loader';
import SearchForm from '@web-e/components/SearchForm.vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'Home',

  pageOptions: {
    routeConfig: {
      path: '/',
    },
    props: {
      title: 'ホーム',
    },
  },

  components: {
    SearchForm,
    HomeAbout,
    HomePurpose,
    HomeFacility,
    HomeGuide,
    HomeNotice,
  },

  data: () => ({
    purposes: [] as Purpose[],
    facilities: [] as Facility[],
    notices: [] as NoticeForUser[],
  }),

  computed: {
    // 重要なお知らせを返す
    emergencyNotices(): NoticeForUser[] {
      return this.notices.filter((n) => n.isEmergency);
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.clearSearch();
      await Promise.all([
        this.loadPurpose(),
        this.loadFacility(),
        this.loadNotices(),
      ]);
    },

    // 目的を読み込み
    async loadPurpose() {
      const purposes = await loadCachePurposes(this);
      this.$set(this, 'purposes', purposes);
    },

    // 施設を読み込み
    async loadFacility() {
      const facilities = await loadCacheFacilities(this);
      this.$set(this, 'facilities', facilities);
    },

    // お知らせを読み込み
    async loadNotices() {
      const result = await this.$api<GetNoticeResponse, GetNoticeRequest>({
        path: '/notice',
        method: 'get',
        params: {},
      });
      this.$set(this, 'notices', result.result);
    },

    ...mapActions('search', ['clearSearch']),
  },
});
</script>
