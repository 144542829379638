<template>
  <div class="tableWeek">
    <table>
      <tbody>
        <tr>
          <th class="text-left fixed"></th>
          <th v-for="(w, i) in weeklyTableHeader" :key="i">
            <span class="week" :class="w.style">
              {{ w.label }}
            </span>
            <span class="day">
              {{ w.text }}
            </span>
          </th>
        </tr>
        <tr v-for="unit in item.units" :key="unit.id">
          <td class="text-left fixed">
            {{ unit.name }}
          </td>
          <availability
            v-for="(day, j) in unit.days"
            :key="day.date"
            :ratio="day.ratio"
            :remain="
              unit.type === rentalType.frame.value
                ? day.max - day.sum
                : undefined
            "
            :type="day.type"
            @click="
              $emit('click:cell', {
                facilityId: item.id,
                unitId: unit.id,
                day: day,
                col: j,
              })
            "
          ></availability>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import {
  DailyAvailability,
  WeeklyAvailability,
} from '@api-i/routes/availability/availability';
import { daysOfWeek, rentalType } from '@api/constants';
import Availability from './Availability.vue';
import Vue, { PropType } from 'vue';

export interface ClickWeeklyCellEventData {
  facilityId: string;
  unitId: string;
  day: DailyAvailability;
  col: number;
}

export default Vue.extend({
  name: 'WeeklyAvailabilityTable',

  components: {
    Availability,
  },

  props: {
    dates: {
      type: Array as PropType<Date[]>,
      required: true,
    },

    item: {
      type: Object as PropType<WeeklyAvailability>,
      required: true,
    },
  },

  computed: {
    /**
     * 週次の各曜日に対する日付
     */
    weeklyTableHeader() {
      return this.dates.map((date, index) => {
        const w = date.getDay();
        const m = date.getMonth() + 1;
        const d = date.getDate();

        const style = w === 6 ? 'txtBlue' : w === 0 ? 'txtRed' : '';
        const label = daysOfWeek.items[(w + 6) % 7].text.charAt(0);
        let text = String(d);
        if (index === 0 || d === 1) {
          text = m + '/' + d;
        }
        return { style, label, text };
      });
    },

    rentalType() {
      return rentalType;
    },
  },
});
</script>
