<template>
  <v-expansion-panels v-if="count > 0" v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span>
          お知らせ
          <v-chip color="red" dark>{{ count }}</v-chip>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-5">
        <div v-for="item in items" :key="item.id">
          <v-expansion-panels accordion class="accordion-newsDetail">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-chip
                  v-if="item.isEmergency"
                  color="red"
                  dark
                  class="d-inline"
                >
                  重要
                </v-chip>
                <span class="ml-3">
                  {{ item.subject }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <mavon-editor
                  v-model="item.body"
                  language="ja"
                  default-open="preview"
                  :toolbars-flag="false"
                  :subfield="false"
                  class="markdown-editor"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import {
  GetNoticeRequest,
  GetNoticeResponse,
} from '@api-e/routes/notice/notice';
import { Facility, NoticeForUser } from '@api/models';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'SearchNews',

  props: {
    facility: {
      type: Object as PropType<Facility>,
      required: true,
    },
  },

  data: () => ({
    items: [] as NoticeForUser[],
    panel: 0,
  }),

  computed: {
    count(): number {
      return this.items.length;
    },
    hasEmergency(): boolean {
      return this.items.some((v) => v.isEmergency);
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await Promise.all([this.loadNotice()]);
    },

    async loadNotice() {
      const result = await this.$api<GetNoticeResponse, GetNoticeRequest>({
        path: '/notice',
        method: 'get',
        params: {
          facilityId: this.facility.id,
        },
      });

      this.items = result.result;
    },
  },
});
</script>
