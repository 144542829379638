<template>
  <v-container>
    <v-row>
      <!-- 氏名 -->
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">
            氏名
            <span class="required">必須</span>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="data.name"
              :rules="rules.name"
              required
              outlined
              dense
              placeholder="全角（例：静岡太郎）"
              autocomplete="name"
              name="name"
              @input="onInput($event, 'name')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- ふりがな -->
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">
            ふりがな
            <span class="required">必須</span>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="data.kana"
              :rules="rules.kana"
              required
              outlined
              dense
              placeholder="全角（例：しずおかたろう）"
              autocomplete="name"
              name="kana"
              @input="onInput($event, 'kana')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- 郵便番号 -->
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">
            郵便番号
            <span class="required">必須</span>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="data.zipCode"
              :rules="rules.zipCode"
              required
              outlined
              dense
              placeholder="420-8602"
              autocomplete="postal-code"
              name="postal-code"
              @input="onInputZipCode"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- 住所 -->
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">
            住所
            <span class="required">必須</span>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="data.address"
              :rules="rules.address"
              required
              outlined
              dense
              hint="番地・建物名まで入力してください。"
              persistent-hint
              placeholder="静岡県静岡市葵区追手町5-1"
              autocomplete="address"
              name="address"
              @input="onInput($event, 'address')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- TEL -->
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">
            TEL
            <span class="required">必須</span>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="data.tel"
              :rules="rules.tel"
              required
              outlined
              dense
              hint="原則、当日ご連絡のつくお電話番号をご記入ください。"
              persistent-hint
              placeholder="090-0000-0000"
              autocomplete="tel"
              name="tel"
              type="tel"
              @input="onInput($event, 'tel')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- E-mail -->
      <v-col cols="12" class="mb-4">
        <v-row>
          <v-col cols="12" md="3">
            E-mail
            <span class="required">必須</span>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="data.email"
              :rules="rules.email"
              required
              outlined
              dense
              placeholder="example@mail.com"
              autocomplete="email"
              name="email"
              type="email"
              @input="onInput($event, 'email')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { validationRule } from '@api/constants';
import AddressSearcher from '@web/modules/address-searcher';
import Vue, { PropType } from 'vue';
import { mapActions, mapState } from '@web-e/store/cart';
import { CartBook } from '@web-t/store/cart';

export default Vue.extend({
  name: 'CartBookEdit',

  props: {
    value: {
      type: Object as PropType<CartBook>,
      default: undefined,
    },
  },

  computed: {
    data: {
      get(): CartBook {
        return this.book || ({} as CartBook);
      },
      set(value: CartBook): void {
        this.setBook(value);
      },
    },

    rules() {
      return validationRule.items;
    },

    ...mapState(['book']),
  },

  methods: {
    onInput(value: string, name: string) {
      this.data = {
        ...this.data,
        [name]: value,
      };
    },

    /**
     * 住所検索
     */
    async onInputZipCode(value: string) {
      if (!AddressSearcher.isZipCodeFormat(value)) {
        this.onInput(value, 'zipCode');
        return;
      }
      const result = await AddressSearcher.fetchAPI(value);
      if (!result) {
        this.onInput(value, 'zipCode');
        return;
      }
      const { address1, address2, address3 } = result;
      this.data = {
        ...this.data,
        zipCode: value,
        address: `${address1}${address2}${address3}`,
      };
    },

    ...mapActions(['setBook']),
  },
});
</script>
