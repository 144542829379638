<template>
  <div class="lContainer -sub -reserve">
    <div v-if="!isLoggedIn" class="mContainer -flowCont">
      <div class="inner">
        <ReserveFlow :value="2" />
      </div>
    </div>
    <template v-if="afterLogin">
      <div class="mContainer -editReadCont py-5">
        <section class="inner">
          <span class="font-weight-black"
            >受付番号：{{ book?.receiptNo }}の変更 / 取消</span
          ><br />
          <p class="mb-0 readTxt">
            ご予約内容をご確認いただき、ページ下部よりご希望手続きをお選びください。<br />
            また、予約変更/取消の中止をする場合は右上の「変更 /
            取消の中止」ボタンをクリックしてください。
          </p>
        </section>
      </div>
    </template>
    <Cart>
      <template #top>
        <section class="inner">
          <h1 class="contTtl">ご予約内容</h1>
        </section>
      </template>

      <template #bottom>
        <section class="inner mt-15">
          <h1 class="contTtl">お客様情報</h1>
        </section>

        <section class="formCont -confirm inner">
          <!-- 本体 -->
          <CartBook :value="book" />
        </section>

        <section class="inner">
          <!-- 操作 -->
          <v-card-actions class="btnArea -list">
            <!-- ログイン直後 -->
            <template v-if="afterLogin">
              <v-btn
                depressed
                outlined
                class="btnBasic -order1 -icoNone"
                :disabled="!!readonly"
                to="/reserveEdit"
              >
                入力内容を変更する
              </v-btn>
              <v-btn
                depressed
                color="red"
                outlined
                class="btnBasic -order2 -icoNone"
                :disabled="!!readonly"
                @click="cancelBook"
              >
                この予約を取り消す
              </v-btn>
              <v-btn
                depressed
                class="btnBasic -back -order2 -icoNone"
                to="/reserveLogin"
              >
                予約の変更・取消を中止
              </v-btn>
            </template>
            <!-- 変更または取消 -->
            <template v-else-if="isLoggedIn">
              <v-btn
                depressed
                small
                outlined
                to="/reserveEdit"
                class="btnBasic -back -order2"
                :disabled="!!readonly"
              >
                入力内容を変更する
              </v-btn>
              <v-btn
                depressed
                small
                class="btnBasic -order1"
                :disabled="!!readonly || !booksToSave.length"
                @click="saveBook"
              >
                この内容で予約する
              </v-btn>
            </template>
            <!-- 通常 -->
            <template v-else>
              <v-btn
                depressed
                small
                outlined
                to="/reserveInput"
                class="btnBasic -back -order2"
              >
                入力内容を変更する
              </v-btn>
              <v-btn
                depressed
                small
                class="btnBasic -order1"
                :disabled="!booksToSave.length"
                @click="saveBook"
              >
                この内容で予約する
              </v-btn>
            </template>
          </v-card-actions>
        </section>
        <section
          v-if="isLoggedIn && typeof readonly === 'string'"
          class="inner"
        >
          <span class="font-weight-light grey--text text--darken-3">
            {{ readonly }}
          </span>
        </section>
      </template>
    </Cart>
  </div>
</template>

<script lang="ts">
import {
  CreateBookRequest,
  CreateBookResponse,
  UpdateBookRequest,
  UpdateBookResponse,
} from '@api-e/routes/book/book';
import { Book, Facility, Unit } from '@api/models';
import CartBook from '@web-e/components/cart/book/CartBook.vue';
import ReserveFlow from '@web-e/components/ReserveFlow.vue';
import {
  mapActions as mapActionsCart,
  mapGetters as mapGettersCart,
  mapState as mapStateCart,
} from '@web-e/store/cart';
import {
  mapActions as mapActionsLoginStatus,
  mapState as mapStateLoginStatus,
} from '@web-e/store/loginStatus';
import { handleApiError, handleUnknownError } from '@web/modules/error-handler';
import { calcApproxAmounts } from '@web/modules/fee-calculator';
import {
  loadCacheFacilities,
  loadCacheUnits,
} from '@web/modules/master-loader';
import { ApproxAmounts } from '@web-t/books';
import { chain } from 'lodash';
import Vue from 'vue';
import Cart from '@web-e/components/cart/Cart.vue';
import { GetLoggedInResponse } from '@api-e/routes/login/login';
import { cartFormat } from '@web/modules/cart-handler';
import HolidaySearcher from '@web/modules/holiday-searcher';

export default Vue.extend({
  name: 'ReserveConfirm',

  pageOptions: {
    routeConfig: {
      path: '/reserveConfirm',
    },
    props: {
      title: 'ご予約内容の確認',
    },
  },

  components: {
    ReserveFlow,
    Cart,
    CartBook,
  },

  data: () => ({
    // 施設マスタ
    facilities: [] as Facility[],

    // 区画種類マスタ
    units: [] as Unit[],

    // 祝日
    holidays: [] as string[],
  }),

  computed: {
    facilityDict(): Record<string, Facility> {
      return chain(this.facilities).keyBy('id').value();
    },

    unitDict(): Record<string, Unit> {
      return chain(this.units).keyBy('id').value();
    },

    /**
     * 全ての概算金額
     */
    amounts(): ApproxAmounts {
      const result = calcApproxAmounts(
        this.details,
        this.options,
        this.unitDict,
        this.holidays,
      );

      return result;
    },

    /**
     * ログイン後か否か
     */
    afterLogin(): boolean {
      return Boolean(this.$route.query.afterLogin);
    },

    ...mapStateCart(['book', 'details', 'options', 'readonly']),
    ...mapGettersCart(['booksToSave']),
    ...mapStateLoginStatus(['isLoggedIn']),
  },

  async mounted() {
    const year = new Date().getFullYear();
    await Promise.all([
      this.loadFacility(),
      this.loadUnit(),
      this.loadHolidays(year),
      this.loadHolidays(year + 1),
    ]);
    await this.init();
  },

  methods: {
    // 施設マスタを読み込む
    async loadFacility() {
      const facilities = await loadCacheFacilities(this);
      this.$set(this, 'facilities', facilities);
    },

    // 区画種類マスタを読み込む
    async loadUnit() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },

    /**
     * 祝日をAPIから取得する
     */
    async loadHolidays(year: number) {
      const holidays = await HolidaySearcher.fetchCacheAPI(year, this);
      this.$set(this, 'holidays', [...this.holidays, ...holidays]);
    },

    async init() {
      if (this.afterLogin) {
        await this.$store.dispatch(
          'loading/register',
          Promise.all([this.loagLoggedIn()]),
        );
      }
    },

    /**
     * ログイン済みデータを取得など
     */
    async loagLoggedIn() {
      try {
        // ログイン済みかどうか取得する
        const result = await this.$api<GetLoggedInResponse>({
          path: '/login/getLoggedIn',
          method: 'post',
          params: {},
        });

        // ログイン済みの場合
        if (result.book) {
          const { book, details, options } = cartFormat(
            result.book,
            this.unitDict,
          );
          this.setBook(book);
          this.setDetails(details);
          this.setOptions(options);

          this.setReadonly(result.readonly);
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: [
              'データの取得に失敗しました。下記内容を確認してください。',
            ],
          })
        ) {
          handleUnknownError(error, this);
        }
        throw error;
      }
    },

    /**
     * 予約を保存する
     */
    async saveBook() {
      // ログインしているかどうかで保存か更新か
      if (this.isLoggedIn) {
        await this.updateBook();
      } else {
        await this.createBook();
      }
    },

    // 予約を新規登録して完了画面へ遷移する
    async createBook() {
      try {
        const result = await this.$api<CreateBookResponse, CreateBookRequest>({
          path: '/book/create',
          method: 'post',
          params: {
            books: this.booksToSave,
          },
        });
        if (result) {
          this.setBooks(result.books);
          this.clearEdits();
          this.$router.push('/reserveComplete');
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['予約に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    // 予約の更新
    async updateBook() {
      try {
        const result = await this.$api<UpdateBookResponse, UpdateBookRequest>({
          path: '/book/update',
          method: 'post',
          params: {
            book: this.booksToSave[0],
          },
        });
        if (result) {
          this.setBooks(this.booksToSave as Book[]);
          this.clearEdits();
          await this.logout();
          this.$router.push({
            path: '/reserveComplete',
            query: { operation: 'update' },
          });
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['予約の更新に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    // ログアウト
    async logout() {
      try {
        await this.$api({
          path: '/login/logout',
          method: 'post',
          params: {},
        });
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['ログアウトに失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
        }
      }

      // ログイン情報をクリア
      await this.setIsLoggedIn(false);
    },

    // 予約の取消
    async cancelBook() {
      if (!confirm('予約を取り消します。よろしいですか？')) {
        return;
      }
      try {
        const result = await this.$api({
          path: '/book/cancel',
          method: 'post',
        });
        if (result) {
          this.clearEdits();
          // ログアウトしてトップページへ
          await this.logout();
          this.$router.push('/');
        }
      } catch (error) {
        if (
          !handleApiError(error, this, {
            prefix: ['予約の取消に失敗しました。下記内容を確認してください。'],
          })
        ) {
          handleUnknownError(error, this);
          throw error;
        }
      }
    },

    ...mapActionsCart([
      'setBooks',
      'setBook',
      'setDetails',
      'setOptions',
      'clearEdits',
      'setReadonly',
    ]),
    ...mapActionsLoginStatus(['setIsLoggedIn']),
  },
});
</script>
