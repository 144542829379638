<template>
  <div class="mContainer -reserveCont">
    <slot name="top"></slot>

    <section class="reserveInner inner">
      <!-- 施設 -->
      <div
        v-for="(details_, facilityId) in details"
        :key="facilityId"
        class="reserveItemCont"
      >
        <h2 class="facility">
          <p class="facility__name mb-0">
            予約施設：{{ facilityDict[facilityId]?.name }}
          </p>
        </h2>
        <div class="reserveDetail">
          <!-- ディテール -->
          <CartDetail
            v-for="(detail, j) in details_"
            :key="j"
            :detail="detail"
            :unit-dict="unitDict"
            :amount="amounts.details[facilityId][j]"
          >
            <template #action>
              <slot name="action.detail" v-bind="{ facilityId, index: j }" />
            </template>
          </CartDetail>

          <!-- オプション -->
          <CartOption
            :facility-id="facilityId"
            :options="options"
            :unit-dict="unitDict"
            :amounts="amounts.options"
          >
            <template #title-side>
              <slot name="titleSide.option" v-bind="{ facilityId }" />
            </template>
            <template #action="{ date }">
              <slot name="action.option" v-bind="{ facilityId, date }" />
            </template>
          </CartOption>

          <!-- 小計 -->
          <CartPrice :facility-id="facilityId" :amounts="amounts.facilities" />
        </div>
      </div>
    </section>

    <section class="inner">
      <!-- 総計 -->
      <CartPriceAll :amount="amounts.all" />
    </section>

    <slot name="bottom"></slot>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { chain } from 'lodash';
import CartDetail from '@web-e/components/cart/detail/CartDetail.vue';
import CartOption from '@web-e/components/cart/option/CartOption.vue';
import CartPrice from '@web-e/components/cart/price/CartPrice.vue';
import CartPriceAll from '@web-e/components/cart/price/CartPriceAll.vue';
import { Facility, Unit } from '@api/models';
import {
  loadCacheFacilities,
  loadCacheUnits,
} from '@web/modules/master-loader';
import { ApproxAmounts } from '@web-t/books';
import { calcApproxAmounts } from '@web/modules/fee-calculator';
import { mapState } from '@web-e/store/cart';
import HolidaySearcher from '@web/modules/holiday-searcher';

export default Vue.extend({
  name: 'Cart',

  components: {
    CartDetail,
    CartOption,
    CartPrice,
    CartPriceAll,
  },

  data: () => ({
    // 施設マスタ
    facilities: [] as Facility[],

    // 区画種類マスタ
    units: [] as Unit[],

    // 祝日
    holidays: [] as string[],
  }),

  computed: {
    facilityDict(): Record<string, Facility> {
      return chain(this.facilities).keyBy('id').value();
    },

    unitDict(): Record<string, Unit> {
      return chain(this.units).keyBy('id').value();
    },

    /**
     * 全ての概算金額
     */
    amounts(): ApproxAmounts {
      const result = calcApproxAmounts(
        this.details,
        this.options,
        this.unitDict,
        this.holidays,
      );

      return result;
    },

    ...mapState(['details', 'options']),
  },

  async mounted() {
    const year = new Date().getFullYear();
    await Promise.all([
      this.loadFacilities(),
      this.loadUnits(),
      this.loadHolidays(year),
      this.loadHolidays(year + 1),
    ]);
  },

  methods: {
    /**
     * 施設マスタを読み込む
     */
    async loadFacilities() {
      const facilities = await loadCacheFacilities(this);
      this.$set(this, 'facilities', facilities);
    },

    /**
     * 区画種類マスタを読み込む
     */
    async loadUnits() {
      const units = await loadCacheUnits(this, { scope: 'fees' });
      this.$set(this, 'units', units);
    },

    /**
     * 祝日をAPIから取得する
     */
    async loadHolidays(year: number) {
      const holidays = await HolidaySearcher.fetchCacheAPI(year, this);
      this.$set(this, 'holidays', [...this.holidays, ...holidays]);
    },
  },
});
</script>
