<template>
  <v-dialog v-model="show" scrollable max-width="800">
    <v-card>
      <v-card-title>
        <h3>ご予約内容の詳細</h3>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="optionTtl">施設からお客様への確認事項</div>
            </v-col>

            <v-col v-for="field in filteredFields" :key="field.id" cols="12">
              <span class="inputTtl">
                {{ field.name }}
              </span>
              <VariableInputField
                v-model="item[field.property]"
                :field="field"
                :unit="unit"
                :usage-time="usageTime"
              />
            </v-col>

            <v-col v-if="!!$listeners.add" cols="12">
              <p class="noteTxt mb-0">
                ※オプションは、予約カートで登録が可能です。
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <div class="actionCont">
        <v-card-actions class="btnArea -list mt-0">
          <v-btn depressed small class="btnBasic -back" @click="show = false">
            キャンセル
          </v-btn>
          <v-btn
            v-if="!!$listeners.save"
            depressed
            small
            class="btnBasic"
            @click="save"
          >
            保存
          </v-btn>
          <v-btn
            v-if="!!$listeners.add"
            depressed
            small
            class="btnBasic"
            @click="add"
          >
            予約カートに追加
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import VariableInputField from '@web-e/components/VariableInputField.vue';
import { InsertDetailRequest } from '@api-i/routes/detail/detail';
import { Field, Unit, UsageTime } from '@api/models';
import { chain } from 'lodash';
import Vue, { PropType } from 'vue';
import { schemaOperation } from '@api/constants';
import { mapActions } from '@web/store/snackbar';

export interface CartDetailDialogItem extends InsertDetailRequest {}

export type CartDetailDialogDataType = {
  show: boolean;
  item: CartDetailDialogItem;
};

const initItem: CartDetailDialogItem = {
  unitId: '',
};

export default Vue.extend({
  name: 'CartDetailDialog',

  components: {
    VariableInputField,
  },

  props: {
    value: {
      type: Object as PropType<CartDetailDialogDataType>,
      default: () => ({
        show: false,
        item: structuredClone(initItem),
      }),
    },

    units: {
      type: Array as PropType<Unit[]>,
      default: () => [],
    },

    holidays: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  computed: {
    show: {
      get(): boolean {
        return this.value.show;
      },
      set(show: boolean): void {
        this.$emit('input', { ...this.value, show });
      },
    },

    item: {
      get(): CartDetailDialogItem {
        return this.value.item;
      },
      set(item: CartDetailDialogItem): void {
        this.$emit('input', { ...this.value, item });
      },
    },

    unit(): Unit | undefined {
      return this.units.find((unit) => {
        return unit.id === this.value.item.unitId;
      });
    },

    usageTime(): UsageTime | undefined {
      if (!this.unit?.usageTimes) {
        return undefined;
      }
      const { startDate } = this.item;
      if (!startDate) {
        return undefined;
      }
      const result = schemaOperation.find(
        this.unit.usageTimes,
        { system: { date: startDate } },
        { holidays: this.holidays },
      );

      return result;
    },

    filteredFields(): Field[] | undefined {
      return this.unit?.fields?.filter((field) => field.isPublic);
    },

    detailToSave(): Partial<CartDetailDialogItem> {
      const paths = chain(this.filteredFields).keyBy('property').keys().value();

      const result = chain(this.value.item)
        .pick([...paths, 'id', 'bookId', 'unitId'])
        .value();

      return result;
    },
  },

  methods: {
    /**
     * 予約カートに追加する
     */
    add(): void {
      this.$emit('add', this.detailToSave);
    },

    /**
     * 保存
     */
    save(): void {
      this.$emit('save', this.detailToSave);
    },

    ...mapActions({
      snackbarRegister: 'register',
    }),
  },
});
</script>
