<template>
  <div class="mContainer -about">
    <section class="inner">
      <h2
        class="topSubttl -center d-flex flex-column justify-space-between align-center"
      >
        <span class="ja">オクシズとは</span>
        <span class="en" aria-hidden="true">ABOUT</span>
      </h2>
      <div class="readTxt text-center">
        静岡市では、市内の中山間地「奥静岡」を「オクシズ」という愛称で呼んでいます。<br />静岡市の面積の大半は豊かな自然が残る山間の地域です。<br />そこには、地域特有の風習や催事などの文化、木材やわさび・お茶など人の手と心で育てられた農林産物の数々、急峻な地形につくられた茶畑や棚田といった美しい景観が今もなお残っています。<br />少し車を走らせれば、都市にはない魅力ある豊かな自然と調和した人々の営みの風景に出会えます。
      </div>
      <div class="aboutPointWarp">
        <div class="aboutPoint">
          <div class="img">
            <v-img
              src="../../assets/img/top/about_hand.svg"
              height="34"
              width="38"
              contain
            ></v-img>
          </div>
          <p class="mb-0">
            ばった・・・静岡の方言。（場所を）確保した。押さえた。
          </p>
        </div>
      </div>
      <div class="btnArea -list">
        <v-btn
          depressed
          large
          href="http://www.okushizuoka.jp/"
          target="_blank"
          class="btnBasic"
        >
          オクシズ ホームページを見る
        </v-btn>
        <v-btn
          depressed
          large
          href="https://www.okushizuoka.jp/images/guidemap2022.pdf#page=2"
          target="_blank"
          class="btnBasic"
        >
          オクシズ ガイドマップを見る
        </v-btn>
      </div>
      <div class="btnArea mt-0">
        <p>
          お越しの際は<br class="d-sm-none" />道路通行規制をご確認ください。
        </p>
        <v-btn
          depressed
          x-large
          outlined
          href="https://shizuokashi-road.appspot.com/index_pub.html"
          target="_blank"
          class="btnBasic map"
        >
          <p class="mb-0">しずみち info</p>
          静岡市道路通行規制情報
        </v-btn>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'HomeAbount',
});
</script>
