<template>
  <div class="lContainer -sub -link">
    <h1 class="pageTtl text-center">各施設HPリンク一覧</h1>
    <div class="mContainer -hpLink">
      <div class="inner">
        <div
          v-for="(facilities_, area) in facilitiesByArea"
          :key="area"
          class="mb-8"
        >
          <h4>{{ area }}</h4>
          <v-list>
            <v-row>
              <v-col
                v-for="facility in facilities_"
                :key="facility.id"
                cols="12"
                md="6"
                lg="4"
              >
                <v-list-item :href="facility.url" target="_blank">
                  <v-list-item-icon>
                    <v-icon class="txtC-green">
                      mdi-checkbox-multiple-blank-outline
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="facility.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Facility } from '@api/models';
import { loadCacheFacilities } from '@web/modules/master-loader';
import { chain } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'Link',

  pageOptions: {
    routeConfig: {
      path: '/link',
    },
    props: {
      title: 'オクシズ関連リンク一覧',
    },
  },

  data: () => ({
    facilities: [] as Facility[],
  }),

  computed: {
    facilitiesByArea(): Record<string, Facility[]> {
      const result = chain(this.facilities).groupBy('area').value();

      return result;
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await Promise.all([this.loadFacility()]);
    },

    async loadFacility() {
      const facilities = await loadCacheFacilities(this);
      this.$set(this, 'facilities', facilities);
    },
  },
});
</script>
