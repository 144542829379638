import {
  StoreFreeActions,
  StoreFreeGetters,
  StoreFreeMutations,
  StoreFreeState,
} from '@web-t/store/free';
import { StoreRootExternal } from '@web-t/store/root';
import { ActionTree, createNamespacedHelpers, Module } from 'vuex';
import {
  DefineActions,
  DefineGetters,
  DefineMutations,
} from 'vuex-type-helper';

const state: StoreFreeState = {
  availability: [],
  datesOfMonth: [],
  weekIdx: 0,
  dateIdx: 0,
};

const getters: DefineGetters<StoreFreeGetters, StoreFreeState> = {
  availability(state) {
    return state.availability;
  },
  datesOfMonth(state) {
    return state.datesOfMonth;
  },
  weekIdx(state) {
    return state.weekIdx;
  },
  dateIdx(state) {
    return state.dateIdx;
  },
};

const mutations: DefineMutations<StoreFreeMutations, StoreFreeState> = {
  setAvailability(state, payload) {
    state.availability = payload;
  },
  setDatesOfMonth(state, payload) {
    state.datesOfMonth = payload;
  },
  setWeekIdx(state, payload) {
    state.weekIdx = payload;
  },
  setDateIdx(state, payload) {
    state.dateIdx = payload;
  },
};

const actions: DefineActions<
  StoreFreeActions,
  StoreFreeState,
  StoreFreeMutations,
  StoreFreeGetters
> = {
  setAvailability({ commit }, payload) {
    commit('setAvailability', payload);
  },
  setDatesOfMonth({ commit }, payload) {
    commit('setDatesOfMonth', payload);
  },
  setWeekIdx({ commit }, payload) {
    commit('setWeekIdx', payload);
  },
  setDateIdx({ commit }, payload) {
    commit('setDateIdx', payload);
  },
};

export const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers<
    StoreFreeState,
    StoreFreeGetters,
    StoreFreeMutations,
    StoreFreeActions
  >('free');

const storeModule: Module<StoreFreeState, StoreRootExternal> = {
  namespaced: true,

  state,
  getters,
  mutations,
  actions: actions as unknown as ActionTree<StoreFreeState, StoreRootExternal>,
};

export default storeModule;
