import { ConstantInputProperty } from '@api-t/constants/inputProperty';

export const inputProperty: ConstantInputProperty = {
  items: [
    {
      text: 'section',
      value: 'section',
    },
    {
      text: 'usage',
      value: 'usage',
    },
    {
      text: 'startDate',
      value: 'startDate',
    },
    {
      text: 'startTime',
      value: 'startTime',
    },
    {
      text: 'endDate',
      value: 'endDate',
    },
    {
      text: 'endTime',
      value: 'endTime',
    },
    {
      text: 'stayDays',
      value: 'stayDays',
    },
    {
      text: 'quantity',
      value: 'quantity',
    },
    {
      text: 'numOfPeople',
      value: 'numOfPeople',
    },
    {
      text: 'realNumOfPeople',
      value: 'realNumOfPeople',
    },
    {
      text: 'people_1',
      value: 'people_1',
    },
    {
      text: 'people_2',
      value: 'people_2',
    },
    {
      text: 'people_3',
      value: 'people_3',
    },
    {
      text: 'people_4',
      value: 'people_4',
    },
    {
      text: 'people_5',
      value: 'people_5',
    },
    {
      text: 'people_6',
      value: 'people_6',
    },
    {
      text: 'people_7',
      value: 'people_7',
    },
    {
      text: 'people_8',
      value: 'people_8',
    },
    {
      text: 'people_9',
      value: 'people_9',
    },
    {
      text: 'free_1',
      value: 'free_1',
    },
    {
      text: 'free_2',
      value: 'free_2',
    },
    {
      text: 'free_3',
      value: 'free_3',
    },
    {
      text: 'free_4',
      value: 'free_4',
    },
    {
      text: 'free_5',
      value: 'free_5',
    },
    {
      text: 'free_6',
      value: 'free_6',
    },
    {
      text: 'free_7',
      value: 'free_7',
    },
    {
      text: 'free_8',
      value: 'free_8',
    },
    {
      text: 'free_9',
      value: 'free_9',
    },
  ],
};
