import {
  ConstantTargetSite,
} from '@api-t/constants/targetSite';

export const targetSite: ConstantTargetSite = {
  items: [{
    text: '公開サイトと管理サイト',
    value: true,
  },
  {
    text: '管理サイトのみ',
    value: false,
  },],
  get(value: boolean): string {
    if(value){
      return targetSite.items[0].text;
    }else{
      return targetSite.items[1].text;
    }
  },
};
