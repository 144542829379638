import {
  ConstantClosedDayType,
  ConstantClosedDayTypeDict,
} from '@api-t/constants/closedDayType';

const closedDayTypeDict: ConstantClosedDayTypeDict = {
  closed: {
    text: '休業',
    value: 'closed',
  },
  maintenance: {
    text: '臨時',
    value: 'maintenance',
  },
  out: {
    text: '外',
    value: 'out',
  },
};

export const closedDayType: ConstantClosedDayType = {
  ...closedDayTypeDict,
  items: [closedDayTypeDict.closed, closedDayTypeDict.maintenance],
  get(value: string): string {
    switch (value) {
      case closedDayTypeDict.closed.value:
        return closedDayTypeDict.closed.text;
      case closedDayTypeDict.maintenance.value:
        return closedDayTypeDict.maintenance.text;
      case closedDayTypeDict.out.value:
        return closedDayTypeDict.out.text;
      default:
        return '';
    }
  },
};
