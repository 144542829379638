<template>
  <div class="mContainer -facility">
    <section class="inner">
      <h2
        class="topSubttl -center d-flex flex-column justify-space-between align-center"
      >
        <span class="orgIcon icon-facility txtC-green"></span>
        <span class="ja">施設から予約する</span>
        <span class="en" aria-hidden="true">FACILITY</span>
      </h2>
      <div class="facilityCont">
        <div
          v-for="(fas, areaName) in facilitiesByArea"
          :key="areaName"
          class="facilityListCont"
        >
          <h4>{{ areaName }}</h4>
          <div class="facilityList">
            <div class="facilityListItem">
              <div
                v-for="facility in fas"
                :key="facility.id"
                class="facilityListItem__item"
              >
                <router-link
                  :to="{ path: '/search', query: { facilityId: facility.id } }"
                >
                  {{ facility.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btnArea">
        <v-btn depressed to="/link" class="btnBasic">施設一覧を見る</v-btn>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Facility } from '@api/models';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'HomeFacility',

  props: {
    items: {
      type: Array as PropType<Facility[]>,
      default: () => [],
    },
  },

  computed: {
    // 利用施設の地区別表示
    facilitiesByArea(): Record<string, Facility[]> {
      return this.items.reduce((prev: Record<string, Facility[]>, next) => {
        const areaName = next.area || 'その他';
        return {
          ...prev,
          [areaName]: [...(prev[areaName] || []), next],
        };
      }, {});
    },
  },
});
</script>
