import { ConstantTaxType, ConstantTaxTypeDict } from '@api-t/constants/taxType';

const taxTypeDict: ConstantTaxTypeDict = {
  included: {
    text: '内税',
    value: 'included',
  },
  excluded: {
    text: '外税',
    value: 'excluded',
  },
};

export const taxType: ConstantTaxType = {
  ...taxTypeDict,
  items: [taxTypeDict.included, taxTypeDict.excluded],
  get(value: string): string {
    switch (value) {
      case taxTypeDict.included.value:
        return taxTypeDict.included.text;
      case taxTypeDict.excluded.value:
        return taxTypeDict.excluded.text;
      default:
        return '';
    }
  },
};
