<template>
  <v-date-picker
    v-model="_value"
    scrollable
    :first-day-of-week="1"
    :full-width="fullWidth"
    :day-format="(date) => new Date(date).getDate()"
  ></v-date-picker>
</template>

<script lang="ts">
import Vue from 'vue';

export const props = {
  fullWidth: {
    type: Boolean,
    default: false,
  },
};

export default Vue.extend({
  name: 'DateSelector',

  props: {
    value: {
      type: String,
      default: '',
    },

    ...props,
  },

  computed: {
    _value: {
      get(): string {
        return this.value;
      },
      set(newValue: string) {
        this.$emit('input', newValue);
        this.$emit('updated', newValue);
      },
    },
  },
});
</script>
